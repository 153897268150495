import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../services';
import { storage } from '../../../../../../../environments/environment';

@Component({
  selector: "app-frind-requests",
  templateUrl: "./frind-requests.component.html",
  styleUrls: ["./frind-requests.component.css"]
})
export class FrindRequestsComponent implements OnInit {
  public friends: any;
  photoUrl: string = storage.S3 + "/user/profile/";
  constructor(private Api: ApiService) {}

  ngOnInit() {
    this.getFriends();
  }

  cancelFriendRequest(requestId, requester) {
      this.Api.post(`/user/friend/accepted`, {
        requester: requester
      }).subscribe((result: any) => {
        this.getFriends();
      });
  }

  accepted(requester) {

     this.Api.post(`/user/friend/accepted`, {
       requester: requester
     }).subscribe((result: any) => {
       this.getFriends();
     });
  }

  getFriends() {
    this.Api.get(`/friend/requests`).subscribe((result: any) => {
      if (result.data) {
        this.friends = result.data.requests;
      }
    });
  }
}

