import { Injectable } from "@angular/core";
import { ApiService } from "./../../server/api.service";

@Injectable({
  providedIn: "root"
})
export class MemberService {
  constructor(private Api: ApiService) {}

  getmembers() {
    return this.Api.get(`/family/members`);
  }

  store(member: any) {
    return this.Api.post("/family/user/store", member);
  }

  update(id: any, member: any) {
    return this.Api.put(`/family/user/` + id, member);
  }

  getById(id: string) {
    return this.Api.get(`/team/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/team/delete/` + id);
  }
}
