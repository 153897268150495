import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";
import {Observable, Subject} from 'rxjs';
import { IncomingMessage } from 'http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private Api: ApiService) { }

  addByID(items: any, userId: any) {
    return this.Api.post(`/shop/cart/` + userId, items);
  }

  getCartItems() {
    return this.Api.get('/shop/cart', { withCredentials: true });
  }

  removeCartItem(id: string) {
    return this.Api.put('/shop/cart/remove/' + id , { withCredentials: true });
  }

  clearCart() {
    // return this.Api.delete('/shop/cart/remove/', { withCredentials: true });
    return this.Api.delete('/shop/cart/remove/');
  }

  updateCart(id: any, shippingCost: any) {
    return this.Api.put('/shop/cart/'+ id, shippingCost)
  }
  
  updateLocalCartItems(items: any, userId:any) {
    return this.Api.post('/shop/cart/'+ userId, items);
  }

  cartItems = new Subject<any>();
  
}
