import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../services';
import { storage } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-frind-pending-requests',
  templateUrl: './frind-pending-requests.component.html',
  styleUrls: ['./frind-pending-requests.component.css']
})
export class FrindPendingRequestsComponent implements OnInit {
  public friends: any;
  photoUrl: string = storage.S3 + '/user/profile/';
  constructor(private Api: ApiService) { }

  ngOnInit() {
    this.getFriends();

  }

  getFriends() {
      this.Api.get(`/friend/pending/requests`).subscribe((result: any) => {
        if (result.data) {
          this.friends = result.data;
        }
      });
  }
}

