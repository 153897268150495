import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'social-share-widget',
  templateUrl: './social-share-widget.component.html',
  styleUrls: ['./social-share-widget.component.css']
})
export class SocialShareWidgetComponent implements OnInit {

  constructor() { }

  @Input() options: any;
  ngOnInit() {
  }

}
