import { Injectable } from "@angular/core";
import { server } from "../../../environments/environment";
import { ApiService } from "../../services/server/api.service";

@Injectable({
  providedIn: "root"
})
export class RouteService {
  public baseUrl = server.baseUrl;
  constructor(private Api: ApiService) {}

  routeLog(route: any) {
    return this.Api.post("/route/log", route);
  }

  getRoutes() {
    return this.Api.get(`/web/routes`);
  }

  store(route: any) {
    return this.Api.post("/web/route/create", route);
  }
  update(id: any, route: any) {
    return this.Api.put("/web/route/update/" + id, route);
  }

  getById(id: string) {
    return this.Api.get(`/web/route/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/web/route/delete/` + id);
  }
}
