import { LandingEventsComponent } from './modules/frontend/components/pages/events/landing-events/landing-events.component';
import { UserOrderViewComponent } from './modules/dashboard/user-dashboard/components/user-order-view/user-order-view.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/guards";
import { Role } from "./models";

// Auth
import { LoginComponent } from "./modules/auth/login/login.component";
import { RegisterComponent } from "./modules/auth/register/register.component";
import { RegistrationComponent } from "./modules/auth/registration/registration.component";
import { PasswordRecoverComponent } from "./modules/auth/password-recover/password-recover.component";
import { PasswordResetComponent } from "./modules/auth/password-reset/password-reset.component";

import { HomeComponent } from "./modules/frontend/components/home/home.component";

import { ActivityRouteComponent } from './modules/frontend/components/pages/activity/activity-route/activity-route.component';

// User Dashboard
import { UserDashboardComponent } from "./modules/dashboard/user-dashboard/user-dashboard.component";
import { ProfileComponent } from "./modules/dashboard/user-dashboard/components/profile/profile.component";
import { ProfileEditComponent } from "./modules/dashboard/user-dashboard/components/profile/profile-edit/profile-edit.component";
import { ImportComponent } from "./modules/dashboard/user-dashboard/components/import/import.component";
import { RewardsComponent } from "./modules/dashboard/user-dashboard/components/rewards/rewards.component";
import { EventsComponent } from "./modules/dashboard/user-dashboard/components/events/events.component";
import { LeaderBoardComponent } from "./modules/dashboard/user-dashboard/components/leader-board/leader-board.component";

// goals
import { GoalsComponent } from "./modules/dashboard/user-dashboard/components/goals/goals.component";
import { CreateGoalComponent } from "./modules/dashboard/user-dashboard/components/goals/create/create-goal.component";

// Routes
import { RoutesComponent } from "./modules/dashboard/user-dashboard/components/routes/routes.component";
import { LogRouteComponent } from "./modules/dashboard/user-dashboard/components/routes/log-route/log-route.component";
import { CreateRoutComponent } from "./modules/dashboard/user-dashboard/components/routes/create-rout/create-rout.component";

// challenges
import { ChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/challenges.component";
import { ChallengesCreateComponent } from "./modules/dashboard/user-dashboard/components/challenges/challenges-create/challenges-create.component";

// Pages
import { FaqComponent } from "./modules/frontend/components/pages/faq/faq.component";
import { ContactComponent } from "./modules/frontend/components/pages/contact/contact.component";
import { OurAppComponent } from "./modules/frontend/components/pages/our-app/our-app.component";
import { FeatureComponent } from "./modules/frontend/components/pages/feature/feature.component";
import { ClubsComponent } from "./modules/frontend/components/pages/clubs/clubs.component";
// Events
import { EventsPageComponent } from "./modules/frontend/components/pages/events/events.component";

import { MembershipComponent } from "./modules/frontend/components/pages/membership/membership.component";
import { RewardComponent } from "./modules/frontend/components/pages/reward/reward.component";
import { ActivityComponent } from "./modules/dashboard/user-dashboard/components/activity/activity.component";
import { SearchFriendComponent } from "./modules/dashboard/user-dashboard/components/friends/search-friend/search-friend.component";
import { FriendsComponent } from "./modules/dashboard/user-dashboard/components/friends/friends/friends.component";
import { FriendComponent } from "./modules/dashboard/user-dashboard/components/friends/friend/friend.component";
import { FrindRequestsComponent } from "./modules/dashboard/user-dashboard/components/friends/frind-requests/frind-requests.component";
import { FrindPendingRequestsComponent } from "./modules/dashboard/user-dashboard/components/friends/frind-pending-requests/frind-pending-requests.component";
import { GroupsComponent } from "./modules/dashboard/user-dashboard/components/groups/groups/groups.component";
import { SearchGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/search-group/search-group.component";
import { CreateGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/create-group/create-group.component";
import { GroupComponent } from "./modules/dashboard/user-dashboard/components/groups/group/group.component";
import { CreateEventComponent } from "./modules/dashboard/user-dashboard/components/events/create-event/create-event.component";
import { EditEventComponent } from "./modules/dashboard/user-dashboard/components/events/edit-event/edit-event.component";
// Groups
import { GroupsJoinComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-join/groups-join.component";
import { GroupsRequestComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-request/groups-request.component";
import { EditGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/edit-group/edit-group.component";
import { JoinChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/join-challenges/join-challenges.component";
import { ReviewChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/review-challenges/review-challenges.component";
import { ViewEventComponent } from "./modules/dashboard/user-dashboard/components/events/view-event/view-event.component";
import { LeaderBoardEventComponent } from "./modules/dashboard/user-dashboard/components/events/leader-board-event/leader-board-event.component";
import { GroupsViewComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-view/groups-view.component";
import { ViewGoalComponent } from "./modules/dashboard/user-dashboard/components/goals/view-goal/view-goal.component";
import { JoinEventComponent } from "./modules/dashboard/user-dashboard/components/events/join-event/join-event.component";
import { EditChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/edit-challenges/edit-challenges.component";
import { SingleEventpageComponent } from "./modules/frontend/components/pages/events/single-eventpage/single-eventpage.component";
import { SingleStageComponent } from "./modules/dashboard/user-dashboard/components/events/single-stage/single-stage.component";
import { PaymentComponent } from "./modules/auth/payment/payment.component";
import { PrivacyComponent } from "./modules/frontend/components/pages/privacy/privacy.component";
import { AccountInfo } from "./modules/frontend/components/pages/account-info/account-info.component";
import { TermsComponent } from "./modules/frontend/components/pages/terms/terms.component";
import { SitemapComponent } from "./modules/frontend/components/pages/sitemap/sitemap.component";
import { MoreEventsComponent } from "./modules/dashboard/user-dashboard/components/events/more-events/more-events.component";
import { UpgradeMembershipComponent } from "./modules/dashboard/user-dashboard/components/membership/upgrade-membership/upgrade-membership.component";
import { ContactSupportComponent } from "./modules/dashboard/user-dashboard/components/contact-support/contact-support.component";
import { MyRideComponent } from "./modules/dashboard/user-dashboard/components/my-ride/my-ride.component";
import { FamilyComponent } from "./modules/dashboard/user-dashboard/components/family/family.component";
import { EditFamilyComponent } from "./modules/dashboard/user-dashboard/components/family/edit-family/edit-family.component";
import { AddMemberComponent } from "./modules/dashboard/user-dashboard/components/family/add-member/add-member.component";
import { EditMemberComponent } from "./modules/dashboard/user-dashboard/components/family/edit-member/edit-member.component";
import { AccountComponent } from './modules/dashboard/user-dashboard/components/account/account.component';
import { UserOrdersComponent } from './modules/dashboard/user-dashboard/components/user-orders/user-orders.component';

const routes: Routes = [
  // Home Page
  {
    path: "",
    component: HomeComponent
  },

  // Public Content Pages
  {
    path: "faq",
    component: FaqComponent
  },
  {
    path: "contact",
    component: ContactComponent
  },
  {
    path: "our-app",
    component: OurAppComponent
  },
  {
    path: "feature",
    component: FeatureComponent
  },
  {
    path: "clubs",
    component: ClubsComponent
  },
  {
    path: "events",
    component: EventsPageComponent
  },
  {
    path: "granfondo",
    component: LandingEventsComponent
  },
  {
    path: "event/:id",
    component: SingleEventpageComponent
  },
  {
    path: "membership",
    component: MembershipComponent
  },
  {
    path: "reward",
    component: RewardComponent
  },
  {
    path: "privacy-policy",
    component: PrivacyComponent
  },
  // {
    // path: "account-information",
    // component: AccountInfo
  // },
  {
    path: "account-deletion-request",
    component: AccountInfo
  },
  {
    path: "terms-conditions",
    component: TermsComponent
  },
  {
    path: "sitemap",
    component: SitemapComponent
  },
  // Social Pages
  {
    path: "app_link/route/:id",
    component: ActivityRouteComponent
  },
  {
    path: "activity/:id",
    component: ActivityRouteComponent
  },

  //Auth Pages
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "register",
    component: RegisterComponent
  },
  {
    path: "passwordrecovery",
    component: PasswordRecoverComponent
  },
  {
    path: "passwordreset",
    component: PasswordResetComponent
  },
  // Payment
  {
    path: "payment",
    component: PaymentComponent
  },

  // USER DASHBOARD
  {
    path: "user/dashboard",
    component: UserDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/contact-support",
    component: ContactSupportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer, Role.Vendor] }
  },
  {
    path: "user/profile",
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/profile/edit",
    component: ProfileEditComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/rewards",
    component: RewardsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/my-ride",
    component: MyRideComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/events",
    component: EventsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/events/more",
    component: MoreEventsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/events/create",
    component: CreateEventComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/events/edit/:id",
    component: EditEventComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/events/view/:id",
    component: ViewEventComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/events/leader-board/:id",
    component: LeaderBoardEventComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/events/join/:id",
    component: JoinEventComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/event/stage/:id",
    component: SingleStageComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/leader-board",
    component: LeaderBoardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/import",
    component: ImportComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/activity/:id",
    component: ActivityComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  // Routes
  {
    path: "user/routes",
    component: RoutesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/routes/all",
    component: RoutesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/routes/create",
    component: CreateRoutComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/routes/log",
    component: LogRouteComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/activity",
    component: GoalsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  // goals
  {
    path: "user/goals",
    component: GoalsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/goal/create",
    component: CreateGoalComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/goal/:id",
    component: ViewGoalComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/challenges",
    component: ChallengesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/challenge/create",
    component: ChallengesCreateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/challenge/edit/:id",
    component: EditChallengesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/challenge/join/:id",
    component: JoinChallengesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/challenge/:id",
    component: ReviewChallengesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  //account
  {
    path: "user/account",
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  //user orders
  {
    path: "user/orders",
    component: UserOrdersComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  //user orders detail
  {
    path: "user/order-view/:id",
    component: UserOrderViewComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  //family
  {
    path: "user/family/edit",
    component: EditFamilyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/family/member/add",
    component: AddMemberComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/family/member/edit/:id",
    component: EditMemberComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  //Friend
  {
    path: "user/friends/search",
    component: SearchFriendComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/friends/requests",
    component: FrindRequestsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/friends/pending/requests",
    component: FrindPendingRequestsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/friends",
    component: FriendsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/friend/:id",
    component: FriendComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/teams",
    component: GroupsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/teams/search",
    component: SearchGroupComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/teams/join",
    component: GroupsJoinComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/teams/view",
    component: GroupsViewComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  {
    path: "user/team/create",
    component: CreateGroupComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/team/:id",
    component: GroupComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/team/edit/:id",
    component: EditGroupComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/membership",
    component: UpgradeMembershipComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },
  {
    path: "user/shop",
    component: UserDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer] }
  },

  // Shop
  {
    path: "shop",
    loadChildren: () =>
      import("./modules/frontend/shop/shop.module").then(
        mod => mod.ShopModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer, Role.Vendor] }
  },

  // Admin Dashbaord
  {
    path: "admin-dashboard",
    loadChildren: () =>
      import("./modules/dashboard/admin-dashboard/admin-dashboard.module").then(
        mod => mod.AdminDashboardModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer, Role.Vendor] }
  },

  // Shop Dashboard
  {
    path: "shop-dashboard",
    loadChildren: () =>
      import("./modules/dashboard/shop-dashboard/shop-dashboard.module").then(
        mod => mod.ShopDashboardModule
      ),
    canActivate: [AuthGuard],
    data: { roles: [Role.User, Role.superAdmin, Role.Developer, Role.Vendor] }
  },

  // Referral Dashboard
  {
    path: "referral",
    loadChildren: () =>
      import("./modules/dashboard/referral-dashboard/referral-dashboard.module").then(
        mod => mod.ReferralDashboardModule
      )
  },

  // Referral Dashboard
  {
    path: "supplier-dashboard",
    loadChildren: () =>
      import("./modules/dashboard/supplier-dashboard/supplier-dashboard.module").then(
        mod => mod.SupplierDashboardModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
