import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-right-sidebar',
  templateUrl: './user-right-sidebar.component.html',
  styleUrls: ['./user-right-sidebar.component.css']
})
export class UserRightSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
