import { Component, OnInit } from '@angular/core';
import io from 'socket.io-client';

import { SeoService } from 'src/app/services'

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	private socket;
	constructor(
		private seoService: SeoService
	) { }


	ngOnInit() {
		this.seoService.updateTag('og:image', 'http://dev.gocycle.com.au/assets/img/default/img_banner_about.png')
		this.connect();
	}

	connect() {
		this.socket = io.connect("https://api.gocycle.com.au",
			{
				// Send auth token on connection, you will need to DI the Auth service above
				// 'query': 'token=' + Auth.getToken()
				rejectUnauthorized: false,
				path: '/socket.io',
				transports: ['websocket', 'polling']
				// secure: true,
			}
		);
		return () => this.socket.disconnect();
	}

	// Home Header slider
	slideConfig = {
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 15000,
		speed: 2000
	};

	// Partners Slider
	slideConfig2 = {
		dots: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 1000,
		speed: 2000,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 660,
				settings: {
					slidesToShow: 2
				}
			}
		]
	};
}
