import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../services';
import { storage } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.css']
})
export class FriendsComponent implements OnInit {
  public friends: any;
  photoUrl: string = storage.S3 + '/user/profile/';
  constructor(private Api: ApiService) { }

  ngOnInit() {
    this.getFriends();
  }

  unFriend(){
    
  }

  getFriends() {
    this.Api.get(`/user/friends`).subscribe((result: any) => {
      if (result.data) {
        this.friends = result.data;
      }
    });
  }
}