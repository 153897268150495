import { Injectable } from '@angular/core';
import { ApiService } from "../../server/api.service";
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private Api: ApiService) { }

  getRecords() {
    return this.Api.get(`/payments`);
  }

  store(payment: any) {
    return this.Api.post("/payment/create", payment);
  }

  update(id: any, payment: any) {
    return this.Api.put("/payment/" + id, payment);
  }

  getById(id: string) {
    return this.Api.get(`/payment/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/payment/` + id);
  }
}
