import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { storage } from "../../../../../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService, AuthenticationService, ApiService } from '../../../../../../services';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { Router, ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { first } from "rxjs/operators";
declare var paypal;

@Component({
  selector: "app-upgrade-membership",
  templateUrl: "./upgrade-membership.component.html",
  styleUrls: ["./upgrade-membership.component.css"]
})
export class UpgradeMembershipComponent implements OnInit {
  @ViewChild('paypal', { static: false }) paypalElement: ElementRef;
  loading = false;
  submitted = false;
  returnUrl: string;
  memberships = [
    { _id: 1, name: "Monthly Membership - $5.99" },
    { _id: 2, name: "Annual Membership - $59.00" },
    {
      _id: 3,
      name: "Monthly Family Membership - $8.99"
    },
    {
      _id: 4,
      name: "Annual Family Membership - $85.00"
    }
  ];
  membership = 2;
  paymentMethods = [
    { id: 0, name: "CREDIT CARD" }
    ,
    { id: 1, name: "PAYPAL" }
  ];
  paymentmethod = 1;
  coupon = '';
  plan: any;
  order: any;
  subscription: any;

  cardNumberElement: any;
  elements: Elements;
  card: StripeElement;
  token = null;
  isPaid = false;
  user: any;
  paymentMethodToken = null;
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: "en"
  };

  stripeTest: FormGroup;
  regEmail = null;
  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private route: ActivatedRoute,
    private router: Router,
    private Api: ApiService,
    private notifier: NotifierService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.paymentmethod = 1;
    this.membership = 4;
    this.getUserProfile();
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/dashboard";
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.stripeService.elements(this.elementsOptions).subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.cardNumberElement = this.elements.create("cardNumber", {});
        this.cardNumberElement.mount("#card-cardNumber");
        var cardNumberElement = this.elements.create("cardExpiry", {});
        cardNumberElement.mount("#card-cardExpiry");
        var cardNumberElement = this.elements.create("cardCvc", {});
        cardNumberElement.mount("#card-cardcvv");
      }
    });

    this.getPaypalButton();
  }

  getPaypalButton(){
    paypal.Buttons({
      // Configure environment
      env: 'sandbox',
      client: {
        sandbox: 'ARszb-7WcXufCqV649WUibBC-PA4OJ0K214K566ylDUOvnJzWP1WI_9pmR1-NkyiQlfyukJX5zR9PfxL',
        production: 'demo_production_client_id'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          'plan_id': self.plan,
        });
      },
      onApprove: (data, actions) => {
        this.subscription = data.subscriptionID
        this.order = data.orderID;
        this.paypay();
        // alert('You have successfully created subscription ' + data.subscriptionID);
        // self.getSubcriptionDetails(data.subscriptionID);
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        if (data.status == 'COMPLETED') {
          this.paypay();
        }

      },
      onCancel: (data, actions) => {
        //    console.log('OnCancel', data, actions);
      },
      onError: err => {
        //  console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //  console.log('onClick', data, actions);
      },
    }).render('#paypal');
    const self = this;
    this.plan = 'P-8TC72503HD607571UL2CFEJY';  //Default Plan Id
    if (this.membership == 1 && this.coupon === '') {
      this.plan = 'P-8TC72503HD607571UL2CFEJY'; // Monthly Membership
    }

    if (this.membership == 1 && this.coupon != '') {
      this.plan = 'P-41E81003RW9059216L2HH6GY'; // Monthly trial Membership
    }

    if (this.membership == 2 && this.coupon === '') {
      this.plan = 'P-80W04532KP1123240L2HHUVY'; // Annual Membership
    }
    if (this.membership == 2 && this.coupon != '') {
      this.plan = 'P-3RS37706FT257472HL2HH24A';  // Annual trial Membership
    }
    if (this.membership == 3 && this.coupon === '') {
      this.plan = 'P-5TE536167X761905ML26NQBQ'; // Monthly Family Membership
    }

    if (this.membership == 3 && this.coupon != '') {
      this.plan = 'P-2S486744PC805534NL26NZNY'; // Monthly Family trial Membership
    }

    if (this.membership == 4 && this.coupon === '') {
      this.plan = 'P-4MC38838LB339861VL26NTNQ'; // Annual Family Membership
    }
    if (this.membership == 4 && this.coupon != '') {
      this.plan = 'P-05W30860TC740482LL26OERQ';  // Annual Family trial Membership
    }

    paypal.Buttons({
      // Configure environment
      env: 'sandbox',
      client: {
        sandbox: 'ARszb-7WcXufCqV649WUibBC-PA4OJ0K214K566ylDUOvnJzWP1WI_9pmR1-NkyiQlfyukJX5zR9PfxL',
        production: 'demo_production_client_id'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          'plan_id': self.plan,
        });
      },
      onApprove: (data, actions) => {
        this.subscription = data.subscriptionID
        this.order = data.orderID;
        this.paypay();
        // alert('You have successfully created subscription ' + data.subscriptionID);
        // self.getSubcriptionDetails(data.subscriptionID);
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        if (data.status == 'COMPLETED') {
          this.paypay();
        }

      },
      onCancel: (data, actions) => {
        //    console.log('OnCancel', data, actions);
      },
      onError: err => {
        //  console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //  console.log('onClick', data, actions);
      },
    }).render('#paypal');
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.regEmail = res.data.email;
        this.user = res.data;
      }
    });
    this.stripeTest = this.formBuilder.group({
      name: ["", [Validators.required]]
    });
  }
  buy() {
    const name = this.stripeTest.get("name").value;
    this.stripeService
      .createToken(this.cardNumberElement, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          this.token = result.token.id;
          this.stripeService
            .createPaymentMethod("card", this.cardNumberElement)
            .subscribe(rest => {
              // Handle result.error or result.paymentMethod
              this.paymentMethodToken = rest.paymentMethod.id;
              if (this.paymentMethodToken) {
                this.pay();
              }
            });
        } else if (result.error) {
          // Error creating the token
          this.notifier.notify("error", result.error.message);
        }
      });
  }

  pay() {
    this.submitted = true;
    this.loading = true;
    this.Api.post("/user/pay", {
      token: this.token,
      pm: this.paymentMethodToken,
      email: this.regEmail,
      membership: this.membership
    }).subscribe(res => {
      if (res.data) {
        this.isPaid = true;
        this.notifier.notify("success", "Payment successful");
        this.router.navigate([this.returnUrl]);
      } else {
        this.notifier.notify("error", "some thing went wrong");
      }
    });
  }

  paypay() {
    this.submitted = true;
    this.loading = true;
    this.Api.post("/auth/payment/paypal", {
      email: this.regEmail,
      membership: this.user.membership,
      coupon: this.user.coupon,
      plan: this.plan,
      subscription: this.subscription,
      order: this.order
    }).subscribe(res => {
      if (res.data) {
        this.loading = false;
        this.isPaid = true;
        this.notifier.notify("success", "Upgrade Membership successfully");
      } else {
        this.loading = false;
        this.notifier.notify("error", "some thing went wrong");
      }
    });
  }

  onChangePaypal(){
    this.getPaypalButton();
  }
}
