import { Injectable } from '@angular/core';
import { server } from '../../../environments/environment';
import { ApiService } from '../../services/server/api.service';

@Injectable({
  providedIn: "root"
})
export class RideService {
  public baseUrl = server.baseUrl;
  constructor(private Api: ApiService) {}
  
  getRides(id: number) {
    return this.Api.get(`/user/rides/all`);
  }

  rideLog(ride: any) {
    return this.Api.post("/user/ride/create-log", ride);
  }
}
