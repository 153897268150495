import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService, AuthenticationService } from '../../../../../services';
import { User } from '../../../../../models';
import { storage } from '../../../../../../environments/environment';

@Component({
	selector: 'app-user-profile-photo',
	templateUrl: './user-profile-photo.component.html',
	styleUrls: [ './user-profile-photo.component.css' ]
})
export class UserProfilePhotoComponent implements OnInit {
	currentUser: User;
	photoUrl: string = storage.S3 + '/user/profile/';
	currentUserSubscription: Subscription;

	constructor(public Api: ApiService, private authenticationService: AuthenticationService) {
		this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}
	ngOnInit() {}
}
