import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class FeedbackService {
  constructor(private Api: ApiService) {}

  getFeedbacks() {
    return this.Api.get(`/feedbacks`);
  }

  store(feedback: any) {
    return this.Api.post("/feedback/create", feedback);
  }

  update(id: any, feedback: any) {
    return this.Api.put("/feedback/" + id, feedback);
  }

  getById(id: string) {
    return this.Api.get(`/feedback/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/feedback/` + id);
  }
}