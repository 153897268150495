import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  isCollapsed  = true;
  isCollapsed3  = true;
  isCollapsed2 = false;
  isCollapsed4 = true;
  isCollapsed5 = true;
  isCollapsed6 = true;
  constructor() { }

  ngOnInit() {
  }

}
