import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class KeywordService {
  constructor(private Api: ApiService) {}

  getCountries() {
    return this.Api.get(`/keyword`);
  }

  store(country: any) {
    return this.Api.post("/keyword/create", country);
  }

  update(id: any, country: any) {
    return this.Api.put("/keyword/" + id, country);
  }

  getById(id: string) {
    return this.Api.get(`/keyword/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/keyword/` + id);
  }
}