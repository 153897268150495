import { Component, OnInit } from '@angular/core';
import {
  ApiService
} from "../../../../../services";
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {

  memberships: any;

  constructor(
    private Api: ApiService,
  ) {

  }

  ngOnInit() {
    this.getMemberships();
  }

  getMemberships() {
    this.Api.get("/auth/memberships").subscribe(res => {
      this.memberships = res.data;
      console.log(res.data);
    });
  }

}
