import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RouteService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-create-rout",
  templateUrl: "./create-rout.component.html",
  styleUrls: ["./create-rout.component.css"]
})
export class CreateRoutComponent implements OnInit {
  isCollapsed = false;
  RoutsBodyCollapsed = false;
  RoutDetailsBody = false;
  returnUrl: string;
  createRouteForm: FormGroup;
  loading = false;
  submitted = false;

  private geocodeUrl: string =
    "https://api.mapbox.com/geocoding/v5/mapbox.places/";

  private ACCESS_TOKEN: string = "access_token=REDACTED";

  constructor(
    private formBuilder: FormBuilder,
    private routeService: RouteService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/routes/all";

    this.createRouteForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", []],
      distance: ["", [Validators.required]],
      startLocationName: ["", [Validators.required]],
      startDate: ["", []],
      endLocationName: ["", [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.createRouteForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.createRouteForm.invalid) {
      return;
    }

    this.loading = true;

    this.routeService
      .store(this.createRouteForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Route has been created successful"
            );
            this.loading = false;
            this.createRouteForm.reset;
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);

          this.loading = false;
        }
      );
  }
}
