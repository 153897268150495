import { Component, OnInit } from "@angular/core";
import { ApiService, AuthenticationService } from "../../../../../services";
@Component({
  selector: "app-leader-board",
  templateUrl: "./leader-board.component.html",
  styleUrls: ["./leader-board.component.css"]
})
export class LeaderBoardComponent implements OnInit {
  // loading
  loading = true;
  familyLoading = true;
  friendLoading = true;
  clubLoading = true;

  userId = "";
  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 10;
  public family = null;
  public users: any = [];
  public friends: any = [];
  public members: any = [];
  public clubMembers: any = [];
  public clubs: any = [];
  public position: number = 0;
  public distance: number = 0.0;
  public tierDistance: number = 0.0;
  public distanceComplete: any = 0;
  public dailyPosition: number = 0;
  public dailyPositionFriend: number = 0;
  constructor(
    private Api: ApiService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.getLeaderboard();
    this.getFamilyLeaderboard();
    this.getFriendsLeaderboard();
    this.getClubLeaderboard()
    this.getUserTier();
  }

  getUserTier() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.distance = res.data.distance;
        this.tierDistance = res.data.tier.distance;
        this.distanceComplete = 100 - (this.distance / this.tierDistance) * 100;
        this.distanceComplete = this.distanceComplete.toFixed(0);
        this.family = res.data.family;
      }
    });
  }

  getLeaderboard() {
    this.loading = true;
    this.Api.get("/user/leaderboard").subscribe((result: any) => {
      if (result.data) {
        this.authenticationService.currentUser.subscribe(user => {
          if (user) {
            this.userId = user.data._id;
          }
        });
        this.users = result.data.users;
        this.dailyPosition = result.data.dailyPosition;
        this.position =
          this.users.findIndex(obj => obj._id === this.userId) + 1;
      }
      this.loading = false;
    });
  }

  getFamilyLeaderboard() {
    this.familyLoading = true;
    this.Api.get("/family/leaderboard").subscribe((result: any) => {
      if (result.data) {
        this.members = result.data.users;
      }
      this.familyLoading = false;
    });
  }

  getFriendsLeaderboard() {
    this.friendLoading = true;
    this.Api.get("/friends/leaderboard").subscribe((result: any) => {
      if (result.data) {
        this.friends = result.data.users;
        this.dailyPositionFriend = result.data.dailyPosition;
      }
      this.friendLoading = false;
    });
  }

  getClubLeaderboard() {
    this.clubLoading = true;
    this.Api.get("/clubs/leaderboard").subscribe((result: any) => {
      if (result.data) {
        this.clubs = result.data.clubs;
      }
      this.clubLoading = false;
    });
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }
}
