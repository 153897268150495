import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../../../models';
import { UserService, ApiService, AuthenticationService } from '../../../../../services';
import { storage } from '../../../../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { first } from 'rxjs/operators';

@Component({
  selector: "app-user-profile-photo-edit",
  templateUrl: "./user-profile-photo-edit.component.html",
  styleUrls: ["./user-profile-photo-edit.component.css"]
})
export class UserProfilePhotoEditComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  loading = false;
  submitted = false;
  photoUrl: string = storage.S3 + "/user/profile/";
  userPhoto = "assets/img/default/placeholder-person.png";
  userBackground = "assets/img/auth/register.jpg";

  constructor(
    private userService: UserService,
    private notifier: NotifierService,
    private Api: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      res => {
        this.currentUser = res;
      }
    );

    this.getUserProfile();
  }

  ngOnInit() {}

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.currentUser.data = res.data;
        this.userPhoto = this.photoUrl + this.currentUser.data.profile.photo;
        this.userBackground =
          this.photoUrl + this.currentUser.data.profile.banner;
      }
    });
  }
  photoPreview(file) {
    if (file.length === 0) {
      return;
    }

    let mimeType = file[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.notifier.notify("error", "Only images are supported.");
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = _event => {
      this.userService.uploadProfilePhoto(file[0]).subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Profile photo has been updated successful"
            );
            this.currentUser.data.profile.photo = res.data;
            this.userPhoto = this.photoUrl + res.data;
            this.loading = false;
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
    };
  }

  backgroundPreview(file) {
    if (file.length === 0) {
      return;
    }

    let mimeType = file[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.notifier.notify("error", "Only images are supported.");
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = _event => {
      this.userService.uploadProfileBanner(file[0]).subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Profile banner has been updated successful"
            );
            this.currentUser.data.profile.banner = res.data;
            this.userBackground = this.photoUrl + res.data;
            this.loading = false;
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
    };
  }
}
