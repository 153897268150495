import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  UserService,
  AuthenticationService,
  ApiService
} from "../../../services";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions
} from "ngx-stripe";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { first } from "rxjs/operators";
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare var paypal;
declare const FB: any;
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"]
})
export class PaymentComponent implements OnInit {
  @ViewChild('paypal', { static: false }) paypalElement: ElementRef;
  plan: any;
  order: any;
  subscription: any;
  basicAuth = 'Basic QWNWUTBIX05QTVlWMDIzSDhMM3Y2alhNcDRVdaUN2V0M4Mmo4a19hodjdkdS14M3F4dFJ6Y2pNTnRPcGN6OUpPdjU1TW9jTllsEV1p5WURWNm46RUZJRWtJd0dYdDFJSTdFRmlEdVQ3UWExV2ZXWDZnYmw3Z2w5ajgwZVlsVjI1ODdfUTRHSUxCSWxZfOGg1SzRRZTFhMZU1yVgFZGRThIWXAyRjA=';  //Pass your ClientId + scret key
  isMobile = /iPhone/i.test(window.navigator.userAgent)
  loading = false;
  submitted = false;
  paymentMethods = [
    { id: 0, name: "CREDIT CARD" }
    ,
    { id: 1, name: "PAYPAL" }
  ];
  paymentmethod = 1;

  cardNumberElement: any;
  elements: Elements;
  card: StripeElement;
  token = null;
  isPaid = false;
  user: any;
  paymentMethodToken = null;
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: "en"
  };
  public payPalConfig?: IPayPalConfig;
  stripeTest: FormGroup;
  regEmail = null;
  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    private router: Router,
    private Api: ApiService,
    private notifier: NotifierService,
    private userService: UserService
  ) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.stripeService.elements(this.elementsOptions).subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.cardNumberElement = this.elements.create("cardNumber", {});
        this.cardNumberElement.mount("#card-cardNumber");
        var cardNumberElement = this.elements.create("cardExpiry", {});
        cardNumberElement.mount("#card-cardExpiry");
        var cardNumberElement = this.elements.create("cardCvc", {});
        cardNumberElement.mount("#card-cardcvv");
      }
    });
    const self = this;
    this.plan = 'P-8TC72503HD607571UL2CFEJY';  //Default Plan Id
    if (this.user.membership == 1 && this.user.coupon === '') {
      this.plan = 'P-8TC72503HD607571UL2CFEJY'; // Monthly Membership
    }

    if (this.user.membership == 1 && this.user.coupon != '') {
      this.plan = 'P-41E81003RW9059216L2HH6GY'; // Monthly trial Membership
    }

    if (this.user.membership == 2 && this.user.coupon === '') {
      this.plan = 'P-80W04532KP1123240L2HHUVY'; // Annual Membership
    }
    if (this.user.membership == 2 && this.user.coupon != '') {
      this.plan = 'P-3RS37706FT257472HL2HH24A';  // Annual trial Membership
    }
    if (this.user.membership == 3 && this.user.coupon === '') {
      this.plan = 'P-5TE536167X761905ML26NQBQ'; // Monthly Family Membership
    }

    if (this.user.membership == 3 && this.user.coupon != '') {
      this.plan = 'P-2S486744PC805534NL26NZNY'; // Monthly Family trial Membership
    }

    if (this.user.membership == 4 && this.user.coupon === '') {
      this.plan = 'P-4MC38838LB339861VL26NTNQ'; // Annual Family Membership
    }
    if (this.user.membership == 4 && this.user.coupon != '') {
      this.plan = 'P-05W30860TC740482LL26OERQ';  // Annual Family trial Membership
    }

    paypal.Buttons({
      // Configure environment
      env: 'sandbox',
      client: {
        sandbox: 'ARszb-7WcXufCqV649WUibBC-PA4OJ0K214K566ylDUOvnJzWP1WI_9pmR1-NkyiQlfyukJX5zR9PfxL',
        production: 'demo_production_client_id'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,
      createSubscription: function (data, actions) {
        return actions.subscription.create({
          'plan_id': self.plan,
          // 'TRIALBILLINGPERIOD': 'Week',
          // 'TRIALBILLINGFREQUENCY': 1,
          // 'TRIALTOTALBILLINGCYCLES': 1,
          // 'TRIALAMT': 0.00,
        });
      },
      onApprove: (data, actions) => {
        this.subscription = data.subscriptionID
        this.order = data.orderID;
        this.paypay();
        // alert('You have successfully created subscription ' + data.subscriptionID);
        // self.getSubcriptionDetails(data.subscriptionID);
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        if (data.status == 'COMPLETED') {
          this.paypay();
        }

      },
      onCancel: (data, actions) => {
        //    console.log('OnCancel', data, actions);
      },
      onError: err => {
        //  console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //  console.log('onClick', data, actions);
      },
    }).render('#paypal');

  }
  ngOnInit() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '331323564452768',
        cookie: true,
        xfbml: true,
        version: 'v3.2'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/es_LA/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    this.stripeTest = this.formBuilder.group({
      name: ["", [Validators.required]]
    });
    this.user = JSON.parse(localStorage.getItem("user-data"));
    this.regEmail = localStorage.getItem("reg-email");
    if (!this.regEmail) {
      this.router.navigate(["/register"]);
    }
  }

  // ============Start Get Subcription Details Method============================
  getSubcriptionDetails(subcriptionId) {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // console.log(JSON.parse(this.responseText));
        // alert(JSON.stringify(this.responseText));
      }
    };
  }
  // ============END Get Subcription Details Method========================

  buy() {
    this.loading = true;
    const name = this.stripeTest.get("name").value;
    this.stripeService
      .createToken(this.cardNumberElement, { name })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          this.token = result.token.id;
          this.stripeService
            .createPaymentMethod("card", this.cardNumberElement)
            .subscribe(rest => {
              // Handle result.error or result.paymentMethod
              this.paymentMethodToken = rest.paymentMethod.id;
              if (this.paymentMethodToken) {
                this.pay();
              }
            });
        } else if (result.error) {
          this.loading = false;
          // Error creating the token
          this.notifier.notify("error", result.error.message);
        }
      });
  }

  pay() {
    this.submitted = true;
    this.loading = true;
    this.userService
      .register(this.user)
      .pipe(first())
      .subscribe(
        data => {
          this.Api.post("/auth/pay", {
            token: this.token,
            pm: this.paymentMethodToken,
            email: this.regEmail,
            membership: this.user.membership,
            coupon: this.user.coupon
          }).subscribe(res => {
            if (res.data) {
              this.loading = false;
              localStorage.removeItem("reg-email");
              this.isPaid = true;
              this.notifier.notify("success", "Registration successful");
              var registrationMethod = this.user.referralBy
              this.logCompleteRegistrationEvent(registrationMethod)
              if (this.user.referralBy == "Ride Nation") {
                this.RideNationRegistrationEvent();
              }
            } else {
              this.loading = false;
              this.notifier.notify("error", "some thing went wrong");
            }
          });
        },
        error => {
          this.loading = false;
          this.notifier.notify("error", error);
        }
      );
  }

  paypay() {
    this.submitted = true;
    this.loading = true;
    this.userService
      .register(this.user)
      .pipe(first())
      .subscribe(
        data => {
          this.Api.post("/auth/payment/paypal", {
            email: this.regEmail,
            membership: this.user.membership,
            coupon: this.user.coupon,
            plan: this.plan,
            subscription: this.subscription,
            order: this.order
          }).subscribe(res => {
            if (res.data) {
              this.loading = false;
              localStorage.removeItem("reg-email");
              this.isPaid = true;
              this.notifier.notify("success", "Registration successful");
              var registrationMethod = this.user.referralBy
              this.logCompleteRegistrationEvent(registrationMethod)
              if (this.user.referralBy == "Ride Nation") {
                this.RideNationRegistrationEvent();
              }
            } else {
              this.loading = false;
              this.notifier.notify("error", "some thing went wrong");
            }
          });
        },
        error => {
          this.loading = false;
          this.notifier.notify("error", error);
        }
      );
  }

  logCompleteRegistrationEvent(registrationMethod) {
    var params = {};
    params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = registrationMethod;
    FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);
  }
  RideNationRegistrationEvent() {
    FB.AppEvents.logEvent('Ride Nation Registration')
  }
}
