import { Component, OnInit } from '@angular/core';

@Component({
  selector: "light-header",
  templateUrl: "./light-header.component.html",
  styleUrls: ["./light-header.component.css"]
})
export class LightHeaderComponent implements OnInit {
  MenuCollapsed= false;
  constructor() {}

  ngOnInit() {}
}
