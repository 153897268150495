import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RideService } from '../../../../../../services';
import { NotifierService } from 'angular-notifier';
import { storage } from '../../../../../../../environments/environment';
@Component({
  selector: "app-log-route",
  templateUrl: "./log-route.component.html",
  styleUrls: ["./log-route.component.css"]
})
export class LogRouteComponent implements OnInit {
  logRouteForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  photoUrl: string = storage.S3 + "/route/ride/";
  currentUserSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private rideService: RideService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/dashboard";

    this.logRouteForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", []],
      image: ["", []],
      distanceCompleted: [0, []],
      completedTime: [0, []],
      averageSpeed: [0, []],
      startLocationName: ["", []],
      startDate: ["", []],
      endLocationName: ["", []],
      endDate: ["", []]
    });
  }

  handleInputChange(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
      // this.groupForm.controls["photo"].setValue(reader.result);
      this.logRouteForm.patchValue({
        image: reader.result
      });
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.logRouteForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.logRouteForm.invalid) {
      return;
    }

    this.loading = true;

    this.rideService
      .rideLog(this.logRouteForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify("success", "Route has been logged successful");
            this.loading = false;
            this.submitted = false;
            this.logRouteForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
