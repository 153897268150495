import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { storage } from "../../../../../../../environments/environment";
import { TeamService, ApiService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-edit-group",
  templateUrl: "./edit-group.component.html",
  styleUrls: ["./edit-group.component.css"]
})
export class EditGroupComponent implements OnInit {
  teamForm: FormGroup;
  id = "";
  team: any;
  photoUrl: string = storage.S3 + "/groups/covers/";
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    private notifier: NotifierService,
    private Api: ApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.getTeam(this.id);
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/team/" + this.id;
    this.teamForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", []],
      photo: ["", []],
      membersLimit: ["", [Validators.required]],
      privacy: ["", [Validators.required]]
    });
  }

  getTeam(id: any) {
    this.Api.get(`/team/show/` + id).subscribe((result: any) => {
      if (result) {
        this.team = result.data;
        this.teamForm.get("name").setValue(this.team.name);
        this.teamForm.get("description").setValue(this.team.description);
        this.teamForm.get("membersLimit").setValue(this.team.membersLimit);
        this.teamForm.get("privacy").setValue(this.team.privacy);
        this.teamForm.get("photo").setValue(this.photoUrl + this.team.photo);
      }
    });
  }

  handleInputChange(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
      this.teamForm.patchValue({
        photo: reader.result
      });
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.teamForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.teamForm.invalid) {
      return;
    }

    this.loading = true;

    this.teamService
      .update(this.id, this.teamForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Team has been updated successfully"
            );
            this.loading = false;
            this.submitted = false;
            this.teamForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}