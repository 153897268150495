import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../../services";
import { storage } from "../../../../../../../environments/environment";
import { NotifierService } from "angular-notifier";
@Component({
  selector: "app-search-group",
  templateUrl: "./search-group.component.html",
  styleUrls: ["./search-group.component.css"]
})
export class SearchGroupComponent implements OnInit {
  public teams: any;
  public userTeams: any;
  public TeamRequests: any;
  public keyWord: any = "";
  loading = true;
  userTeamLoading = true;
  photoUrl: string = storage.S3 + "/groups/covers/";
  constructor(private Api: ApiService, private notifier: NotifierService) { }

  ngOnInit() {
    this.getTeams();
    this.getUserTeams();
    this.getTeamRequests();
  }

  joinTeam(team) {
    this.Api.post("/team/join", { team: team }).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Team has been joined successful");
        this.loading = false;
        this.getTeams();
      }
    });
  }

  getTeamRequests() {
    this.Api.get("/user/teams/invitations").subscribe((result: any) => {
      if (result.data.length > 0) {
        this.TeamRequests = result.data;
      }
    });
  }
  getUserTeams() {
    this.userTeamLoading = true;
    this.Api.get("/user/teams/all").subscribe((result: any) => {
      if (result.data.length > 0) {
        this.userTeams = result.data;
      }
      this.userTeamLoading = false;
    });
  }

  getTeams() {
    this.loading = true;
    this.Api.get("/teams/search", {
      keyword: this.keyWord
    }).subscribe((result: any) => {
      if (result.data) {
        this.teams = result.data;
      }
      this.loading = false;
    });
  }

  searchTeams() {
    this.getTeams();
  }

  deleteTeams(id) {
    if (confirm("Are you sure to delete ")) {
      this.Api.delete(`/team/delete/` + id).subscribe((result: any) => {
        this.notifier.notify("success", "Team has been deleted successfully");
        this.getTeams();
      });
    }
  }
}
