import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { storage } from "../../../../../../../environments/environment";
import { MemberService, ApiService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-edit-member",
  templateUrl: "./edit-member.component.html",
  styleUrls: ["./edit-member.component.css"]
})
export class EditMemberComponent implements OnInit {
  memberForm: FormGroup;
  id = "";
  member: any;
  photoUrl: string = storage.S3 + "/groups/covers/";
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private notifier: NotifierService,
    private Api: ApiService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
    this.getMember(this.id);
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/account";
    this.memberForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      familyMemberType: ["child", [Validators.required]],
      restriction: [true, [Validators.required]]
    });
  }

  getMember(id: any) {
    this.Api.get(`/family/member/` + id).subscribe((result: any) => {
      if (result) {
        this.member = result.data;
        this.memberForm
          .get("firstName")
          .setValue(this.member.profile.name.first);
        this.memberForm.get("lastName").setValue(this.member.profile.name.last);
        this.memberForm
          .get("familyMemberType")
          .setValue(this.member.familyMemberType);
        this.memberForm.get("restriction").setValue(this.member.restriction);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.memberForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.memberForm.invalid) {
      return;
    }

    this.loading = true;

    this.memberService
      .update(this.id, this.memberForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "member has been updated successfully"
            );
            this.loading = false;
            this.submitted = false;
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
