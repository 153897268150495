import { Component, OnInit } from '@angular/core';
import { OrderService } from "../../../../../services/order/order.service";
import {
  UserService,
  AuthenticationService,
  ApiService
} from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
import { RedeemPointService } from "../../../../../services/redeem-point/redeem-point.service";

@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.css']
})
export class UserOrdersComponent implements OnInit {

  public pages: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 10;
  page: number;
  orders: any;
  user: any;
  sum = 0;
  orderDetail : any;
  total : any;
  orderId: any;
  photoUrl: string = storage.S3 + '/shop/products/';
  shippingCost: any
  loading = false;
  pointPerDolar: any = 1000;

  constructor(
    private OrderApi: OrderService,
    private Api: ApiService,
    private RedeemApi: RedeemPointService
  ) {}
  ngOnInit() {
    this.getUserProfile();
    this.getPoints();
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
       this.user = res.data;
       this.getMyOrders();
      } else {
        console.log('user profile called: no data found')
      }
    });
  }

  getMyOrders(){
    this.OrderApi.getUserOrders(this.user._id, this.perPage, this.currentPage).subscribe((result:any) => {
      if (result.data) {
        this.orders = result.data.orders;
        this.totalPages = result.data.pages;
        this.pages = Array.from(Array(result.data.pages).keys());
        this.currentPage = result.data.page;
      }
    })
  }


  calculateShippingCost(items) {
    this.shippingCost = 0;
    for (let item of items) {
      this.shippingCost = this.shippingCost + item.shippingCost;
    }
    return this.shippingCost;
  }

  nextPage(page) {
    if (page + 1 <= this.totalPages) {
      this.currentPage = page + 1;
      this.getMyOrders();
    }
  }

  previousPage(page) {
    if (page - 1 >= 0) {
      this.currentPage = page - 1;
      this.getMyOrders();
    }
  }

  pageChanged(event: any): void {
    this.page = event.page;
  }

  print(orderId:any) {
    this.orderId = orderId;
    this.getOrder(this.orderId);
  }

  getOrder(id) {
    this.OrderApi.getOrderByID(id).subscribe((result:any) => {
      if (result.data) {
        document.title = result.data.number;
        this.orderDetail = result.data;
        setTimeout(function(){
          window.print();
        }, 3000);
      }
    })
  }

  getPoints() {
    this.RedeemApi.getPoint().subscribe((result:any) => {
      if (result) {
        this.pointPerDolar = result.data.points;
      }
    })
  }

  getPerProductTotal(unitPrice, quantity, redeemPoint, shipping) {
    let total = 0;
    let pointValue = redeemPoint > 0 ? redeemPoint / this.pointPerDolar : 0;
    total = unitPrice * quantity + shipping - pointValue;
    return total; 
  }

  calculateTotal() {
    for (let item of this.orderDetail.products) {
      this.total = this.total + item.price;
    }
  }

  getShipingStatus(vendorOrders:any) {
    let allShippingStatus = [];
    if (vendorOrders && vendorOrders.length > 0) {
      vendorOrders.filter(function(item) {
        allShippingStatus.push(item.shippingStatus.toLowerCase());
      });

      if(allShippingStatus.includes('cancelled') && allShippingStatus.length == 0) {
        return allShippingStatus[0];
      } else {
        let cancelledIndex = allShippingStatus.indexOf('cancelled');
        if(cancelledIndex > -1){
          allShippingStatus.splice(cancelledIndex, 1);
        }
        let allEquall = allShippingStatus.every( (val, i, allShippingStatus) => val === allShippingStatus[0] );
        return allEquall ? allShippingStatus[0] : 'Pending';
      }

    }
  }

}
