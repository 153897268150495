import { JwtInterceptor, ErrorInterceptor } from "src/app/shared/interceptors";
import {
  HttpClientModule,
  HttpClientJsonpModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";
import { NgCircleProgressModule } from "ng-circle-progress";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxPayPalModule } from 'ngx-paypal';

// Services
import { ApiService, AuthService, UserService, TokenService } from "./services";
import { AfterLoginService } from "./services/auth/after-login.service";
import { BeforeLoginService } from "./services/auth/before-login.service";

// DEP
import { NotifierModule } from "angular-notifier";
import { AgmCoreModule } from "@agm/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CollapseModule } from "ngx-bootstrap";
import { ModalModule } from "ngx-bootstrap/modal";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgSelectModule } from "@ng-select/ng-select";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ChartsModule } from "ng2-charts";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";
// Components
import { AppComponent } from "./app.component";
import { TopbarComponent } from "./modules/frontend/common/topbar/topbar.component";
import { HeaderComponent } from "./modules/frontend/common/header/header.component";
import { FooterComponent } from "./modules/frontend/common/footer/footer.component";
// Auth
import { LoginComponent } from "./modules/auth/login/login.component";
import { RegisterComponent } from "./modules/auth/register/register.component";
import { PasswordRecoverComponent } from "./modules/auth/password-recover/password-recover.component";
import { PasswordResetComponent } from "./modules/auth/password-reset/password-reset.component";
import { PasswordEditComponent } from "./modules/dashboard/user-dashboard/components/profile/password-edit/password-edit.component";

import { HomeComponent } from "./modules/frontend/components/home/home.component";
import { AlertComponent } from "./modules/dashboard/user-dashboard/common/alert/alert.component";

// Widgets
import { UserProfileWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/user-profile-widget/user-profile-widget.component";
import { DownloadAppWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/download-app-widget/download-app-widget.component";
import { SubroutesWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/subroutes-widget/subroutes-widget.component";
import { FindFriendWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/find-friend-widget/find-friend-widget.component";
import { GroupsWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/groups-widget/groups-widget.component";
import { CaloriesDistanceInfoWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/calories-distance-info-widget/calories-distance-info-widget.component";

// User Dashboard
import { UserDashboardComponent } from "./modules/dashboard/user-dashboard/user-dashboard.component";

import { ProfileComponent } from "./modules/dashboard/user-dashboard/components/profile/profile.component";
import { ProfileEditComponent } from "./modules/dashboard/user-dashboard/components/profile/profile-edit/profile-edit.component";
import { ImportComponent } from "./modules/dashboard/user-dashboard/components/import/import.component";
import { RewardsComponent } from "./modules/dashboard/user-dashboard/components/rewards/rewards.component";
import { EventsComponent } from "./modules/dashboard/user-dashboard/components/events/events.component";
import { LeaderBoardComponent } from "./modules/dashboard/user-dashboard/components/leader-board/leader-board.component";

// goals
import { GoalsComponent } from "./modules/dashboard/user-dashboard/components/goals/goals.component";
import { CreateGoalComponent } from "./modules/dashboard/user-dashboard/components/goals/create/create-goal.component";
// routes
import { CreateRoutComponent } from "./modules/dashboard/user-dashboard/components/routes/create-rout/create-rout.component";
import { RoutesComponent } from "./modules/dashboard/user-dashboard/components/routes/routes.component";
import { LogRouteComponent } from "./modules/dashboard/user-dashboard/components/routes/log-route/log-route.component";
// challenges
import { ChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/challenges.component";
import { ChallengesCreateComponent } from "./modules/dashboard/user-dashboard/components/challenges/challenges-create/challenges-create.component";

// Pages
import { FaqComponent } from "./modules/frontend/components/pages/faq/faq.component";
import { ContactComponent } from "./modules/frontend/components/pages/contact/contact.component";
import { OurAppComponent } from "./modules/frontend/components/pages/our-app/our-app.component";
import { FeatureComponent } from "./modules/frontend/components/pages/feature/feature.component";
import { ClubsComponent } from "./modules/frontend/components/pages/clubs/clubs.component";
import { EventsPageComponent } from "./modules/frontend/components/pages/events/events.component";
import { MembershipComponent } from "./modules/frontend/components/pages/membership/membership.component";
import { RewardComponent } from "./modules/frontend/components/pages/reward/reward.component";
import { UserActivitiesComponent } from "./modules/dashboard/user-dashboard/widgets/user-activities/user-activities.component";
import { UserRideStatesComponent } from "./modules/dashboard/user-dashboard/widgets/user-ride-states/user-ride-states.component";
import { UserProfilePhotoEditComponent } from "./modules/dashboard/user-dashboard/widgets/user-profile-photo-edit/user-profile-photo-edit.component";
import { MapBoxComponent } from "./modules/dashboard/user-dashboard/common/map-box/map-box.component";
import { ActivityComponent } from "./modules/dashboard/user-dashboard/components/activity/activity.component";
import { UserPositionInfoComponent } from "./modules/dashboard/user-dashboard/widgets/user-position-info/user-position-info.component";
import { FriendsComponent } from "./modules/dashboard/user-dashboard/components/friends/friends/friends.component";
import { SearchFriendComponent } from "./modules/dashboard/user-dashboard/components/friends/search-friend/search-friend.component";
import { GroupsComponent } from "./modules/dashboard/user-dashboard/components/groups/groups/groups.component";
import { SearchGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/search-group/search-group.component";
import { UserProfilePhotoComponent } from "./modules/dashboard/user-dashboard/widgets/user-profile-photo/user-profile-photo.component";
import { CreateEventComponent } from "./modules/dashboard/user-dashboard/components/events/create-event/create-event.component";
import { EditEventComponent } from "./modules/dashboard/user-dashboard/components/events/edit-event/edit-event.component";
import { CreateGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/create-group/create-group.component";
import { GroupComponent } from "./modules/dashboard/user-dashboard/components/groups/group/group.component";
import { FriendComponent } from "./modules/dashboard/user-dashboard/components/friends/friend/friend.component";
import { FrindRequestsComponent } from "./modules/dashboard/user-dashboard/components/friends/frind-requests/frind-requests.component";
import { FrindPendingRequestsComponent } from "./modules/dashboard/user-dashboard/components/friends/frind-pending-requests/frind-pending-requests.component";
import { GroupsJoinComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-join/groups-join.component";
import { GroupsRequestComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-request/groups-request.component";
import { EditGroupComponent } from "./modules/dashboard/user-dashboard/components/groups/edit-group/edit-group.component";
import { LightHeaderComponent } from "./modules/frontend/common/light-header/light-header.component";
import { JoinChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/join-challenges/join-challenges.component";
import { ReviewChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/review-challenges/review-challenges.component";
import { SocialShareWidgetComponent } from "./modules/dashboard/user-dashboard/widgets/social-share-widget/social-share-widget.component";
import { ViewEventComponent } from "./modules/dashboard/user-dashboard/components/events/view-event/view-event.component";
import { JoinEventComponent } from "./modules/dashboard/user-dashboard/components/events/join-event/join-event.component";
import { GroupsViewComponent } from "./modules/dashboard/user-dashboard/components/groups/groups-view/groups-view.component";
import { ViewGoalComponent } from "./modules/dashboard/user-dashboard/components/goals/view-goal/view-goal.component";
import { EditChallengesComponent } from "./modules/dashboard/user-dashboard/components/challenges/edit-challenges/edit-challenges.component";
import { YourGroupsComponent } from "./modules/dashboard/user-dashboard/components/groups/your-groups/your-groups.component";
import { LeaderBoardEventComponent } from "./modules/dashboard/user-dashboard/components/events/leader-board-event/leader-board-event.component";
import { SingleEventpageComponent } from "./modules/frontend/components/pages/events/single-eventpage/single-eventpage.component";
import { SingleStageComponent } from "./modules/dashboard/user-dashboard/components/events/single-stage/single-stage.component";
import { GroupMembersComponent } from "./modules/dashboard/user-dashboard/components/groups/group/group-members/group-members.component";
import { PaymentComponent } from "./modules/auth/payment/payment.component";
import { NgxStripeModule } from "ngx-stripe";
import { TermsComponent } from "./modules/frontend/components/pages/terms/terms.component";
import { PrivacyComponent } from "./modules/frontend/components/pages/privacy/privacy.component";
import { AccountInfo } from "./modules/frontend/components/pages/account-info/account-info.component";
import { SitemapComponent } from "./modules/frontend/components/pages/sitemap/sitemap.component";
import { MoreEventsComponent } from "./modules/dashboard/user-dashboard/components/events/more-events/more-events.component";
import { UpgradeMembershipComponent } from "./modules/dashboard/user-dashboard/components/membership/upgrade-membership/upgrade-membership.component";
import { RegistrationComponent } from "./modules/auth/registration/registration.component";
import { TermsContentComponent } from "./modules/frontend/components/pages/terms/terms-content/terms-content.component";
import { ContactSupportComponent } from "./modules/dashboard/user-dashboard/components/contact-support/contact-support.component";
import { UserLeftSidebarComponent } from "./modules/dashboard/user-dashboard/common/user-left-sidebar/user-left-sidebar.component";
import { UserRightSidebarComponent } from "./modules/dashboard/user-dashboard/common/user-right-sidebar/user-right-sidebar.component";
import { MyRideComponent } from "./modules/dashboard/user-dashboard/components/my-ride/my-ride.component";
import { FamilyComponent } from "./modules/dashboard/user-dashboard/components/family/family.component";
import { AddMemberComponent } from "./modules/dashboard/user-dashboard/components/family/add-member/add-member.component";
import { EditMemberComponent } from "./modules/dashboard/user-dashboard/components/family/edit-member/edit-member.component";
import { UserSharedModule } from "./modules/dashboard/user-shared-module";
import { EditFamilyComponent } from "./modules/dashboard/user-dashboard/components/family/edit-family/edit-family.component";
import { AccountComponent } from './modules/dashboard/user-dashboard/components/account/account.component';
import { UserOrdersComponent } from './modules/dashboard/user-dashboard/components/user-orders/user-orders.component';
import { UserOrderViewComponent } from './modules/dashboard/user-dashboard/components/user-order-view/user-order-view.component';
import { ActivityRouteComponent } from './modules/frontend/components/pages/activity/activity-route/activity-route.component';
import { LandingEventsComponent } from './modules/frontend/components/pages/events/landing-events/landing-events.component';
import { ShortenPipe } from "./shared/helpers/read-more";

@NgModule({
  declarations: [
    ShortenPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    // Default
    HomeComponent,
    AlertComponent,
    // Widgets
    UserProfileWidgetComponent,
    DownloadAppWidgetComponent,
    SubroutesWidgetComponent,
    FindFriendWidgetComponent,
    GroupsWidgetComponent,
    // User Dashboard
    UserDashboardComponent,
    ImportComponent,
    ProfileComponent,
    ProfileEditComponent,
    PasswordRecoverComponent,
    PasswordResetComponent,
    PasswordEditComponent,
    RewardsComponent,
    EventsComponent,
    LeaderBoardComponent,
    //goal
    GoalsComponent,
    CreateGoalComponent,
    // routes
    RoutesComponent,
    CreateRoutComponent,
    LogRouteComponent,
    //  pages
    FaqComponent,
    ContactComponent,
    OurAppComponent,
    FeatureComponent,
    ClubsComponent,
    EventsPageComponent,
    MembershipComponent,
    TopbarComponent,
    RewardComponent,
    UserActivitiesComponent,
    CaloriesDistanceInfoWidgetComponent,
    ChallengesComponent,
    UserRideStatesComponent,
    UserProfilePhotoEditComponent,
    MapBoxComponent,
    ActivityComponent,
    UserPositionInfoComponent,
    ChallengesCreateComponent,
    FriendsComponent,
    SearchFriendComponent,
    GroupsComponent,
    SearchGroupComponent,
    UserProfilePhotoComponent,
    CreateEventComponent,
    CreateGroupComponent,
    GroupComponent,
    FriendComponent,
    FrindRequestsComponent,
    FrindPendingRequestsComponent,
    GroupsJoinComponent,
    GroupsRequestComponent,
    EditGroupComponent,
    LightHeaderComponent,
    JoinChallengesComponent,
    ReviewChallengesComponent,
    SocialShareWidgetComponent,
    ViewEventComponent,
    EditEventComponent,
    JoinEventComponent,
    GroupsViewComponent,
    ViewGoalComponent,
    EditChallengesComponent,
    YourGroupsComponent,
    LeaderBoardEventComponent,
    SingleEventpageComponent,
    SingleStageComponent,
    GroupMembersComponent,
    PaymentComponent,
    TermsComponent,
    PrivacyComponent,
    AccountInfo,
    SitemapComponent,
    MoreEventsComponent,
    UpgradeMembershipComponent,
    RegistrationComponent,
    TermsContentComponent,
    ContactSupportComponent,
    UserLeftSidebarComponent,
    UserRightSidebarComponent,
    MyRideComponent,
    FamilyComponent,
    AddMemberComponent,
    EditMemberComponent,
    EditFamilyComponent,
    AccountComponent,
    UserOrdersComponent,
    UserOrderViewComponent,
    ActivityRouteComponent,
    LandingEventsComponent,
    
  ],
  imports: [
    GooglePlaceModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    NgxStripeModule.forRoot("pk_live_2f3Z9tmOIjL4KKiHM8D9ROLz00O1o28K3B"), // live
    // NgxStripeModule.forRoot("pk_test_nhveCAdPsroGYbgxZAjZpVrw00PbIiQEdM"), // test
    NgxPayPalModule,
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    NotifierModule.withConfig({
      position: {
        horizontal: {
          /**
           * Defines the horizontal position on the screen
           * @type {'left' | 'middle' | 'right'}
           */
          position: "right",

          /**
           * Defines the horizontal distance to the screen edge (in px)
           * @type {number}
           */
          distance: 12
        },

        vertical: {
          /**
           * Defines the vertical position on the screen
           * @type {'top' | 'bottom'}
           */
          position: "bottom",

          /**
           * Defines the vertical distance to the screen edge (in px)
           * @type {number}
           */
          distance: 12,

          /**
           * Defines the vertical gap, existing between multiple notifications (in px)
           * @type {number}
           */
          gap: 10
        }
      },
      behaviour: {
        /**
         * Defines whether each notification will hide itself automatically after a timeout passes
         * @type {number | false}
         */
        autoHide: 5000,

        /**
         * Defines what happens when someone clicks on a notification
         * @type {'hide' | false}
         */
        onClick: false,

        /**
         * Defines what happens when someone hovers over a notification
         * @type {'pauseAutoHide' | 'resetAutoHide' | false}
         */
        onMouseover: "pauseAutoHide",

        /**
         * Defines whether the dismiss button is visible or not
         * @type {boolean}
         */
        showDismissButton: true,

        /**
         * Defines whether multiple notification will be stacked, and how high the stack limit is
         * @type {number | false}
         */
        stacking: 4
      },
      animations: {
        /**
         * Defines whether all (!) animations are enabled or disabled
         * @type {boolean}
         */
        enabled: true,

        show: {
          /**
           * Defines the animation preset that will be used to animate a new notification in
           * @type {'fade' | 'slide'}
           */
          preset: "slide",

          /**
           * Defines how long it will take to animate a new notification in (in ms)
           * @type {number}
           */
          speed: 300,

          /**
           * Defines which easing method will be used when animating a new notification in
           * @type {'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out'}
           */
          easing: "ease"
        },

        hide: {
          /**
           * Defines the animation preset that will be used to animate a new notification out
           * @type {'fade' | 'slide'}
           */
          preset: "fade",

          /**
           * Defines how long it will take to animate a new notification out (in ms)
           * @type {number}
           */
          speed: 300,

          /**
           * Defines which easing method will be used when animating a new notification out
           * @type {'linear' | 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out'}
           */
          easing: "ease",

          /**
           * Defines the animation offset used when hiding multiple notifications at once (in ms)
           * @type {number | false}
           */
          offset: 50
        },

        shift: {
          /**
           * Defines how long it will take to shift a notification around (in ms)
           * @type {number}
           */
          speed: 300,

          /**
           * Defines which easing method will be used when shifting a notification around
           * @type {string}
           */
          easing: "ease" // All standard CSS easing methods work
        },

        /**
         * Defines the overall animation overlap, allowing for much smoother looking animations (in ms)
         * @type {number | false}
         */
        overlap: 150
      }
    }),
    //	NgProgressModule,
    // NgProgressHttpModule,
    NgSelectModule,
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBMEJUjND8_hxqIkoVFMJmfabEa8D8iuzk"
    }),
    NgCircleProgressModule.forRoot(),
    UserSharedModule,
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
    ApiService,
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
