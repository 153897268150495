export enum Role {
	User = 'cyclist',
	Vendor = 'vendor',
	clubAdmin = 'clubAdmin',
	Account = 'account',
	Admin = 'admin',
	superAdmin = 'superadmin',
	Stakeholder = 'stakeholder',
	Developer = 'developer'
}
