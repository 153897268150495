import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class PickService {
  constructor(private Api: ApiService) {}

  getPicks() {
    return this.Api.get(`/picks`);
  }

  store(pick: any) {
    return this.Api.post("/pick/create", pick);
  }

  update(id: any, pick: any) {
    return this.Api.put("/pick/" + id, pick);
  }

  getById(id: string) {
    return this.Api.get(`/pick/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/pick/` + id);
  }
}