import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import {
  UserService,
  AuthenticationService,
  ApiService
} from "../../../../../../services";
import { User } from "../../../../../../models";
import { NotifierService } from "angular-notifier";
@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styleUrls: ["./profile-edit.component.css"]
})
export class ProfileEditComponent implements OnInit {
  profileForm: FormGroup;
  loading = false;
  submitted = false;
  currentUser: User;
  currentUserSubscription: Subscription;
  clubs: any = [];
  club: any = null;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private notifier: NotifierService,
    private Api: ApiService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    );
  }
  ngOnInit() {
    this.getUserProfile();
    this.getClubs();

    this.profileForm = this.formBuilder.group({
      email: [
        this.currentUser.data.email,
        [Validators.required, Validators.email]
      ],
      firstName: [
        this.currentUser.data.profile.name.first,
        [Validators.required]
      ],
      lastName: [
        this.currentUser.data.profile.name.last,
        [Validators.required]
      ],
      nickName: [this.currentUser.data.profile.nickName, [Validators.required]],
      clubId: [this.currentUser.data.clubId],
      age: [this.currentUser.data.profile.age, []],
      dob: [this.currentUser.data.profile.dob, [Validators.required]],
      gender: [this.currentUser.data.profile.gender, []],
      hometown: [this.currentUser.data.address.hometown, []],
      address: [this.currentUser.data.address.address, []],
      weight: [this.currentUser.data.profile.weight, []],
      height: [this.currentUser.data.profile.height, []],
      isNotification: [
        this.currentUser.data.isNotification,
        [Validators.required]
      ],
      appNotification: [
        this.currentUser.data.appNotification,
        [Validators.required]
      ],
      webNotification: [
        this.currentUser.data.webNotification,
        [Validators.required]
      ]
    });
  }

  getClubs() {
    this.Api.get("/clubs").subscribe(res => {
      this.clubs = res.data;
    });
  }
  filterClub(ev = null) { }

  handleAddressChange(address) {
    this.profileForm.get("address").setValue(address.formatted_address);
  }
  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.currentUser.data = res.data;
        let dob = res.data.dob.split("/");
        let date = new Date(dob[2], dob[1] - 1, dob[0]);
        this.profileForm.get("email").setValue(this.currentUser.data.email);
        this.profileForm
          .get("firstName")
          .setValue(this.currentUser.data.profile.name.first);
        this.profileForm
          .get("lastName")
          .setValue(this.currentUser.data.profile.name.last);
        this.profileForm
          .get("nickName")
          .setValue(this.currentUser.data.profile.nickName);
        this.profileForm.get("dob").setValue(date);
        this.profileForm.get("clubId").setValue(this.currentUser.data.clubId);
        this.profileForm.get("age").setValue(this.currentUser.data.profile.age);
        this.profileForm
          .get("gender")
          .setValue(this.currentUser.data.profile.gender);
        this.profileForm
          .get("hometown")
          .setValue(this.currentUser.data.address.hometown);
        this.profileForm
          .get("address")
          .setValue(this.currentUser.data.address.address);
        this.profileForm
          .get("weight")
          .setValue(this.currentUser.data.profile.weight);
        this.profileForm
          .get("height")
          .setValue(this.currentUser.data.profile.height);
        this.profileForm
          .get("isNotification")
          .setValue(this.currentUser.data.isNotification);
        this.profileForm
          .get("appNotification")
          .setValue(this.currentUser.data.appNotification);
        this.profileForm
          .get("webNotification")
          .setValue(this.currentUser.data.webNotification);


      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileForm.controls;
  }

  onProfileSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    let date = new Date(this.profileForm.value.dob);
    this.profileForm
      .get("dob")
      .setValue(
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    this.loading = true;
    this.userService
      .profileUpdate(this.profileForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.notifier.notify(
            "success",
            "Profile has been updated successfully"
          );
          this.getUserProfile();
          this.loading = false;
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
