import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService, AlertService, AuthenticationService, ApiService } from '../../../../../services';
import { User } from '../../../../../models';
import { storage } from '../../../../../../environments/environment';
import { VendorService } from '../../../../../services/vendor/vendor.service';

@Component({
	selector: 'app-admin-header',
	templateUrl: './admin-header.component.html',
	styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
	currentUser: User;
	role = 'guest';
	currentUserSubscription: Subscription;
	photoUrl: string = storage.S3 + '/user/profile/';
	public vendorProfile: any = "";
	vendorPhoto: string = storage.S3 + '/shop/vendors/';

	constructor(
		private router: Router,
		private userService: UserService,
		private authenticationService: AuthenticationService,
		private Api: ApiService,
		private VendorApi: VendorService
	) {
		this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
			if (this.currentUser) {
				this.role = this.currentUser.role;
			}
		});
	}
	ngOnInit() {
		this.getUserProfile();
		this.getUserVendorProfile();
	}

	getUserProfile() {
		this.Api.get(`/user/profile`).subscribe((res: any) => {
			if (res.data) {
				this.currentUser.data = res.data;

			}
		});
	}

	getUserVendorProfile() {
		if (this.role == 'vendor') {
			this.Api.get('/shop/user/' + this.currentUser.data._id + '/vendor-profile').subscribe((res: any) => {
				if (res.data) {
					this.vendorProfile = res.data;
				}
			});
		}
	}

	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/login']);
	}
}
