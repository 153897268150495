import { Injectable } from '@angular/core';
import { ApiService } from '../../services/server/api.service';

@Injectable({
	providedIn: 'root'
})
export class GoalService {
	constructor(private Api: ApiService) {}

	getGoals() {
		return this.Api.get(`/goals`);
	}

	store(goal: any) {
		return this.Api.post('/web/goal/create', goal);
	}
}
