import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css']
})
export class AccountInfo implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log("LIVE Call");
  }
}
