import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, ApiService } from '../../../../../../services';
import { MustMatch } from '../../../../../../shared/helpers/must-match.helper';
import { NotifierService } from 'angular-notifier';

@Component({
	selector: 'app-password-edit',
	templateUrl: './password-edit.component.html',
	styleUrls: ['./password-edit.component.css']
})
export class PasswordEditComponent implements OnInit {
	passwordForm: FormGroup;
	loading = false;
	submitted = false;

	passType = 'password';
	cpassType = 'password';
	url: string | ArrayBuffer;
	UserForm: any;
	constructor(
		private formBuilder: FormBuilder,
		private alertService: AlertService,
		private Api: ApiService,
		private notifier: NotifierService
	) { }

	ngOnInit() {
		this.passwordForm = this.formBuilder.group(
			{
				password: ['', [Validators.required, Validators.minLength(4)]],
				new_password: ['', [Validators.required, Validators.minLength(4)]],
				confirmPassword: ['', [Validators.required, Validators.minLength(4)]]
			},
			{
				validator: MustMatch('new_password', 'confirmPassword')
			}
		);
	}

	// convenience getter for easy access to form fields

	get p() {
		return this.passwordForm.controls;
	}
	onPasswordSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.passwordForm.invalid) {
			return;
		}

		this.loading = true;
		return this.Api.put(`/user/password/update`, this.passwordForm.value).pipe(first()).subscribe(
			(res) => {
				this.loading = false;
				this.notifier.notify('success', 'Password has been updated successfully');
			},
			(error) => {
				this.notifier.notify('error', error);
				this.loading = false;
			}
		);
	}

	// Password
	public togglePasswordType() {
		if (this.passType === 'password') {
			this.passType = 'text';
		} else {
			this.passType = 'password';
		}
	}
	public ctogglePasswordType() {
		if (this.cpassType === 'password') {
			this.cpassType = 'text';
		} else {
			this.cpassType = 'password';
		}
	}
}
