import { Component, OnInit } from '@angular/core';
import { Subscription, from } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService, ApiService, SocketService } from '../../../../../services';
import { User } from '../../../../../models';
import { storage } from '../../../../../../environments/environment';
import { NotifierService } from "angular-notifier";
import { CartService } from '../../../../../services/cart/cart.service';
@Component({
  selector: "user-dashboard-header",
  templateUrl: "./user-dashboard-header.component.html",
  styleUrls: ["./user-dashboard-header.component.css"]
})
export class UserDashboardHeaderComponent implements OnInit {
  userId = "";
  currentUser: User;
  points: number = 0;
  usedPoint: number = 0;
  role = "guest";
  cartItems: any;
  membership = 'lite';
  currentUserSubscription: Subscription;
  photoUrl: string = storage.S3 + "/user/profile/";
  notifications = [];
  socket: any;
  count: number = 0;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private Api: ApiService,
    private notifier: NotifierService,
    private CartApi: CartService,
  ) {
    this.CartApi.cartItems.subscribe(cItems => {
      this.cartItems = cItems;
      this.usedPoint = 0;
      this.cartItems.forEach((element) => {
        this.usedPoint = this.usedPoint + element.points;
      });
    });

    // this.getUserTier();
    this.socket = SocketService.getInstance();
    // this.getNotification();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
        if (this.currentUser) {
          this.role = this.currentUser.role;
          this.userId = this.currentUser.data._id;
          this.membership = this.currentUser.data.membership.type;
        }
      }
    );

    this.socket.on("connection", function (data) {
      this.socket.emit("setUserId", this.currentUser._id);
    });

    this.socket.on("notification", result => {
      // this.getNotification();
      if (result.data.user === this.userId) {
        this.notifications.unshift(result.data);
        this.count++;
        this.notifier.notify("success", result.data.title);
      }
    });
  }
  ngOnInit() {

    
    if (this.currentUser) {
      this.getUserTier();
      this.getUserProfile();
      this.getNotification();
      this.getCartItems();
    }
  }
  getCartItems() {
    this.CartApi.getCartItems().subscribe(cItems => {
      this.cartItems = cItems.data && cItems.data.data ? cItems.data.data : [];
      this.usedPoint = 0;
      this.cartItems.forEach((element) => {
        this.usedPoint = this.usedPoint + element.points;
      });
    });
  }

  getNotification() {
    this.Api.get(`/user/notifications/1`).subscribe((result: any) => {
      if (result.data.notifications) {
        this.notifications = result.data.notifications;
        this.count = result.data.total;
      }
    });
  }
  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.currentUser.data = res.data;
        this.points = res.data.points;
        this.membership = this.currentUser.data.membership.type;
      }
    });
  }

  getUserTier() {
    this.Api.get(`/tier-check`).subscribe((res: any) => {
      if (res.data) {
      }
    });
  }

  getPointFromat(total) {
    
    if (total < 100) {
      return total.toFixed(0);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    
  }

  clearNotification() {
    this.Api.delete(`/user/notifications/all`).subscribe((res: any) => {
      this.notifier.notify("success", "Your notifications has been cleared");
      this.getNotification();
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }
}
