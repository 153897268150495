import { Injectable } from "@angular/core";
import { server } from "../../../environments/environment";
import { User } from "../../models";
import { ApiService } from "./../../services/server/api.service";

declare const FB: any;
@Injectable({ providedIn: "root" })
export class UserService {
  public baseUrl = server.baseUrl;
  constructor(private Api: ApiService) { }
  public uploadProfilePhoto(image: File) {
    const formData = new FormData();

    formData.append("image", image);

    return this.Api.post("/user/profile/photo/update", formData);
  }

  public uploadProfileBanner(image: File) {
    const formData = new FormData();

    formData.append("image", image);

    return this.Api.post("/user/profile/banner/update", formData);
  }

  getById(id: number) {
    return this.Api.get(`/users/` + id);
  }

  register(user: User) {
    return this.Api.post(`/auth/register`, user);
  }

  forget(user: User) {
    return this.Api.post(`/auth/forgot-password`, user);
  }

  resetPassword(user: User) {
    return this.Api.post(`/auth/reset-password`, user);
  }

  getProfile() {
    return this.Api.get(`/user/profile`);
  }

  profileUpdate(user: User) {
    return this.Api.put(`/user/profile/update-without-photo`, user);
  }

  profileUpdateOnCheckout(user: User) {
    return this.Api.put(`/user/profile/checkout-update`, user);
  }

  passwordUpdate(user: User) {
    return this.Api.put(`/user/password/update`, user);
  }

  store(user: User) {
    return this.Api.post(`/user/store`, user);
  }

  update(id: string, user: User) {
    return this.Api.put("/user/update/" + id, user);
  }

  membersipUpgarde(id: string, user: User) {
    return this.Api.put("/user/membership/upgrade/" + id, user);
  }


  delete(id: string) {
    return this.Api.delete(`/users/delete/` + id);
  }

  sendMail(mail: any) {
    return this.Api.post("/send/mail", mail);
  }

  sendFeedback(feedback: any) {
    return this.Api.post("/feedback/create", feedback);
  }
}
