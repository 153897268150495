import { Injectable } from "@angular/core";
import { ApiService } from "../../server/api.service";

@Injectable({
  providedIn: 'root'
})
export class ReferralCouponService {

  constructor(private Api: ApiService) { }

  getCoupons() {
    return this.Api.get(`/coupons`);
  }

  store(coupon: any) {
    return this.Api.post("/coupon/create", coupon);
  }

  update(id: any, coupon: any) {
    return this.Api.put("/coupon/" + id, coupon);
  }

  getById(id: string) {
    return this.Api.get(`/coupon/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/coupon/` + id);
  }
}
