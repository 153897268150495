import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
import { NotifierService } from "angular-notifier";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-family",
  templateUrl: "./family.component.html",
  styleUrls: ["./family.component.css"]
})
export class FamilyComponent implements OnInit {
  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 50;

  public membershipName = "";
  public expireDate = "";
  public membershipType = "";
  public familyMemberType = "child";

  public keyWord: any = "";
  public users: any;
  loading = true;

  photoUrl: string = storage.S3 + "/user/profile/";
  constructor(
    private Api: ApiService,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getUserProfile();
    this.getFriends();
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.membershipName = res.data.membership.name;
        this.expireDate = res.data.membership.expireDate;
        this.membershipType = res.data.membership.type;
        this.familyMemberType = res.data.familyMemberType;

      }
    });
  }
  getFriends() {
    this.Api.get("/family/members", {
      keyword: this.keyWord,
      perPage: this.perPage,
      page: this.currentPage
    }).subscribe((result: any) => {
      if (result.data) {
        this.loading = false;
        this.users = result.data.members;
        this.total = result.data.members.length;
        this.totalPages = result.data.pages;
        this.pages = Array.from(Array(result.data.pages).keys());
        this.currentPage = result.data.page;
      }
    });
  }

  nextPage(page) {
    this.currentPage = page + 1;
    this.getFriends();
  }

  cancelMembership(type) {
    if (this.familyMemberType == "child" || this.familyMemberType == "adult") {
      this.notifier.notify("success", "You don't have right to do");
      return;
    }
    this.Api.put("/user/membership/cancel", type).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Member has been removed successful");
        this.loading = false;
        this.router.navigate(["/user/membership"]);
      }
    });
  }

  selectMembership(plan) {
    if (this.familyMemberType == "child" || this.familyMemberType == "adult") {
      this.notifier.notify("success", "You don't have right to do");
      return;
    }
    this.Api.post("/user/membership/update", { membership: plan }).subscribe(
      (result: any) => {
        if (result) {
          this.notifier.notify("success", "Member has been removed successful");
          this.loading = false;
          this.ngOnInit();
        }
      }
    );
  }

  removeMember(id) {
    this.Api.put("/family/member/remove/" + id, id).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Member has been removed successful");
        this.loading = false;
      }
    });
  }
}
