import { Component, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-event',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsPageComponent implements OnInit {
  AllEvents:any = [];
  Stringmax: any = [];
  constructor() { 
    this.AllEvents = [
      {
        id:1,
        date:'Sunday 10 October, 2021',
        title:'JOIN THE ROAD CYCLING GRAN FONDO EVENT SET IN ONE OF AUSTRALIA’S MOST BEAUTIFUL BEACHSIDE LOCATIONS!',
        desc:'The Noosa Classic will surely be a bike ride to remember, but also a classic Australian cycling experience with a sensual feast of visual and gourmet delights. So gather your cycling mates, get yourselves ready, and mark Sunday, 10 October 2021 down on your calendar.',
        link:'https://cyclingclassics.com.au/noosa/',
        logoURL:'https://cyclingclassics.com.au/noosa/wp-content/uploads/sites/4/2020/03/Noosa-Classic-2020-PMS-logo_est-reversed-e1564530343766.png'
      },
      {
        id:2,
        date:'Sunday 17th October, 2021',
        title:'DOWNER BRISBANE TO GOLD COAST CYCLE CHALLENGE',
        desc:`Join thousands of riders as they pedal 100km from Brisbane to the Gold Coast. This year you can also choose to ride 40km from Brisbane to Logan or 60km from Logan to the Gold Coast.

        The 100km ride will begin on the Green Bridge (Eleanor Schonell) linking Dutton Park and the University of Queensland in St Lucia before embarking on an uninterrupted traffic-free start along the South East Busway for 15kms taking you all the way out of Brisbane. After exiting the Busway the course then follows a combination of service and suburban roads all the way to the Broadwater Parklands at Southport on the Gold Coast with a couple of rest stops in between.
        
        The 60km Logan to Gold Coast ride begins in Logan, which is also the site of the first rest stop for the 100km riders. The 60km riders will immediately join the 100km riders & also finish at Broadwater Parklands.
        
        The 40km Brisbane to Logan ride begins with the 100km riders, following the same course to the rest stop in Logan.
        `,
        link:'https://bq.org.au/event-site/b2gc/',
        logoURL:'https://bq.org.au/wp-content/uploads/2020/07/B2GC-Logo-MASTER-1.png'
      },
      {
        id:3,
        date:'Sunday 7th November,  2021',
        title:'Tour of Margaret River',
        desc:`A TOUR LIKE NO OTHER
        As the Australian Team Championships, the Tour of Margaret River is the ultimate experience for your cycling team. The unique format of the Tour means that amateur cyclists can compete directly against riders from the pro tour peloton. Designed especially for teams, TOMR is a highly tactical race where competing teams accumulate time in a way specific to each stage. Simply put, there is no other race on the world cycling calendar quite like TOMR.
        
        With over 100 teams participating annually, TOMR is one of the largest competitive road cycling events in Australia. Teams are seeded into divisions via the stage 1 Team Time Trial, and teams compete for trophies and awards within their division of 60 racers. We are proud to have one of the largest fields of women racing in the country. With open and all-female classifications, and a participation category, we have options to suit all.
        `,
        link:'http://tourofmargaretriver.com/',
        logoURL:''
      },
      {
        id:4,
        date:'Saturday 20th November, 2021',
        title:'IT’S BEEN A TOUGH YEAR FOR ALL OF US - CAN YOU GO FOR BROKE?',
        desc:`We all started 2021 thinking Covid-19 was getting under control and our lives would return to some form of normal. How wrong we were. This year Lifeline have been receiving the most calls they've ever had. Like 2020, GFB 2021 wanted to do what we could for our riders and Lifeline.

        With double vaccinations rates hitting 70% & 80% over the next 4 to 5 weeks in NSW, anyone double vaccinated will have many restrictions eased including outdoor ticketed events of 500 people. As such Go for Broke are looking forward to GFB21 being the first cycling event to take place on Saturday 20th of November.
        Start training for something. Join us and celebrate life getting back to a new normal as we all learn to live with Covid-19.       
        
        All funds raised for Go for Broke help Lifeline Harbour to Hawkesbury provide critical 24 hour crisis support. If you or someone you know is feeling distressed ring Lifeline on 13 11 14.
        
        Go for Broke would like to thank all our event partners who have supported us & Lifeline Harbour to Hawkesbury.
        
        HWL Ebsworth Lawyers, Harvey Norman, Pacific Sleep Services, City Tattersalls Club, Loop Brands and Turramurra Cyclery.
        `,
        link:'http://goforbroke.org.au/',
        logoURL:'http://goforbroke.org.au/wp-content/uploads/2018/02/logo.png'
      },
      
    ]
  }
  ngOnInit() {
    this.AllEvents.forEach(item => {
      this.Stringmax.push(200);
    }); 
    
  }

  readMore(i, item){
    console.log(this.Stringmax[i]);
    this.Stringmax[i] = item.desc.length;
  }
}                                                             