import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services';


@Component({
	selector: 'app-routes',
	templateUrl: './routes.component.html',
	styleUrls: [ './routes.component.css' ]
})
export class RoutesComponent implements OnInit {
	userRoutes: any;
	nearByRoutes: any;
	constructor(private Api: ApiService) {}

	ngOnInit() {
		this.getUserRoutes();
		this.getNearByRoutes();
	}

	timeConvert(num) {
	var h = Math.floor(num / 3600);
    var m = Math.floor((num % 3600) / 60);
    var s = Math.floor((num % 3600) % 60);

    var hh = "" + h;
    var mm = "" + m;
    var ss = "" + s;

    if (h < 10) {
      hh = "0" + h;
    }
    if (m < 10) {
      mm = "0" + m;
    }
    if (s < 10) {
      ss = "0" + s;
    }

    return hh + ":" + mm + ":" + ss;
	}

	getUserRoutes() {
		this.Api.get(`/user/routes/all`).subscribe((res: any) => {
			if (res.data) {
				this.userRoutes = res.data;
			}
		});
	}

	getNearByRoutes() {
		this.Api.get(`/user/routes/all`).subscribe((res: any) => {
			if (res.data) {
				this.nearByRoutes = res.data;
			}
		});
	}
}
