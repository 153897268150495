import { Injectable } from '@angular/core';
import { User } from '../../models/user/user.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import { server } from '../../../environments/environment';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  authStatus = this.loggedIn.asObservable();
  public UserSub = new BehaviorSubject<boolean>(this.Token.loggedIn());
  AuthUser = this.loggedIn.asObservable();

  public baseUrl = server.apiUrl;

  constructor(
    private Token: TokenService,
    private http: HttpClient,
    private router: Router,
  //  private permissionsService: NgxPermissionsService
  ) { }

  public logOut() {
    this.http.post(`${this.baseUrl}/auth/logout`, '');
    this.Token.remove();

    return this.router.navigateByUrl('/login');
  }
}
