import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../services';
import { storage } from '../../../../../../../environments/environment';
import { NotifierService } from "angular-notifier";
@Component({
  selector: "app-search-friend",
  templateUrl: "./search-friend.component.html",
  styleUrls: ["./search-friend.component.css"]
})
export class SearchFriendComponent implements OnInit {
  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 50;
  public keyWord: any = "";
  public users: any;
  public userFriends: any;
  public inviteEmail = null;
  loading = true;
  myFriendLoading = true;

  photoUrl: string = storage.S3 + "/user/profile/";
  constructor(private Api: ApiService, private notifier: NotifierService) { }

  ngOnInit() {
    this.getFriends();
    this.getUserFriends();
  }

  getUserFriends() {
    this.myFriendLoading = true;
    this.Api.get("/user/friends").subscribe((result: any) => {
      if (result.data) {
        this.userFriends = result.data.users;
      }
      this.myFriendLoading = false;
    });
  }

  getFriends() {
    this.loading = true;
    this.Api.get(`/friend/users`, {
      keyword: this.keyWord,
      perPage: this.perPage,
      page: this.currentPage
    }).subscribe((result: any) => {
      if (result.data) {
        this.users = result.data.users;
        this.total = result.data.users.length;
        this.totalPages = result.data.pages;
        this.pages = Array.from(Array(result.data.pages).keys());
        this.currentPage = result.data.page;
      }
      this.loading = false;
    });
  }

  nextPage(page) {
    this.currentPage = page + 1;
    this.getFriends();
  }

  searchFriends() {
    this.currentPage = 1;
    this.getFriends();
  }

  sendFriendRequest(recipient) {
    this.Api.post(`/user/friend/request`, {
      recipient: recipient
    }).subscribe((result: any) => {
      this.getFriends();
    });
  }

  sendInvitation() {
    if (this.inviteEmail) {
      this.Api.post("/friend/mail/invitation", {
        inviteEmail: this.inviteEmail
      }).subscribe(
        res => {
          this.notifier.notify("success", "Inviation Email Sent");
        },
        err => {
          this.notifier.notify("warning", err);
        }
      );
    }
  }
}
