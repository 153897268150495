import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private Api: ApiService) { }

  createOrder(info: any) {
    return this.Api.post(`/shop/order/create`, info);
  }

  getOrders(perPage:any, page: any) {
    return this.Api.get(`/shop/orders?perPage=`+ perPage + `&page=` + page);
  }

  getOrderByID(id: any) {
    return this.Api.get(`/shop/order/` + id);
  }

  updateOrder(id: any, order:any) {
    return this.Api.put(`/shop/order/` + id, order);
  }

  deleteOrder(id: any) {
    return this.Api.delete('/shop/order/' + id);
  }

  orderStatuUpdate(id: any, order: any) {
    return this.Api.put(`/shop/order/` + id + '/status', order)
  }

  vendorWiseStatusUpdate(orderId : any, vendorId : any, status : any) {
    return this.Api.put('/shop/order/'+orderId+'/vendor/'+vendorId+'/status', status)
  }

  getVendorOrders(id: any, perPage:any, page: any, status:any=null, startDate:any = null, endDate:any = null) {
    let statusQ = '';
    if(status != '' && status != null) {
      statusQ = '&status=' + status;  
    }
    
    return this.Api.get('/shop/vendor/user/'+id+'/orders?perPage='+ perPage + '&page=' + page + '&startDate=' + startDate + '&endDate=' + endDate + statusQ);
  }

  getVendorOrder(id: any, orderId: any) {
    return this.Api.get('/shop/vendor/user/'+id+'/order/' + orderId);
  }

  vendorOrderUpdate(id: any, orderId: any, data: any) {
    return this.Api.put('/shop/vendor/user/'+id+'/order/' + orderId, data);
  }

  addNote(id: any, note: any) {
    return this.Api.put('/shop/order/' +id + '/notes', note);
  }

  getUserOrders(user: any, perPage, currentPage) {
    return this.Api.get("/shop/user/" + user + "/orders?perPage="+ perPage + '&page=' + currentPage);
  }

  getDashboardItems() {
    return this.Api.get(`/shop/admin/dashboard`);
  }

  searchOrderByDate(start: any, end:any, perPage: any, page: any) {
    return this.Api.get("/shop/orders/filter?startDate="+start+"&endDate="+end+"&perPage="+perPage+"&page="+page);
  }

  searchOrderBySupplierCustomer(supplier: any, customer:any, perPage: any, page: any) {
    return this.Api.get("/shop/orders/filter?supplier="+supplier+"&customer="+customer+"&perPage="+perPage+"&page="+page);
  }

  vendorWiseOrder(order:any, vendor: any) {
    return this.Api.get("/shop/order/"+order+"/vendor/"+vendor);
  }

  searchOrderByCustomerNameOrderNumber(filter: any, keyword: any, perPage: any, page: any) {
    return this.Api.get("/shop/orders/filter?"+filter+"="+keyword+"&perPage="+perPage+"&page="+page);
  }

  searchOrderByVendorPayment(paymentStatus:any, perPage: any, page: any) {
    return this.Api.get("/shop/orders/filter?vendorPaymentStatus="+paymentStatus+"&perPage="+perPage+"&page="+page);
  }
  
  exportToCSV(data:any = null) {
    let keysearch = '';
    if(data.filter) {
      keysearch ='&'+data.filter+'='+data.key;
    }
    return this.Api.get("/shop/orders/export?startDate="+data.start+'&endDate='+data.end+keysearch);
  }
}
