import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
import { AuthenticationService, SocketService } from "../../../../../services";
import { Subscription } from "rxjs";
import { User } from "../../../../../models";
@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"]
})
export class EventsComponent implements OnInit {
  state = null;
  states: any = [];
  country: any;
  countries = [];
  photoUrl: string = storage.S3 + "/events/covers/";
  userEvents: any;
  bsInlineRangeValue = null;
  showMore = false;
  loading = true;
  dateCustomClasses = [];
  /////
  currentUserSubscription: Subscription;
  currentUser: User;
  socket: any;
  constructor(
    private Api: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.socket = SocketService.getInstance();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    );

    this.socket.on("connection", function (data) {
      this.socket.emit("setUserId", this.currentUser._id);
    });

    this.socket.on("event", result => {
      this.getUserEvents();
    });
  }

  ngOnInit() {
    this.getUserEvents();
    this.getStates();
    this.getCountries();
  }

  getCharityLogo(logo) {
    return storage.S3 + "/charities/logo/" + logo;
  }
  getUserEvents() {
    this.loading = true;
    this.Api.get("/web/events/user").subscribe((res: any) => {
      if (res.data) {
        this.userEvents = res.data.events;
        this.setUpData();
      }
      this.loading = false;
    });
  }

  setUpData() {
    this.userEvents.forEach(ev => {
      this.dateCustomClasses.push({
        date: new Date(ev["date"]),
        classes: ["bg-success-light"]
      });
      ev.event_status = "Open";

      if (ev.isStarted == true) {
        ev.event_status = "in progress";
      }

      // if (new Date(ev["startDate"]) < new Date()) {

      if (ev.isClosed == true) {
        ev.event_status = "Closed";
      }



    });
  }

  deleteEvent(id) {
    if (confirm("Are you sure to delete ")) {
      this.Api.delete("/web/user/event/delete/" + id).subscribe(
        (result: any) => {
          this.getUserEvents();
        }
      );
    }
  }
  filterEvents(ev = null) {
    this.loading = true;
    this.userEvents = [];
    this.Api.get("/web/events/user", {
      keyword: ev ? ev.target.value : "",
      state: this.state ? this.state : "",
      date: this.bsInlineRangeValue ? this.bsInlineRangeValue : ""
    }).subscribe((res: any) => {
      if (res.data) {
        this.userEvents = res.data.events;
        this.setUpData();
      }
      this.loading = false;
    });
  }
  formatDate(date, org = null) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year =
        d.getFullYear() > 2019 ? d.getFullYear() : new Date().getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return org ? [day, month, year].join("/") : [year, month, day].join("-");
  }
  changeDate(e) {
    if (e) {
      this.bsInlineRangeValue =
        e.getFullYear() + "-" + (1 + e.getMonth()) + "-" + e.getDate();
    }
    this.filterEvents({ target: { value: "" } });
  }
  getStates() {
    this.Api.get("/states").subscribe(res => {
      this.states = res.data;
    });
  }

  getCountries() {
    this.Api.get("/countries").subscribe(res => {
      this.countries = res.data;
    });
  }
}
