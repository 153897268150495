import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-app-widget',
  templateUrl: './download-app-widget.component.html',
  styleUrls: ['./download-app-widget.component.css']
})
export class DownloadAppWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
