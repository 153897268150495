import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../../services";
import { ActivatedRoute } from "@angular/router";
import { storage } from "../../../../../../../environments/environment";

@Component({
  selector: "app-view-goal",
  templateUrl: "./view-goal.component.html",
  styleUrls: ["./view-goal.component.css"]
})
export class ViewGoalComponent implements OnInit {
  id = null;
  goal: any;
  photoUrl: string = storage.S3 + "/goals/";
  photo = 'assets/img/pages-banner/event-view.png';
  constructor(
    private activatedRoute: ActivatedRoute,
    private Api: ApiService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });
    this.getGoal(this.id);
  }

  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + "h:" + minutes + "m";
  }
  getGoal(id) {
    this.Api.get("/goal/" + id).subscribe((res: any) => {
      if (res.data) {
        this.goal = res.data;
        this.photo = this.photoUrl + res.data.photo
      }
    });
  }

  getP(distanceCompleted, distance) {
    return ((distanceCompleted / distance) * 100).toFixed(0);
  }
}
