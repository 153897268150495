import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";


@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  constructor(private Api: ApiService) { }

  getReferral() {
    return this.Api.get(`/referrals`);
  }

  store(club: any) {
    return this.Api.post("/referral/create", club);
  }

  update(id: any, club: any) {
    return this.Api.put(`/referral/` + id, club);
  }

  getById(id: string) {
    return this.Api.get(`/referral/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/referral/delete/` + id);
  }

}
