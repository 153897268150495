import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services';
import { storage } from '../../../../../../environments/environment';
@Component({
	selector: 'app-find-friend-widget',
	templateUrl: './find-friend-widget.component.html',
	styleUrls: ['./find-friend-widget.component.css']
})
export class FindFriendWidgetComponent implements OnInit {
	isFocused = false;
	Loaded = false;
	loading = true;

	public friends: any;
	photoUrl: string = storage.S3 + '/user/profile/';
	constructor(private Api: ApiService) { }

	ngOnInit() {
		this.getFriends();

	}

	getFriends() {
		this.Api.get(`/user/friends`).subscribe((result: any) => {
			if (result.data.users.length > 0) {
				this.friends = result.data.users;
			}
			this.loading = false;
		});
	}
}
