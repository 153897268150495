import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../../../services";

@Component({
  selector: "app-user-position-info",
  templateUrl: "./user-position-info.component.html",
  styleUrls: ["./user-position-info.component.css"]
})
export class UserPositionInfoComponent implements OnInit {
  weekDistance: number = 0;
  monthDistance: number = 0;
  yearDistance: number = 0;
  constructor(public Api: ApiService) {}

  ngOnInit() {
  //  this.getUserStats();
      this.getUserWeekStats();
      this.getUserMonthStats();  
      this.getUserYearStats(); 
  }

  getUserStats() {
    this.Api.get(`/user/stats`).subscribe((res: any) => {
      if (res.data) {
        this.weekDistance = res.data[0].totalDistance;
        this.monthDistance = res.data[0].totalDistance;
        this.yearDistance = res.data[0].totalDistance;
      }
    });
  }

  getUserWeekStats() {
    this.Api.get(`/user/stats/week`).subscribe((res: any) => {
      if (res.data) {
        this.weekDistance = res.data.totalDistance;
      }
    });
  }
  getUserMonthStats() {
    this.Api.get(`/user/stats/month`).subscribe((res: any) => {
      if (res.data) {
       this.monthDistance = res.data.totalDistance;
    }
    });
  }
  getUserYearStats() {
    this.Api.get(`/user/stats/year`).subscribe((res: any) => {
      if (res.data) {
        this.yearDistance = res.data.totalDistance;
      }
    });
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }
}
