import { Injectable } from '@angular/core';
import { ApiService } from "../../server/api.service";

@Injectable({
  providedIn: "root"
})
export class CountryService {
  constructor(private Api: ApiService) { }

  getCountries() {
    return this.Api.get(`/countries`);
  }

  store(country: any) {
    return this.Api.post("/country/create", country);
  }

  update(id: any, country: any) {
    return this.Api.put('/country/' + id, country);
  }

  getById(id: string) {
    return this.Api.get(`/country/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/country/` + id);
  }
}