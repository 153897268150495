import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services';
import { ActivatedRoute } from '@angular/router';
import { storage } from '../../../../../../environments/environment';
@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.css"]
})
export class ActivityComponent implements OnInit {
  activity: any;
  photoUrl: string = storage.S3 + "/log/";
  constructor(
    private activatedRoute: ActivatedRoute,
    private Api: ApiService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.getRide(params.id);
    });
  }
  timeConvert(num) {
 var h = Math.floor(num / 3600);
 var m = Math.floor((num % 3600) / 60);
 var s = Math.floor((num % 3600) % 60);

 var hh = "" + h;
 var mm = "" + m;
 var ss = "" + s;

 if (h < 10) {
   hh = "0" + h;
 }
 if (m < 10) {
   mm = "0" + m;
 }
 if (s < 10) {
   ss = "0" + s;
 }

 return hh + ":" + mm + ":" + ss;
  }
  getRide(id) {
    this.Api.get(`/ride?id=` + id).subscribe((res: any) => {
      if (res.data) {
        this.activity = res.data;
      }
    });
  }
}
