import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
interface MailChimpResponse {
  result: string;
  msg: string;
}
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  subscribeForm: FormGroup;
  loading = false;
  submitted = false;
  error = "";
  mailChimpEndpoint =
    "https://gocycle.us20.list-manage.com/subscribe/post?u=3887b5597f2551937487e5389&id=1874998a71&";
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private notifier: NotifierService
  ) { }
  ngOnInit() {
    this.subscribeForm = this.formBuilder.group({
      emailSub: ["", [Validators.required, Validators.email]],
      nameSub: ["", [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.subscribeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.subscribeForm.invalid) {
      return;
    }
    this.loading = true;
    const params = new HttpParams()
      .set("MERGE1", this.subscribeForm.value.nameSub)
      .set("MERGE0", this.subscribeForm.value.emailSub)
      .set(
        "ht",
        "6d264d0f6f54ecb66b4e467971838cb9bc5c4256:MTU3OTI2MjI3My40NjY3"
      )
      .set("c", "ng_jsonp_callback_0"); // hidden input name

    const mailChimpUrl = this.mailChimpEndpoint + params.toString();

    // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
    this.http.jsonp<MailChimpResponse>(mailChimpUrl, "c").subscribe(
      response => {
        if (response.result && response.result !== "error") {
          this.notifier.notify("success", "Email has been send successful");
          this.loading = false;
          this.subscribeForm.reset();
          this.submitted = false;
        } else {
          this.error = response.msg;
        }
      },
      error => {
        console.error(error);
        this.error = "Sorry, an error occurred.";
        this.notifier.notify("error", this.error);
        this.loading = false;
      }
    );
  }
}
