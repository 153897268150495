import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { storage } from "../../../../environments/environment";
@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.css"]
})
export class UserDashboardComponent implements OnInit {
  loading = true;
  currentUser: any;
  points: number = 0.00;
  distance: number = 0.00;
  tierDistance: number = 0.00;
  distanceComplete: any = 0.00;
  tierPhoto: string = "assets/img/default/tier-1-100.png";
  isCollapsed = false;
  showChart = false;
  dataLabel = [];
  dataTotal = [];
  barChartData = [];

  constructor(public Api: ApiService) {}

  ngOnInit() {
    this.getChart();
    this.getUserProfile();
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.currentUser = res.data;
        this.points = res.data.points;
        this.distance = res.data.distance
          .toFixed(0);
        if (res.data.tier){
            this.tierDistance = res.data.tier.distance.toFixed(0);
            this.tierPhoto = storage.S3 + "/tiers/" + res.data.tier.photo;
        }
        this.distanceComplete = 100 - (this.distance / this.tierDistance) * 100;
        this.distanceComplete = this.distanceComplete
          .toFixed(0);
      }
    });
  }

  getPointFromat(total) {
    if (total < 100) {
      return total.toFixed(0);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  getChart() {
    this.Api.get(`/user/rides/chart`).subscribe((result: any) => {
      if (result.data) {
        let days = Object.keys(result.data);
        let valArray = [];
        this.barChartLabels = [];
        days.forEach(d => {
          if(new Date(d).getMonth() >= new Date().getMonth()) {
          let nd = new Date(d).getDate();
          this.barChartLabels.push(nd.toString() );
          valArray.push(result.data[d].toFixed(2) );
          }
        });
        this.barChartData = [{ data: valArray, label: 'Distance (KM)', backgroundColor: '#69b144', hoverBackgroundColor: '#808080'}];
        this.loading   = false;
        this.showChart = true;
      }
    });
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
      },
      gridLines: { display: false } }],
      yAxes: [{}]
    }
  };
  public barChartLabels: Label[];
  public barChartType: ChartType = "bar";
  public barChartLegend = false;
  public myColors = [
    {
      backgroundColor: 'rgba(0, 250, 0, .1)',
      borderColor: 'rgb(0, 250, 0)',
      pointBackgroundColor: 'rgb(0, 250, 0)',
      pointBorderColor: '#00ff00',
      pointHoverBackgroundColor: '#00ff00',
      pointHoverBorderColor: 'rgba(0, 250, 0, .8)'
    }
  ];
}
