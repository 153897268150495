import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../../services";
import { storage } from "../../../../../../../environments/environment";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-groups-join",
  templateUrl: "./groups-join.component.html",
  styleUrls: ["./groups-join.component.css"]
})
export class GroupsJoinComponent implements OnInit {
  public groups: any;
  public keyWord: any = "";
  photoUrl: string = storage.S3 + "/groups/covers/";
  constructor(private Api: ApiService, private notifier: NotifierService) { }

  ngOnInit() {
    this.getGroups();
  }

  getGroups() {
    this.Api.get("/user/groups/all").subscribe((result: any) => {
      if (result.data) {
        this.groups = result.data;
      }
    });
  }

  searchGroups() {
    this.getGroups();
  }

  deleteGroup(id) {
    if (confirm("Are you sure to delete ")) {
      this.Api.delete(`/group/delete/` + id).subscribe((result: any) => {
        this.notifier.notify("success", "Group has been deleted successfully");
        this.getGroups();
      });
    }
  }
}

