import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../../../../../services';
import { storage } from '../../../../../../../environments/environment';
import { NotifierService } from 'angular-notifier';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.css"]
})
export class GroupComponent implements OnInit {
  team: any;
  friends: any;
  inviteFriends = [];
  inviteMail = null;
  selectedFriend: any;
  id: string;
  loading = false;
  returnUrl: string;
  photoUrl: string = storage.S3 + "/groups/covers/";
  userPhotoUrl: string = storage.S3 + "/user/profile/";
  userChallenges: any;
  newChallenges: any;
  teams: any;
  userTeams: any;
  invitForm: FormGroup;
  public keyWord: any = "";
  constructor(
    private Api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private formBuilder: FormBuilder
  ) {
    this.getTeam();
  }

  ngOnInit() {
    this.getFriends();
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams["returnUrl"] ||
      "/user/teams/search";

    this.invitForm = this.formBuilder.group({
      email: ["", [Validators.required]]
    });
  }

  getTeam() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.Api.get("/team/show/" + this.id).subscribe((result: any) => {
        if (result) {
          this.team = result.data;
        }
      });
    });
  }
  getFriends() {
    this.Api.get(`/user/friends`).subscribe((result: any) => {
      if (result.data.length > 0) {
        this.friends = result.data;
      }
    });
  }

  friendSelection($event) {
    this.selectedFriend = $event;
  }

  joinTeam() {
    this.Api.post("/team/join", { team: this.id }).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Team has been joined successful");
        this.loading = false;
        this.getTeam();
      }
    });
  }

  deleteTeam() {
    this.Api.delete("/team/delete/" + this.id).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Team has been deleted successful");
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  leaveTeam() {
    this.Api.post("/team/leave", { team: this.id }).subscribe((result: any) => {
      if (result) {
        this.notifier.notify("success", "Team has been leaved successful");
        this.loading = false;
        this.getTeam();
      }
    });
  }

  removeTeamMember(memberTeam) {
    this.Api.post("/team/member/remove", {
      team: this.id,
      member: memberTeam
    }).subscribe((result: any) => {
      if (result) {
        this.notifier.notify(
          "success",
          "Team member has been removed successful"
        );
        this.loading = false;
        this.getTeam();
      }
    });
  }

  sendInvitation() {
    if (this.inviteFriends.length <= 0) {
      this.notifier.notify("warning", "Please select your friend");
      return false;
    }
    if (this.inviteFriends.length > 0) {
      this.Api.post("/team/invitation/multiply", {
        friends: this.inviteFriends,
        team: this.id
      }).subscribe(
        res => {
          this.notifier.notify("success", "Invitation has been sent");
          this.inviteFriends = [];
        },
        err => {
          this.notifier.notify("warning", err);
        }
      );
    }
  }

  sendMailInvitation() {
    if (!this.inviteMail) {
      this.notifier.notify("warning", " Please enter the email address");
      return;
    }
    if (this.inviteMail) {
      this.Api.post("/team/mail/invitation", {
        inviteEmail: this.inviteMail,
        team: this.id
      }).subscribe(
        res => {
          this.notifier.notify("success", "Invitation has been sent");
          this.inviteMail = null;
        },
        err => {
          this.notifier.notify("warning", err);
        }
      );
    }
  }
}
