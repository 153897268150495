import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from 'ngx-bootstrap';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';


// Admin Dashboard
import { AdminHeaderComponent } from './admin-dashboard/common/admin-header/admin-header.component';
// User Dashboard
import { UserDashboardHeaderComponent } from './user-dashboard/common/user-dashboard-header/user-dashboard-header.component';
import { AdminSidebarComponent } from './admin-dashboard/common/admin-sidebar/admin-sidebar.component';
import { AdminFooterComponent } from './admin-dashboard/common/admin-footer/admin-footer.component';

@NgModule({
    declarations: [
        UserDashboardHeaderComponent,
        AdminHeaderComponent,
        AdminSidebarComponent,
        AdminFooterComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        BsDropdownModule.forRoot(),
        MalihuScrollbarModule.forRoot(),
    ],
    exports: [
        UserDashboardHeaderComponent,
        AdminHeaderComponent,
        AdminSidebarComponent,
        AdminFooterComponent
    ]
})
export class UserSharedModule { }
