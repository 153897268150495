import { Injectable } from '@angular/core';
import { mapbox } from '../../../../environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Injectable({
	providedIn: 'root'
})
export class MopboxService {
	constructor() {
		mapboxgl.accessToken = mapbox.accessToken;
	}
}
