import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { LocationService } from '../../../../../services';
import { mapbox } from "../../../../../../environments/environment";

@Component({
  selector: "map-box",
  templateUrl: "./map-box.component.html",
  styleUrls: ["./map-box.component.css"]
})
export class MapBoxComponent implements OnInit {
  map: mapboxgl.Map;
  style = "mapbox://styles/mapbox/streets-v11";
  lat = -28.0042749;
  lng = 153.3928554;

  constructor(private locationService: LocationService) {}

  ngOnInit() {
    this.getMap();
  }

  getMap() {
    // mapboxgl.accessToken =
    //   "pk.eyJ1Ijoic2FsbGFodWRkaW4iLCJhIjoiY2swM2RmeGZoMXRzdTNicHI2bGY3eWFqYSJ9.t_UnTTHOIK5ATin6NVpZnA";
    mapboxgl.accessToken = mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.style,
      zoom: 3,
      center: [this.lng, this.lat]
    });
    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on("load", function() {
   alert('hello');
    });
  }
}
