import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AuthenticationService } from '../../../../../../services';

@Component({
  selector: "app-leader-board-event",
  templateUrl: "./leader-board-event.component.html",
  styleUrls: ["./leader-board-event.component.css"]
})
export class LeaderBoardEventComponent implements OnInit {
  position = 0 ;
  userId = "";
  users: any = [];
  mycompleted = 0;
  distanceCompleted = 0;
  constructor(
    private activatedRoute: ActivatedRoute,
    private Api: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(user => {
      if (user) {
        this.userId = user.data._id;
      }
    });
    this.activatedRoute.params.subscribe(params => {
      this.Api.get("/event/leaderboard/" + params["id"]).subscribe(res => {
        this.users = res.data.users.filter(u => u.user !== null);
        this.position =
          this.users.findIndex(obj => obj.userId === this.userId) + 1;
      });
    });
  }
}
