import { Injectable } from '@angular/core';
import { ApiService } from '../../services/server/api.service';

@Injectable({
  providedIn: "root"
})
export class ChallengeService {
  constructor(private Api: ApiService) {}

  getChallenges() {
    return this.Api.get(`/Challenges`);
  }

  createChallenge(challenge: any) {
    return this.Api.post("/web/Challenge/create", challenge);
  }

  store(challenge: any) {
    return this.Api.post("/web/Challenge/create", challenge);
  }

  update(id: any, challenge: any) {
    return this.Api.put("/web/challenge/" + id, challenge);
  }

  getById(id: string) {
    return this.Api.get(`/challenge/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/challenge/` + id);
  }
}
