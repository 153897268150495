// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false
};

export const server = {

  //Live
   socket: "http://3.105.235.23:8000", // Live IP
   baseIP: "https://api.gocycle.com.au", // Live IP
   apiUrl: "https://api.gocycle.com.au", // Live API
   api: "api",
   version: "v1",
   baseUrl: "https://api.gocycle.com.au/api/v1" // Live API Link

   // Live IP
   // baseIP: "http://3.105.235.23", // Live IP
   // baseUrl: "http://3.105.235.23/api/v1" // Live API Link
   // api: "api",
   // version: "v1",
   // apiUrl: "http://3.105.235.23", // Live API

  //Beta
   // socket: "http://3.106.58.21:8000", //  Beta IP
   // baseIP: "http://3.106.58.21", //  Beta IP
   // apiUrl: "http://3.106.58.21", //  Beta IP
   // api: "api",
   // version: "v1",
   // baseUrl: 'http://3.106.58.21/api/v1' // Beta API Link
   
  //Staging
   // socket: "http://3.106.58.21:8000", //  Beta IP
   // baseIP: "http://3.106.58.21", //  Beta IP
   // apiUrl: "http://3.106.58.21", //  Beta IP
   // api: "api",
   // version: "v1",
   // baseUrl: 'http://3.106.58.21/api/v1' // Beta API Link


  //Development
  //  socket: "http://3.106.58.21:8000", //  Dev IP
  //  baseIP: "http://3.106.58.21", //  Dev IP
  //  apiUrl: "http://3.106.58.21", //  Dev IP
  //  api: "api",
  //  version: "v1",
  //  baseUrl: "http://3.106.58.21/api/v1", // Dev API Link
  
  // Localhost
   // socket: "http://localhost:8000", // Localhost Link
   // baseIP: "http://localhost", // Localhost Link
   // apiUrl: "http://localhost:3000", // Localhost Link
   // api: "api",
   // version: "v1",
   // baseUrl: "http://localhost:3000/api/v1" // Localhost Link
};

export const storage = {
  S3: "https://gocycle.s3-ap-southeast-2.amazonaws.com"
  // S3: "https://gocycle-dev.s3.ap-southeast-2.amazonaws.com"
};

export const mapbox = {
  accessToken:
    "pk.eyJ1Ijoic2FsbGFodWRkaW4iLCJhIjoiY2swM2RmeGZoMXRzdTNicHI2bGY3eWFqYSJ9.t_UnTTHOIK5ATin6NVpZnA"
};

export const facebook = {
  // apiKey: "AIzaSyBMEJUjND8_hxqIkoVFMJmfabEa8D8iuzk"
};

export const google = {
  apiKey: "AIzaSyBMEJUjND8_hxqIkoVFMJmfabEa8D8iuzk"
};

export const stripe = {
  //publicKey: "pk_test_51Hk4iVI3JxdAjE8eqw9TaUenWubViBK73tvsTj9d1ebpUBLTR0nCqXSDEVulPdZo018lUhJYBLKvH41j6z2xt76w000vkz7Pbv", // dev public key
  // secretKey: 'sk_test_51Hk4iVI3JxdAjE8ecxQzdfVMBquzYnyIjoxsZhpUvk0ViTmFw77vDs5HUox5Pag1Fu1po74Acatb5HeqNded7jKt00L0iWpvn5' // dev secret Key

  // publicKey: "pk_live_51Hk4iVI3JxdAjE8eZY3CMHlhJxPS49zA1N2zvrZFbJkaxJTwmS7A3xrmLFtkvaLRL4InecusiiptsB9XXabhiTKQ00RqRU1e2K", // live public key
  // secretKey: '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'// live secret Key
};

export const paypal = {
  // publicKey: "pk_test_51Hk4iVI3JxdAjE8eqw9TaUenWubViBK73tvsTj9d1ebpUBLTR0nCqXSDEVulPdZo018lUhJYBLKvH41j6z2xt76w000vkz7Pbv", // dev public key
  //secretKey: 'sk_test_51Hk4iVI3JxdAjE8ecxQzdfVMBquzYnyIjoxsZhpUvk0ViTmFw77vDs5HUox5Pag1Fu1po74Acatb5HeqNded7jKt00L0iWpvn5' // dev secret Key

  // publicKey: "pk_live_51Hk4iVI3JxdAjE8eZY3CMHlhJxPS49zA1N2zvrZFbJkaxJTwmS7A3xrmLFtkvaLRL4InecusiiptsB9XXabhiTKQ00RqRU1e2K", // live public key
  // secretKey: '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'// live secret Key
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
