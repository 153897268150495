import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import {
  UserService,
  AuthenticationService,
  ApiService
} from "../../../../../services";
import { User } from "../../../../../models";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-contact-support",
  templateUrl: "./contact-support.component.html",
  styleUrls: ["./contact-support.component.css"]
})
export class ContactSupportComponent implements OnInit {
  supportForm: FormGroup;
  loading = false;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notifier: NotifierService,
    private Api: ApiService
  ) {}
  ngOnInit() {
    this.getUserProfile();
    this.supportForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      fullName: ["", [Validators.required]],
      type: ["", [Validators.required]],
      comments: ["", [Validators.required]],
      medium: ["web", [Validators.required]]
    });
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.supportForm.get("email").setValue(res.data.email);
        this.supportForm.get("fullName").setValue(res.data.profile.fullName);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.supportForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.supportForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .sendFeedback(this.supportForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Your Support request has been submitted successful"
            );
            this.loading = false;
            this.supportForm.reset();
            this.submitted = false;
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
