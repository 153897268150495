import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../../services/server/api.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-join-event',
  templateUrl: './join-event.component.html',
  styleUrls: ['./join-event.component.css']
})
export class JoinEventComponent implements OnInit {
  editID = null;
  eventData: any;

  constructor(
		private notifier: NotifierService,
    private Api: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.Api.get('/event?id=' + params['id']).subscribe(res => {
        this.eventData = res.data;
      });
    });
  }

  joinNow() {
    this.Api.post('/event/join', {eventId: this.eventData._id}).subscribe(res => {
      this.notifier.notify('success', 'You have Join this event');
    });
  }
}
