import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FamilyService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-edit-family",
  templateUrl: "./edit-family.component.html",
  styleUrls: ["./edit-family.component.css"]
})
export class EditFamilyComponent implements OnInit {
  familyForm: FormGroup;
  family: any = [];
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private familyService: FamilyService,
    private notifier: NotifierService
  ) { }

  ngOnInit() {
    this.getFamily();
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/account";

    this.familyForm = this.formBuilder.group({
      name: ["", [Validators.required]]
    });
  }

  getFamily() {
    this.familyService.getFamily().subscribe((result: any) => {
      if (result) {
        this.family = result.data;
        this.familyForm.get("name").setValue(this.family.name);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.familyForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.familyForm.invalid) {
      return;
    }

    this.loading = true;

    this.familyService
      .update(this.familyForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Family has been updated successful"
            );
            this.loading = false;
            this.submitted = false;
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
