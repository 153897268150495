import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";
@Injectable({
  providedIn: "root"
})
export class GroupService {
  constructor(private Api: ApiService) {}

  getgroups() {
    return this.Api.get(`/groups`);
  }

  store(group: any) {
    return this.Api.post("/web/group/create", group);
  }

  update(id: any, group: any) {
    return this.Api.put(`/group/update/` + id, group);
  }

  getById(id: string) {
    return this.Api.get(`/group/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/group/delete/` + id);
  }
}
