import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  constructor(private Api: ApiService) {}

  getProducts(perPage: any, page: any) {
    return this.Api.get(`/shop/products/shop?perPage=`+ perPage + `&page=` + page);
  }

  getProductsFrontend(perPage: any, page: any) {
    return this.Api.get(`/shop/front/products?perPage=`+ perPage + `&page=` + page);
  }

  getFeaturedProduct() {
    return this.Api.get('/shop/products/featured');
  }

  getLatestProduct() {
    return this.Api.get('/shop/products?filter=latest');
  }

  getProduct(perPage: any, page: any) {
    return this.Api.get(`/shop/products?perPage=`+ perPage + `&page=` + page);
  }

  store(product: any) {
    return this.Api.post("/shop/product/create", product);
  }

  update(id: any, product: any) {
    return this.Api.put(`/shop/product/` + id, product);
  }

  getBySlug(slug: string) {
    return this.Api.get(`/shop/product/slug/` + slug);
  }

  delete(id: string) {
    return this.Api.delete(`/product/delete/` + id);
  }

  getSearchProduct(key: any) {
    return this.Api.get('/shop/products/front/search?title=' + key)
  }

  getSearchProductWithPagination(key: any, perPage: any, currentPage) {
    return this.Api.get('/shop/products/front/search?title='+key+'&perPage='+ perPage + '&page=' + currentPage)
  }

  getAllProducts() {
    return this.Api.get(`/shop/products`);
  }

  getProductById(id: any) {
    return this.Api.get(`/shop/product/` + id);
  }

  getSearchProductByPoint(minPoint, maxPoint, perPage, currentPage) {
    return this.Api.get('/shop/products/filter?pointsFrom='+minPoint+'&pointsTo='+maxPoint+'&perPage='+ perPage + '&page=' + currentPage);
  }

  featureProduct(id:any, status: any) {
    return this.Api.put(`/shop/product/`+id +`/featured`, status)
  }

  updateStatus(id:any, status: any) {
    return this.Api.put(`/shop/product/`+id +`/status`, status)
  }

  getSearchProductBySupplierCategory(supplier:any, category: any, perPage, currentPage) {
    return this.Api.get('/shop/products/filter?vendor='+supplier+'&category='+category+'&perPage='+ perPage + '&page=' + currentPage);
  }

  getSearchProductByInactiveOutOfStock(inactive:any, outOfStock: any, perPage, currentPage) {
    return this.Api.get('/shop/products/filter?inactive='+inactive+'&outOfStock='+outOfStock+'&perPage='+ perPage + '&page=' + currentPage);
  }

}
