import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";

@Component({
  selector: "app-user-ride-states",
  templateUrl: "./user-ride-states.component.html",
  styleUrls: ["./user-ride-states.component.css"]
})
export class UserRideStatesComponent implements OnInit {
  isFocused = false;
  Loaded = false;
  loading = true;

  public state: any;
  public distanceCompleted = 0;
  public distanceCompletedYear = 0;
  public distanceCompletedMonth = 0;
  public averageSpeed = 0;
  public completedTime: string = "00:00:00";
  public duration = '0hrs 0mins'
  public rides = 0;
  constructor(private Api: ApiService) { }

  ngOnInit() {
    // this.getUserStats();
    this.getStates();
    this.getUserMonthStats();
    this.getUserYearStats();
  }

  getUserStats() {
    this.Api.get(`/user/stats`).subscribe((res: any) => {
      if (res.data) {
        if (res.data[0]) {
          this.rides = res.data[0].count;
          if (res.data[0].totalDistance < 100) {
            this.distanceCompleted = res.data[0].totalDistance.toFixed(2);
          } else if (res.data[0].totalDistance < 1000) {
            this.distanceCompleted = res.data[0].totalDistance.toFixed(0);
          } else {
            this.distanceCompleted = res.data[0].totalDistance
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          }
        }
        this.loading = false;
      }
    });
  }

  timeConvert(num) {
    var h = Math.floor(num / 3600);
    var m = Math.floor((num % 3600) / 60);
    var s = Math.floor((num % 3600) % 60);

    var hh = "" + h;
    var mm = "" + m;
    var ss = "" + s;

    if (h < 10) {
      hh = "0" + h;
    }
    if (m < 10) {
      mm = "0" + m;
    }
    if (s < 10) {
      ss = "0" + s;
    }

    return hh + "hrs " + mm + "mins";// + ss;
  }

  avergeSpeed(speed, total) {
    if (total === 0) {
      total++;
    }
    var avgSpeed = speed / total;

    return Number(avgSpeed.toFixed(2));
  }

  getStates() {
    let averageSpeed = 0;
    let count = 0;
    let completedTime = 0
    this.distanceCompleted = 0;
    this.Api.get(`/user/rides/states`).subscribe((res: any) => {
      if (res.data) {
        this.loading = false;
        if (res.data[0]) {
          if (res.data[0].distanceCompleted < 100) {
            this.distanceCompleted = res.data[0].distanceCompleted.toFixed(2);
          } else if (res.data[0].distanceCompleted < 1000) {
            this.distanceCompleted = res.data[0].distanceCompleted.toFixed(0);
          } else {
            this.distanceCompleted = res.data[0].distanceCompleted
              .toFixed(0)
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          }
          averageSpeed = res.data[0].averageSpeed
          completedTime = res.data[0].completedTime;
          count = res.data[0].count
        }
        this.averageSpeed = this.avergeSpeed(
          averageSpeed,
          count
        );
        this.duration = this.timeConvert(completedTime);
        this.rides = count;
      }
    });
  }

  getUserMonthStats() {
    this.Api.get(`/user/stats/month`).subscribe((res: any) => {
      if (res.data) {
        this.loading = false;
        this.distanceCompletedMonth = res.data.totalDistance;
      }
    });
  }
  getUserYearStats() {
    this.Api.get(`/user/stats/year`).subscribe((res: any) => {
      if (res.data) {
        this.loading = false;
        this.distanceCompletedYear = res.data.totalDistance;
      }
    });
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

}
