import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services';
import { storage } from '../../../../../../environments/environment';

@Component({
  selector: "app-groups-widget",
  templateUrl: "./groups-widget.component.html",
  styleUrls: ["./groups-widget.component.css"]
})
export class GroupsWidgetComponent implements OnInit {
  isFocused = false;
  Loaded = false;
  loading = true;

  public teams: any;
  membership = 'lite';
  photoUrl: string = storage.S3 + "/groups/covers/";
  constructor(private Api: ApiService) { }
  ngOnInit() {
    this.getGroups();
    this.getUserProfile();
  }

  getGroups() {
    this.Api.get("/user/teams/all").subscribe((result: any) => {
      if (result.data.teams.length > 0) {
        this.teams = result.data.teams;
      }
      this.loading = false;
    });
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.membership = res.data.membership.type;
      }
    });
  }

}
