import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TeamService } from '../../../../../../services';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: "app-create-group",
  templateUrl: "./create-group.component.html",
  styleUrls: ["./create-group.component.css"]
})
export class CreateGroupComponent implements OnInit {
  teamForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/teams/search";

    this.teamForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", []],
      photo: ["", []],
      membersLimit: ["", []],
      privacy: ["", []]
    });
  }

  handleInputChange(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
     this.teamForm.patchValue({
       photo: reader.result
     });
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.teamForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.teamForm.invalid) {
      return;
    }

    this.loading = true;

    this.teamService
      .store(this.teamForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Team has been created successful"
            );
            this.loading = false;
            this.submitted = false;
            this.teamForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}