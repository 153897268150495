import { Component, OnInit } from '@angular/core';
import { SeoService, RouteService, ApiService } from 'src/app/services'
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-activity-route',
  templateUrl: './activity-route.component.html',
  styleUrls: ['./activity-route.component.css']
})
export class ActivityRouteComponent implements OnInit {

  id: any;
  record: any;
  photo = "https://www.gocycle.com.au/assets/img/default/img_banner_about.png";

  // http://localhost:4200/assets/img/default/img_banner_about.png
  constructor(
    private seoService: SeoService,
    private Api: ApiService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
    this.getRoute();

  }

  getRoute() {
    this.Api.get("/public/route?id=" + this.id).subscribe((result: any) => {
      if (result.data) {
        this.record = result.data;
        if (result.data.photo) {
          this.photo = result.data.photo;
          this.seoService.updateTag('og:image', this.photo);
        }
      }
    });
  }

  // Home Header slider
  slideConfig = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 15000,
    speed: 2000
  };

  // Partners Slider
  slideConfig2 = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
}
