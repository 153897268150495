import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";
import { ApiService } from "../../../../../../services/server/api.service";
import { storage } from "../../../../../../../environments/environment";
@Component({
  selector: "app-challenges-create",
  templateUrl: "./challenges-create.component.html",
  styleUrls: ["./challenges-create.component.css"]
})
export class ChallengesCreateComponent implements OnInit {
  challengeForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  routes: any;
  selectedRoute: any;
  photoUrl: string = storage.S3 + "/challages/";
  userPhoto = "assets/img/default/empty-event-cover.png";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private challengeService: ChallengeService,
    private notifier: NotifierService,
    private Api: ApiService
  ) {}

  ngOnInit() {
    this.getAllRoutes();
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/challenges";
    this.challengeForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      photo: ["", []],
      description: ["", []],
      challengeType: ["", []],
      distance: [, []],
      completedTime: [, []],
      startDate: ["", []],
      endDate: ["", []],
      membersLimit: [null, []],
      routeId: [null, []],
      inviteEmail: [null],
      socialLink: [null],
      keywords: [[]]
    });
  }

  getAllRoutes() {
    this.Api.get("/user/routes/all").subscribe(res => {
      this.routes = res.data.routes;
    });
  }
  handleInputChange(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
      this.challengeForm.controls["photo"].setValue(reader.result);
    };
  }

  validityChanger(e) {
    if (this.challengeForm.value.challengeType === "route") {
      this.challengeForm.get("routeId").setValidators([Validators.required]);
      this.challengeForm.get("routeId").updateValueAndValidity();
      this.challengeForm.get("distance").clearValidators();
      this.challengeForm.get("distance").updateValueAndValidity();
    } else if (this.challengeForm.value.challengeType === "distance") {
      this.challengeForm.get("distance").setValidators([Validators.required]);
      this.challengeForm.get("distance").updateValueAndValidity();
      this.challengeForm.get("routeId").clearValidators();
      this.challengeForm.get("routeId").updateValueAndValidity();
    }
  }

  routeSelection($event) {
    this.selectedRoute = $event;
    this.challengeForm.controls["distance"].setValue(
      this.selectedRoute.distance
    );
    this.challengeForm.controls["completedTime"].setValue(
      this.selectedRoute.duration
    );
  }

  showDuration(d) {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = m % 60;
    return h + " Hr " + m + " Min " + s + " Sec";
  }

  get f() {
    return this.challengeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.challengeForm.invalid) {
      return;
    }

    this.loading = true;

    this.challengeService
      .createChallenge(this.challengeForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Challenge has been created successful"
            );
            this.loading = false;
            this.submitted = false;
            this.challengeForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
