import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class FamilyService {
  constructor(private Api: ApiService) {}

  getFamilies() {
    return this.Api.get(`/families`);
  }

  getFamily() {
    return this.Api.get(`/family`);
  }

  update(family: any) {
    return this.Api.put(`/family/update/`, family);
  }
}
