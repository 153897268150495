import { Injectable } from '@angular/core';
import { ApiService } from './../../server/api.service';

@Injectable({
	providedIn: 'root'
})
export class RoleService {
	constructor(private Api: ApiService) {}

	getRoles() {
		return this.Api.get(`/roles`);
	}

	store(role: any) {
		return this.Api.post('/role/create', role);
	}

	update(id:any, role: any) {
		return this.Api.put(`/role/update/`+id, role);
	}

	getById(id: string) {
		return this.Api.get(`/role/` + id);
	}

	delete(id: string) {
		return this.Api.delete(`/role/delete/` + id);
	}
}
