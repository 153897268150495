import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventService } from "../../../../../../services";
import { ApiService } from "../../../../../../services/server/api.service";
import { NotifierService } from "angular-notifier";
import { storage } from "../../../../../../../environments/environment";

@Component({
  selector: "app-edit-event",
  templateUrl: "./edit-event.component.html",
  styleUrls: ["./edit-event.component.css"]
})
export class EditEventComponent implements OnInit {
  stagesShow = true;
  eventForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  routes: any;
  selectedRoute: any;
  socialBTN = false;
  cities: any;
  country: any;
  countries = null;
  eventPhoto: any = "";
  participantTypes = [
    { id: "individual", name: "Individual" },
    { id: "teams", name: "Team" }
  ];
  keywords = [
    { _id: 1, name: "Sallah Please" },
    { _id: 2, name: "Bring This" },
    { _id: 3, name: "From Backend" }
  ];
  initStage = {
    image: null,
    image2: null,
    name: null,
    description: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    locationName: null,
    endLocationName: null,
    challengeType: null,
    distance: null,
    routeId: null
  };
  userList = [];
  newStage = {
    image: null,
    image2: null,
    name: null,
    description: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    locationName: null,
    endLocationName: null,
    challengeType: null,
    distance: null,
    routeId: null
  };
  stages = [];

  editID = null;
  stageEditorID = null;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private notifier: NotifierService,
    private Api: ApiService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.editID = params["id"];
      this.getEventEdit();
    });
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] ||
      "/user/events/view/" + this.editID;
    this.getAllRoutes();
    this.getUsers();
    this.getCities();
    this.getCountries();

    this.eventForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      participantType: ["individual", [Validators.required]],
      description: ["", []],
      type: [null, [Validators.required]],
      photo: [null, []],
      startLocationName: [null, []],
      startLocationLatitude: [null, []],
      startLocationLongitude: [null, []],
      endLocationName: [null, []],
      endLocationLatitude: [null, []],
      endLocationLongitude: [null, []],
      date: [null, [Validators.required]],
      time: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      //  membersLimit: [null, []],
      routeId: [null, [Validators.required]],
      challengeType: ["", [Validators.required]],
      distance: [0, [Validators.required]],
      stages: [[]],
      socialLinkFB: [null],
      socialLinkInsta: [null],
      socialLinkWEB: [null],
      inviteEmail: [null],
      state: [null],
      country: [null],
      inviteEmailUser: [null],
      keywords: [[]],
      UpdatedBy: ["user"]
    });
  }

  deleteEventImage() {
    this.eventForm.controls["photo"].setValue("null");
    this.eventPhoto = "";
  }

  getImageUrl(image) {
    return storage.S3 + "/events/covers/" + image;
  }

  getCities() {
    this.Api.get("/states").subscribe(res => {
      this.cities = res.data;
    });
  }

  getCountries() {
    this.Api.get("/countries").subscribe(res => {
      this.countries = res.data;
    });
  }
  getEventEdit() {
    this.Api.get("/event?id=" + this.editID).subscribe(res => {
      Object.keys(this.eventForm.controls).forEach(pro => {
        this.eventForm.controls[pro].setValue(res.data[pro]);
      });
      this.eventForm.controls["type"].setValue(
        res.data["type"] ? res.data["type"] : res.data["privacy"]
      );
      this.eventForm.controls["participantType"].setValue(
        res.data["participantType"]
      );
      this.eventForm.controls["challengeType"].setValue(
        res.data["challengeType"]
      );
      this.validityChanger("ok");
      this.eventForm.controls["state"].setValue(
        res.data.state ? res.data.state : null
      );
      this.eventForm.controls["country"].setValue(res.data.country);
      this.eventForm.controls["startLocationName"].setValue(
        res.data["startLocation"]["name"]
      );
      this.eventForm.controls["startLocationLatitude"].setValue(
        res.data["startLocation"]["coordinates"]["latitude"]
      );
      this.eventForm.controls["startLocationLongitude"].setValue(
        res.data["startLocation"]["coordinates"]["longitude"]
      );

      this.eventForm.controls["endLocationName"].setValue(
        res.data["endLocation"]["name"]
      );
      this.eventForm.controls["endLocationLatitude"].setValue(
        res.data["startLocation"]["coordinates"]["latitude"]
      );
      this.eventForm.controls["endLocationLongitude"].setValue(
        res.data["startLocation"]["coordinates"]["longitude"]
      );
      res.data["startDate"] = new Date(res.data["startDate"]);
      let evTime =
        (res.data["startDate"].getHours() < 10 ? "0" : "") +
        res.data["startDate"].getHours() +
        ":" +
        (res.data["startDate"].getMinutes() < 10 ? "0" : "") +
        res.data["startDate"].getMinutes();
      this.eventForm.controls["date"].setValue(res.data["startDate"]);
      this.eventForm.controls["time"].setValue(evTime);
      if (res.data.photo && !res.data.photo.includes("64,")) {
        this.eventPhoto = storage.S3 + "/events/covers/" + res.data.photo;
        this.eventForm.controls["photo"].setValue("");
      }
      res.data.stages.forEach(stage => {
        let obj: any = {};
        Object.keys(this.initStage).forEach(k => {
          obj[k] = stage[k];
        });
        let endDate = new Date(stage["endDate"]);
        let startDate = new Date(stage["startDate"]);
        obj["routeId"] = null;
        obj["challengeType"] = stage["challengeType"];
        obj["locationName"] = stage["location"];
        obj["endLocationName"] = stage["endLocation"];
        obj["endDate"] = endDate;
        obj["endTime"] =
          (endDate.getHours() < 10 ? "0" : "") +
          endDate.getHours() +
          ":" +
          (endDate.getMinutes() < 10 ? "0" : "") +
          endDate.getMinutes();
        obj["startDate"] = startDate;
        obj["startTime"] =
          (startDate.getHours() < 10 ? "0" : "") +
          startDate.getHours() +
          ":" +
          (startDate.getMinutes() < 10 ? "0" : "") +
          startDate.getMinutes();
        obj["image2"] = storage.S3 + "/events/covers/" + stage["image"];
        this.stages.push(obj);
      });
    });
  }
  getUsers() {
    this.Api.get("/user/friends", { perPage: 1000 }).subscribe(res => {
      this.userList = res.data.users;
    });
  }
  getAllRoutes() {
    this.Api.get("/user/routes/all", { perPage: 1000 }).subscribe(res => {
      this.routes = res.data.routes;
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.eventForm.controls;
  }

  validityChanger(e) {
    if (this.eventForm.value.challengeType === "route") {
      this.eventForm.get("routeId").setValidators([Validators.required]);
      this.eventForm.get("routeId").updateValueAndValidity();
      this.eventForm.get("distance").clearValidators();
      this.eventForm.get("distance").updateValueAndValidity();
      // this.eventForm.get("startLocationName").clearValidators();
      // this.eventForm.get("startLocationName").updateValueAndValidity();
      // this.eventForm.get("endLocationName").clearValidators();
      // this.eventForm.get("endLocationName").updateValueAndValidity();
    } else if (this.eventForm.value.challengeType === "distance") {
      this.eventForm.get("distance").setValidators([Validators.required]);
      this.eventForm.get("distance").updateValueAndValidity();
      // this.eventForm.get("startLocationName").setValidators([Validators.required]);
      // this.eventForm.get("startLocationName").updateValueAndValidity();
      // this.eventForm
      //       .get("endLocationName")
      //       .setValidators([Validators.required]);
      // this.eventForm.get("endLocationName").updateValueAndValidity();
      this.eventForm.get("routeId").clearValidators();
      this.eventForm.get("routeId").updateValueAndValidity();
    }
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.eventForm.invalid) {
      return;
    }
    let dateTime = new Date(this.eventForm.controls["date"].value);
    let date =
      dateTime.getDate() +
      "/" +
      (dateTime.getMonth() + 1) +
      "/" +
      dateTime.getFullYear();
    let time = this.eventForm.controls["time"].value;
    time = time.split(":");
    let dateA: any = date.split("/");
    let dateT = new Date(
      dateA[2],
      dateA[1] - 1,
      dateA[0],
      time[0],
      time[1],
      0,
      0
    );
    this.eventForm.get("date").setValue(dateT);

    let endDateTime = new Date(this.eventForm.controls["endDate"].value);
    let endDate =
      endDateTime.getDate() +
      "/" +
      (endDateTime.getMonth() + 1) +
      "/" +
      endDateTime.getFullYear();
    let endTime = this.eventForm.controls["endTime"].value;
    endTime = endTime.split(":");
    let endDateA: any = endDate.split("/");
    let endDateT = new Date(
      endDateA[2],
      endDateA[1] - 1,
      endDateA[0],
      endTime[0],
      endTime[1],
      0,
      0
    );
    this.eventForm.get("endDate").setValue(endDateT);

    this.loading = true;
    this.eventForm.controls["stages"].setValue(this.stages);
    this.eventService
      .updateEvent(this.eventForm.value, this.editID)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Event has been updated successful"
            );
            this.loading = false;
            this.submitted = false;
            this.eventForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
  routeSelection($event) {
    this.selectedRoute = $event;
    this.eventForm.controls["distance"].setValue(this.selectedRoute.distance);

    this.eventForm.controls["startLocationName"].setValue(
      this.selectedRoute.startLocation.name
    );
    this.eventForm.controls["startLocationLatitude"].setValue(
      this.selectedRoute.startLocation.coordinates.latitude
    );
    this.eventForm.controls["startLocationLongitude"].setValue(
      this.selectedRoute.startLocation.coordinates.longitude
    );

    this.eventForm.controls["endLocationName"].setValue(
      this.selectedRoute.endLocation.name
    );
    this.eventForm.controls["endLocationLatitude"].setValue(
      this.selectedRoute.endLocation.coordinates.latitude
    );
    this.eventForm.controls["endLocationLongitude"].setValue(
      this.selectedRoute.endLocation.coordinates.longitude
    );
  }
  showOff() { }
  handleInputChange(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
      this.eventForm.controls["photo"].setValue(reader.result);
      this.eventPhoto = reader.result;
    };
  }
  handleInputChangeStage(e) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event: Event) => {
      this.newStage.image = reader.result;
      this.newStage.image2 = reader.result;
    };
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year =
        d.getFullYear() > 2019 ? d.getFullYear() : new Date().getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  showDuration(d) {
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = m % 60;
    return h + " Hr " + m + " Min " + s + " Sec";
  }

  pushToSaveStage() {
    if (this.stageEditorID !== null) {
      this.stages[this.stageEditorID] = this.newStage;
      // this.stageEditorID = null;
    } else {
      this.stages.push(this.newStage);
      this.stageEditorID = this.stages.length - 1;
    }
  }

  pushToStageList() {
    if (this.stageEditorID !== null) {
      this.stages[this.stageEditorID] = this.newStage;
      this.stageEditorID = null;
    } else {
      this.stages.push(this.newStage);
    }
    this.newStage = {
      image: null,
      image2: null,
      name: null,
      description: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      locationName: null,
      endLocationName: null,
      challengeType: null,
      distance: null,
      routeId: null
    };
  }
}
