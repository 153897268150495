import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../../services/server/api.service';
import { AuthenticationService } from '../../../../../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from '../../../../../../models';
import { storage } from '../../../../../../../environments/environment';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css']
})
export class ViewEventComponent implements OnInit {
  loading = true;
  inviteEmailUser = [];
  inviteEmail = null;
  iconPhotoUrl: string = storage.S3 + '/user/profile/';
  reserveForm: FormGroup;
  options: any;
  eventData: any = [];
  members: any = [];
  friends: any = [];
  currentUserSubscription: Subscription;
  currentUser: User;
  photoUrl: string = '';
  photoDIR = storage.S3 + '/events/covers/';
  slideConfig = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 15000,
    speed: 2000
  };

  membership = 'lite';

  constructor(
    private formBuilder: FormBuilder,
    private Api: ApiService, private notifier: NotifierService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.getFriends();
    this.getUserProfile();

    this.activatedRoute.params.subscribe(params => {
      this.Api.get('/event?id=' + params['id']).subscribe(res => {
        this.eventData = res.data;
        this.members = res.data.members;
        if (this.eventData.photo !== '') {
          this.loading = false;
          this.photoUrl = storage.S3 + '/events/covers/' + this.eventData.photo;
        } else {
          this.loading = false;
          this.photoUrl = '/assets/img/pages-banner/single-event.png';
        }
        this.eventData['startDate'] = new Date(this.eventData['startDate']);

        this.eventData.stages.forEach(stage => {
          stage['startDate'] = new Date(stage['startDate']);
          stage['endDate'] = new Date(stage['endDate']);
        });
        this.options = { fb: this.eventData.socialLinkFB, insta: this.eventData.socialLinkInsta, web: this.eventData.socialLinkWEB };
      });
    });

    this.reserveForm = this.formBuilder.group({
      type: [null, [Validators.required]],
      isAgree: [null, [Validators.required]],
      AllowEmail: [0],
    });
  }
  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.membership = res.data.membership.type;
      }
    });
  }
  getFriends() {
    this.Api.get("/user/friends", { perPage: 1000 }).subscribe(res => {
      if (res.data.users) {
        this.friends = res.data.users;
      }
    });
  }
  getTime(t) {
    var today = new Date(t);
    var h = today.getHours();
    var m = today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes();
    var s = today.getSeconds();
    var ampm = h > 12 ? 'PM' : 'AM';
    h = h % 12;
    let shh = h > 9 ? h : '0' + h;
    return shh + ":" + m + ' ' + ampm;
  }


  getDate(t) {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date(t);
    var dd = String(today.getDate()).padStart(2, '0');
    var yyyy = today.getFullYear();
    return dd + " " + monthNames[today.getMonth()] + " " + yyyy;
  }

  reserveThis() {
    this.Api.post('/event/join', { eventId: this.eventData._id }).subscribe(res => {
      this.ngOnInit();
    });
  }
  alreadyMember() {
    if (this.eventData && this.currentUser) {
      const list = this.members.filter(m => m._id === this.currentUser.data._id);
      if (list && list.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  sendInvitation() {
    if (this.inviteEmailUser.length > 0) {
      this.Api.post('/event/invitation/multiply', { friends: this.inviteEmailUser, event: this.eventData._id }).subscribe(res => {
        this.notifier.notify("success", "Invitation has been sent");
      }, err => {
        this.notifier.notify('warning', err);
      });
    } else if (this.inviteEmail) {
      this.Api.post('/event/mail/invitation', { inviteEmail: this.inviteEmail, event: this.eventData._id }).subscribe(res => {
        this.notifier.notify(
          "success",
          "Invitation has been sent"
        );
      }, err => {
        this.notifier.notify('warning', err);
      });
    }
  }
}
