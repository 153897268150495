import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";
@Injectable({
  providedIn: "root"
})
export class PointService {
  constructor(private Api: ApiService) {}

  getPoints() {
    return this.Api.get(`/points`);
  }

  store(point: any) {
    return this.Api.post("/point/create", point);
  }

  update(id: any, point: any) {
    return this.Api.put("/point/" + id, point);
  }

  getById(id: string) {
    return this.Api.get(`/point/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/point/` + id);
  }
}