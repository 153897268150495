import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
declare const FB: any;
import { CartService } from '../../../services/cart/cart.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private authenticationService: AuthenticationService,
		private notifier: NotifierService,
		private CartApi: CartService
	) {
		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate(['/user/dashboard']);
		}
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required]
		});

		// get return url from route parameters or default to '/'
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/user/dashboard';

		(window as any).fbAsyncInit = function () {
			FB.init({
				appId: '331323564452768',
				cookie: true,
				xfbml: true,
				version: 'v3.2'
			});
			FB.AppEvents.logPageView();
		};

		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/es_LA/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}

	fbLogin() { }

	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}
	logLoginEvent() {
		FB.AppEvents.logEvent('Web Login')
	}
	onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}
		this.loading = true;
		this.authenticationService.login(this.f.email.value, this.f.password.value).pipe(first()).subscribe(
			(data) => {
				this.logLoginEvent();
				if (data.role === 'cyclist') {
					this.router.navigate([this.returnUrl]);
				} else if (data.role === 'vendor') {
				this.router.navigate(['/supplier-dashboard']);
				} else {
					this.router.navigate(['/admin-dashboard']);
				}

				//update local cart items
				this.saveLocalItemsToDB(data.data._id);
			},
			(error) => {
				this.notifier.notify('error', error);
				this.loading = false;
			}
		);
	}

	//update local cart items to DB
	saveLocalItemsToDB(userId:any) {
		if ( localStorage.getItem('gcCartItems') ) {
			let cartItems = JSON.parse(localStorage.getItem('gcCartItems'));
			console.log('update cart', cartItems);
			this.CartApi.updateLocalCartItems(cartItems, userId).subscribe((result: any) => {
				if ( result ) {
					localStorage.removeItem('gcCartItems');
				}
			});

			// this.CartApi.getCartItems().subscribe((result: any) => {
			// 	console.log(result);
			// });
		}
	}
}