import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService, AlertService, AuthenticationService } from '../../../services';
import { NotifierService } from 'angular-notifier';
@Component({
	selector: 'app-password-recover',
	templateUrl: './password-recover.component.html',
	styleUrls: [ './password-recover.component.css' ]
})
export class PasswordRecoverComponent implements OnInit {
	forgetForm: FormGroup;
	loading = false;
	submitted = false;
	mailSend = false;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private notifier: NotifierService
	) {
		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate([ '/' ]);
		}
	}

	ngOnInit() {
		this.forgetForm = this.formBuilder.group({
			email: [ '', [ Validators.required, Validators.email ] ]
		});
	}

	// convenience getter for easy access to form fields
	get f() {
		return this.forgetForm.controls;
	}

	onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.forgetForm.invalid) {
			return;
		}

		this.loading = true;
		this.userService.forget(this.forgetForm.value).pipe(first()).subscribe(
			(data) => {
				this.notifier.notify('success', 'Email has been send successful');
				this.mailSend = true;
			},
			(error) => {
				this.notifier.notify('error', error);
				this.loading = false;
			}
		);
	}
}
