import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { server } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class SocketService {
  /**
   * constuctor with control handle, that you can not instantiate by new NodoSocket();
   * socket should act as a real singleton, not to have multiple socket connection instances
   * within the same application to the same resource
   */
  constructor() {
    if (!SocketService.isCreating) {
      throw new Error(
        "This is a real singleton. Get an instance via var socket = SocketService.getInsance(); !"
      );
    }

    //  console.info("creating socket object");

    //  console.info("establishing connection to server...");
    this.socket = io.connect(this.url,
      {
        // Send auth token on connection, you will need to DI the Auth service above
        // 'query': 'token=' + Auth.getToken()
        rejectUnauthorized: false,
        path: '/socket.io',
        transports: ['websocket', 'polling']
        //    secure: true,
      }
    );
  }

  static instance: SocketService = null;
  static isCreating: Boolean = false;
  public socket: any;
  private url = server.socket;

  /**
   * get instance wrapper
   * @returns {SocketService}
   */
  public static getInstance(): SocketService {
    if (SocketService.instance === null) {
      SocketService.isCreating = true;
      SocketService.instance = new SocketService();
      SocketService.isCreating = false;
    }

    /// console.log('SocketService.instance', SocketService.instance);
    return SocketService.instance;
  }

  /**
   * receive data form Socket-Server
   * @param eventName
   * @param callback
   */
  public on(eventName, callback): void {
    this.socket.on(eventName, function () {
      const args = arguments;
      if (typeof callback === "function") {
        callback.apply(this.socket, args);
      }
    });
  }

  /**
   * submit data to socket-server
   * @param eventName
   * @param data
   * @param callback
   */
  public emit(eventName, data, callback): void {
    this.socket.emit(eventName, data, function () {
      const args = arguments;
      if (typeof callback === "function") {
        callback.apply(this.socket, args);
      }
    });
  }
}

/**
 usage:
 in an angular2 component controller, you may use it that way:


 var mySocket = SocketService.getInstance();


 !!!IMPORTANT: the arguments you will get back in your callback depends on your socket server implementation

 mySocket.emit('api:model', {
                requestID: 'aSampleRequestID,
                model: 'aSampleCollectionName,
                action: 'update',
                id: 'aSampleID',
                doc: {firstname:'john', lastname:'doe'}
            }, function (err, result, data) {

                if(err){
                    // handle error here

                    return;
                }

                if(data){
                    // handle your data here
                }



            });


 or for listening on events

 !!!IMPORTANT: the arguments you will get back in your callback depends on your socket server implementation

 mySocket.on('api:model:created', function (collectionName, id, editor, requestID){
        // handle your stuff here
    });

 */
