import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
@Component({
  selector: "app-challenges",
  templateUrl: "./challenges.component.html",
  styleUrls: ["./challenges.component.css"]
})
export class ChallengesComponent implements OnInit {

  challengeLoading = true;
  myChallengeLoading = true;

  photoUrl: string = storage.S3 + "/challenges/";
  userChallenges: any;
  newChallenges: any;
  constructor(private Api: ApiService) { }

  ngOnInit() {
    this.getNewChallenges();
    this.getUserChallenges();
  }

  timeConvert(num) {
    var h = Math.floor(num / 3600);
    var m = Math.floor((num % 3600) / 60);
    var s = Math.floor((num % 3600) % 60);

    var hh = "" + h;
    var mm = "" + m;
    var ss = "" + s;

    if (h < 10) {
      hh = "0" + h;
    }
    if (m < 10) {
      mm = "0" + m;
    }
    if (s < 10) {
      ss = "0" + s;
    }

    return hh + ":" + mm + ":" + ss;
  }

  getUserChallenges() {
    this.myChallengeLoading = true;
    this.Api.get("/user/challenges/user").subscribe((res: any) => {
      if (res.data) {
        this.userChallenges = res.data.challenges;
      }
      this.myChallengeLoading = false;
    });
  }

  getNewChallenges() {
    this.challengeLoading = true;
    this.Api.get("/challenges/user").subscribe((res: any) => {
      if (res.data) {
        this.newChallenges = res.data.challenges;
      }
      this.challengeLoading = false;
    });
  }
}
