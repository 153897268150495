import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService, AlertService, AuthenticationService } from '../../../../../services';
import { User } from '../../../../../models';
import { storage } from '../../../../../../environments/environment';
import { NotifierService } from 'angular-notifier';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [ './profile.component.css' ]
})
export class ProfileComponent implements OnInit {
	isCollapsed = false;
	passType = 'password';
	cpassType = 'password';
	url: string | ArrayBuffer;
	UserForm: any;
	currentUser: User;
	currentUserSubscription: Subscription;
	photoUrl: string = storage.S3 + '/user/profile/';

	constructor(
		private router: Router,
		private userService: UserService,
		private notifier: NotifierService,
		private authenticationService: AuthenticationService
	) {
		this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
		});
	}

	ngOnInit() {}

	// Image Upload
	onSelectFile(event) {
		if (event.target.files && event.target.files.length) {
			const fileReader: FileReader = new FileReader();
			fileReader.readAsDataURL(event.target.files[0]);
			fileReader.onload = (event: Event) => {
				this.url = fileReader.result;
				this.UserForm.patchValue({
					photo: fileReader.result
				});
			};
		}
	}
}
