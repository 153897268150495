import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService, ApiService } from "../../../../../../services";
import { Router, ActivatedRoute } from "@angular/router";
import { storage } from "../../../../../../../environments/environment";
import { User } from "../../../../../../models";
@Component({
  selector: "app-friend",
  templateUrl: "./friend.component.html",
  styleUrls: ["./friend.component.css"]
})
export class FriendComponent implements OnInit {
  routeLoading = true;
  challengeLoading = true;

  id: string = "";
  currentUser: User;
  isFriend = false;
  requestByYou = false;
  isLiked = false;
  friendsStatus = "notFriend";
  friend: any;
  photoUrl: string = storage.S3 + "/user/profile/";
  challengePhotoUrl: string = storage.S3 + "/challenges/";
  newChallenges: any;
  userRoutes: any;
  tierPhoto: string;
  constructor(
    private Api: ApiService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });
    this.authenticationService.currentUser.subscribe(user => {
      if (this.id == user.data._id) {
        this.router.navigate(["/user/friends/search"]);
      }
    });
    this.getFriend();
    this.getUserChallenges();
    this.getUserRoutes();
  }

  timeConvert(num) {
    var h = Math.floor(num / 3600);
    var m = Math.floor((num % 3600) / 60);
    var s = Math.floor((num % 3600) % 60);

    var hh = "" + h;
    var mm = "" + m;
    var ss = "" + s;

    if (h < 10) {
      hh = "0" + h;
    }
    if (m < 10) {
      mm = "0" + m;
    }
    if (s < 10) {
      ss = "0" + s;
    }

    return hh + ":" + mm + ":" + ss;
  }

  sendFriendRequest(recipient) {
    this.Api.post(`/user/friend/request`, {
      recipient: recipient
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  canRequest(friendId) {
    this.Api.post(`/user/friend/request/cancel`, {
      friendId: friendId
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  rejectRequest(friendId) {
    this.Api.post(`/user/friend/request/cancel`, {
      friendId: friendId
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  accepted(requester) {
    this.Api.post(`/user/friend/accepted`, {
      requester: requester
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  unFriend(friendId) {
    this.Api.post(`/user/unfriend`, {
      friendId: friendId
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  liked(userId) {
    this.Api.post(`/user/profile/Liked`, {
      user: userId
    }).subscribe((result: any) => {
      this.getFriend();
    });
  }

  getFriend() {
    this.Api.get("/friend/" + this.id).subscribe((result: any) => {
      if (result) {
        this.friend = result.data[0];
        this.isFriend = result.data[0].isFriend;
        this.friendsStatus = result.data[0].friendsStatus;
        this.requestByYou = result.data[0].requestByYou;
        this.isLiked = result.data[0].isLiked;
      }
    });
  }

  getUserChallenges() {
    this.challengeLoading = true;
    this.Api.get("/challenges/user/" + this.id).subscribe((res: any) => {
      if (res.data) {
        this.newChallenges = res.data;
      }
      this.challengeLoading = false;
    });
  }

  getUserRoutes() {
    this.routeLoading = true;
    this.Api.get(`/user/routes/` + this.id).subscribe((res: any) => {
      if (res.data) {
        this.userRoutes = res.data;
      }
      this.routeLoading = false;
    });
  }
}
