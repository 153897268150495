import { forEach } from '@angular-devkit/schematics';
import { Component, OnInit } from '@angular/core';
import { OrderService } from "../../../../../services/order/order.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  UserService,
  AuthenticationService,
  ApiService
} from "../../../../../services";
import { Location } from "@angular/common";
import { RedeemPointService } from "../../../../../services/redeem-point/redeem-point.service";


@Component({
  selector: 'app-user-order-view',
  templateUrl: './user-order-view.component.html',
  styleUrls: ['./user-order-view.component.css']
})
export class UserOrderViewComponent implements OnInit {

  public pages: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 10;
  page: number;
  order: any;
  user: any;
  total: any;
  id: any;
  pointPerDolar: any;

  loading = false;

  constructor(
    private userService: UserService,
    private OrderApi: OrderService,
    private Api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location, 
    private RedeemApi: RedeemPointService
  ) {}
  ngOnInit() {
    this.getUserProfile();
    this.getPoints();
  }

  getUserProfile() {

    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
       this.user = res.data;
      } else {
        console.log('user profile called: no data found')
      }
    });

    this.route.params.subscribe(params => {
      this.id = params.id;
      this.getOrder(this.id);
    });
  }

  getOrder(id) {
    this.OrderApi.getOrderByID(id).subscribe((result:any) => {
      if (result.data) {
        this.order = result.data;
        console.log("Order ", this.order);
        this.calculateTotal();
      }
    })
  }

  calculateTotal() {
    for (let item of this.order.products) {
      this.total = this.total + item.price;
    }
  }

  getPoints() {
    this.RedeemApi.getPoint().subscribe((result:any) => {
      if (result) {
        this.pointPerDolar = result.data.points;
      }
    })
  }
  getVendorDetails(vendors:any) {
    let vendorList = '';
    if (vendors) {
      vendors.forEach( (item, index) => {
        vendorList = vendorList + '<li class="mb-3">' +
                        '<div class="mb-2">' + item.vendor.name + '</div>'+
                        '<div class="mb-2">' + item.vendor.address+', ' + item.vendor.city + ', ' + item.vendor.state + ', ' + item.vendor.postCode +'</div>' +
                        '<div class="mb-2">' + item.vendor.email + '</div>' +
                        '<div class="mb-2">' + item.vendor.phone + '</div>' +
                    '</li>';
      });
      vendorList = '<ul class="vendorList">'+ vendorList + '</ul>'; 
      
    }
    return vendorList;
  }
  
  getPerProductTotal(unitPrice, quantity, redeemPoint, shipping) {
    let total = 0;
    let pointValue = redeemPoint > 0 ? redeemPoint / this.pointPerDolar : 0;
    total = unitPrice * quantity + shipping - pointValue;
    return total; 
  }

  goBack() {
    this.location.back();
  }

  getShipingStatus(vendorOrders:any) {
    let allShippingStatus = [];
    if (vendorOrders && vendorOrders.length > 0) {
      vendorOrders.filter(function(item) {
        allShippingStatus.push(item.shippingStatus.toLowerCase());
      });

      if(allShippingStatus.includes('cancelled') && allShippingStatus.length == 0) {
        return allShippingStatus[0];
      } else {
        let cancelledIndex = allShippingStatus.indexOf('cancelled');
        if(cancelledIndex > -1){
          allShippingStatus.splice(cancelledIndex, 1);
        }
        let allEquall = allShippingStatus.every( (val, i, allShippingStatus) => val === allShippingStatus[0] );
        return allEquall ? allShippingStatus[0] : 'Pending';
      }

    }
  }
}
