import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService, AuthenticationService } from '../../../../../services';
import { User } from '../../../../../models';
import { storage } from '../../../../../../environments/environment';

@Component({
	selector: 'app-user-profile-widget',
	templateUrl: './user-profile-widget.component.html',
	styleUrls: [ './user-profile-widget.component.css' ]
})
export class UserProfileWidgetComponent implements OnInit {
	isCollapsed = false;
	tierDistance:number = 0;
	currentUser: User;
	photoUrl: string = storage.S3 + '/user/profile/';
	currentUserSubscription: Subscription;

	constructor(public Api: ApiService, private authenticationService: AuthenticationService) {
		this.currentUserSubscription = this.authenticationService.currentUser.subscribe((user) => {
			this.currentUser = user;
			if (this.currentUser.data.distance < 100) {
            this.tierDistance = this.currentUser.data.distance
			 .toFixed(2);
			}else if (this.currentUser.data.distance < 1000) {
			  this.tierDistance = this.currentUser.data.distance.toFixed(0);	
            } else {
              this.tierDistance = this.currentUser.data.distance
                .toFixed(0)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            }
		
		});
	}
	ngOnInit() {}
}
