import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService, AlertService, AuthenticationService } from '../../../services';
import { MustMatch } from '../../../shared/helpers/must-match.helper';
import { NotifierService } from 'angular-notifier';
@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: [ './password-reset.component.css' ]
})
export class PasswordResetComponent implements OnInit {
	passType = 'password';
	PassSeeClass = 'icon-eyeclose';
	reset = false;
	resetForm: FormGroup;
	loading = false;
	submitted = false;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private authenticationService: AuthenticationService,
		private userService: UserService,
		private notifier: NotifierService
	) {
		// redirect to home if already logged in
		if (this.authenticationService.currentUserValue) {
			this.router.navigate([ '/' ]);
		}
	}

	ngOnInit() {
		this.resetForm = this.formBuilder.group(
			{
				email: [ '', [ Validators.required, Validators.email ] ],
				code: [ '', [ Validators.required ] ],
				new_password: [ '', [ Validators.required, Validators.minLength(4) ] ],
				confirmPassword: [ '', [ Validators.required, Validators.minLength(4) ] ]
			},
			{
				validator: MustMatch('password', 'confirmPassword')
			}
		);
	}
	// convenience getter for easy access to form fields
	get f() {
		return this.resetForm.controls;
	}

	onSubmit() {
		this.submitted = true;
		// stop here if form is invalid
		if (this.resetForm.invalid) {
			return;
		}

		this.loading = true;
		this.userService.resetPassword(this.resetForm.value).pipe(first()).subscribe(
			(data) => {
				this.notifier.notify('success', 'Reset Password successful');
				this.reset = true;
			},
			(error) => {
				this.notifier.notify('error', error);
				this.loading = false;
			}
		);
	}
	// Password
	public togglePasswordType() {
		if (this.passType === 'password') {
			this.passType = 'text';
			this.PassSeeClass = 'icon-eyeopen';
		} else {
			this.passType = 'password';
			this.PassSeeClass = 'icon-eyeclose';
		}
	}
}
