import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../../../../services/server/api.service";
import { storage } from "../../../../../../../environments/environment";
@Component({
  selector: "app-single-stage",
  templateUrl: "./single-stage.component.html",
  styleUrls: ["./single-stage.component.css"]
})
export class SingleStageComponent implements OnInit {
  id = "";
  stage: any;
  loading = true;
  photoUrl: string = "";
  photoDIR = storage.S3 + "/events/covers/";
  constructor(
    private activatedRoute: ActivatedRoute,
    private Api: ApiService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.Api.get("/stage/" + params["id"]).subscribe(res => {
        this.stage = res.data;
        if (this.stage.image !== "") {
          this.loading = false;
          this.photoUrl = storage.S3 + "/events/covers/" + this.stage.image;
        } else {
          this.loading = false;
          this.photoUrl = "/assets/img/default/Home-Banne-1.png";
        }
        this.stage["startDate"] = new Date(this.stage["startDate"]);
        this.stage["endDate"] = new Date(this.stage["endDate"]);
      });
    });
  }

  formatDate(date) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var today = new Date(date);
    var dd = String(today.getDate()).padStart(2, "0");
    var yyyy = today.getFullYear();
    return dd + " " + monthNames[today.getMonth()] + " " + yyyy;
  }
}
