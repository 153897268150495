import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-app',
  templateUrl: './our-app.component.html',
  styleUrls: ['./our-app.component.css']
})
export class OurAppComponent implements OnInit {

  constructor() { }
  slideConfig = {
		dots: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 15000,
		speed: 2000,
	responsive: [
	    {
	      breakpoint: 1580,
	      settings: {
	        slidesToShow: 3
	      }
	    },
	    {
	      breakpoint: 980,
	      settings: {
	        slidesToShow: 2
	      }
	    },
	    {
	      breakpoint: 660,
	      settings: {
	        slidesToShow: 1
	      }
	    }
	  ]
   };
  ngOnInit() {
  }

}
