import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from '../../../../../services';

@Component({
  selector: "app-subroutes-widget",
  templateUrl: "./subroutes-widget.component.html",
  styleUrls: ["./subroutes-widget.component.css"]
})
export class SubroutesWidgetComponent implements OnInit {
  @Input()
  tierPhoto: string;
  membership = 'lite';
  constructor(private Api: ApiService) { }
  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile() {
    this.Api.get(`/user/profile`).subscribe((res: any) => {
      if (res.data) {
        this.membership = res.data.membership.type;
      }
    });
  }
}
