import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import {
  ApiService,
  UserService,
  AuthenticationService
} from "../../../services";
import { NotifierService } from "angular-notifier";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
declare const FB: any;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit {
  isMobile = /iPhone/i.test(window.navigator.userAgent)
  modalRef: BsModalRef;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  register = false;
  states: any;
  countries: any;
  memberships = [
    { _id: 1, name: "Monthly Membership - $5.99" },
    { _id: 2, name: "Annual Membership - $59.00" },
    {
      _id: 3,
      name: "Monthly Family Membership - $8.99"
    },
    {
      _id: 4,
      name: "Annual Family Membership - $85.00"
    }
    //, { _id: 0, name: "Lite Version - FREE" }
  ];
  membership = 1;
  payment = true;
  coupons: any = [
    { _id: 1, name: "CA Offer", code: "CAOFFER", payment: false, trialDay: 90, promoMessage: "Coupon successfully redeemed: Full access until 30 June, 2020", expireDate: '30-6-2020', status: 'active' },
    { _id: 2, name: "Gocycle Offer", code: "3MTHFREE", payment: true, trialDay: 90, promoMessage: "Coupon successfully redeemed: 3 Month FREE Trial", expireDate: '30-6-2022', status: 'active' },
    { _id: 3, name: "RNOFFER", code: "RNOFFER", payment: false, trialDay: 90, promoMessage: "Coupon successfully redeemed: Full access until 30 June, 2020", expireDate: '30-6-2022', status: 'inactive' },
    { _id: 4, name: "SCODY", code: "SCODY", payment: true, trialDay: 90, promoMessage: "Coupon successfully Redeemed: 3 Month FREE Trial", expireDate: '30-6-2022', status: 'active' },
    { _id: 5, name: "Gocycle Offer", code: "3MNTHFREE", payment: true, trialDay: 90, promoMessage: "Coupon successfully redeemed: 3 Month FREE Trial", expireDate: '30-6-2022', status: 'active' },
    { _id: 5, name: "Gocycle Offer", code: "1MOFFER", payment: false, trialDay: 30, promoMessage: "Coupon successfully redeemed: Full access until 30 June, 2020", expireDate: '30-6-2022', status: 'active' }
  ];
  coupon = "";
  promoMessage;
  couponExpire = false;
  isRedeem = false;
  referrals = [
    { _id: 0, name: "Cycling Australia" },
  //  { _id: 1, name: "Ride Nation" },
    { _id: 2, name: "Friend / Colleague" },
    { _id: 3, name: "Facebook" },
    { _id: 4, name: "Instagram" },
    { _id: 5, name: "Santos Tour Down Under" },
    { _id: 6, name: "Scody" },
    // { _id: 2, name: "Bike Exchange" },
    // { _id: 3, name: "John Smith" },
    { _id: 7, name: "None of the above" }
  ];
  clubs = [
    { _id: 0, name: "None" }
  ];
  charities = [
    { _id: 0, name: "None" }
  ];
  timeZones = [
    {
      _id: "Dateline Standard Time",
      name: "(UTC-12:00) International Date Line West"
    },
    { _id: "UTC-11", name: "(UTC-11:00) Coordinated Universal Time-11" },
    { _id: "Aleutian Standard Time", name: "(UTC-10:00) Aleutian Islands" },
    { _id: "Hawaiian Standard Time", name: "(UTC-10:00) Hawaii" },
    { _id: "Marquesas Standard Time", name: "(UTC-09:30) Marquesas Islands" },
    { _id: "Alaskan Standard Time", name: "(UTC-09:00) Alaska" },
    { _id: "UTC-09", name: "(UTC-09:00) Coordinated Universal Time-09" },
    {
      _id: "Pacific Standard Time (Mexico)",
      name: "(UTC-08:00) Baja California"
    },
    { _id: "UTC-08", name: "(UTC-08:00) Coordinated Universal Time-08" },
    {
      _id: "Pacific Standard Time",
      name: "(UTC-08:00) Pacific Time (US &amp; Canada)"
    },
    { _id: "US Mountain Standard Time", name: "(UTC-07:00) Arizona" },
    {
      _id: "Mountain Standard Time (Mexico)",
      name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
      _id: "Mountain Standard Time",
      name: "(UTC-07:00) Mountain Time (US &amp; Canada)"
    },
    {
      _id: "Central America Standard Time",
      name: "(UTC-06:00) Central America"
    },
    {
      _id: "Central Standard Time",
      name: "(UTC-06:00) Central Time (US &amp; Canada)"
    },
    { _id: "Easter Island Standard Time", name: "(UTC-06:00) Easter Island" },
    {
      _id: "Central Standard Time (Mexico)",
      name: "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    { _id: "Canada Central Standard Time", name: "(UTC-06:00) Saskatchewan" },
    {
      _id: "SA Pacific Standard Time",
      name: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    { _id: "Eastern Standard Time (Mexico)", name: "(UTC-05:00) Chetumal" },
    {
      _id: "Eastern Standard Time",
      name: "(UTC-05:00) Eastern Time (US &amp; Canada)"
    },
    { _id: "Haiti Standard Time", name: "(UTC-05:00) Haiti" },
    { _id: "Cuba Standard Time", name: "(UTC-05:00) Havana" },
    { _id: "US Eastern Standard Time", name: "(UTC-05:00) Indiana (East)" },
    {
      _id: "Turks And Caicos Standard Time",
      name: "(UTC-05:00) Turks and Caicos"
    },
    { _id: "Paraguay Standard Time", name: "(UTC-04:00) Asuncion" },
    {
      _id: "Atlantic Standard Time",
      name: "(UTC-04:00) Atlantic Time (Canada)"
    },
    { _id: "Venezuela Standard Time", name: "(UTC-04:00) Caracas" },
    { _id: "Central Brazilian Standard Time", name: "(UTC-04:00) Cuiaba" },
    {
      _id: "SA Western Standard Time",
      name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    { _id: "Pacific SA Standard Time", name: "(UTC-04:00) Santiago" },
    { _id: "Newfoundland Standard Time", name: "(UTC-03:30) Newfoundland" },
    { _id: "Tocantins Standard Time", name: "(UTC-03:00) Araguaina" },
    { _id: "E. South America Standard Time", name: "(UTC-03:00) Brasilia" },
    { _id: "SA Eastern Standard Time", name: "(UTC-03:00) Cayenne, Fortaleza" },
    {
      _id: "Argentina Standard Time",
      name: "(UTC-03:00) City of Buenos Aires"
    },
    { _id: "Greenland Standard Time", name: "(UTC-03:00) Greenland" },
    { _id: "Montevideo Standard Time", name: "(UTC-03:00) Montevideo" },
    { _id: "Magallanes Standard Time", name: "(UTC-03:00) Punta Arenas" },
    {
      _id: "Saint Pierre Standard Time",
      name: "(UTC-03:00) Saint Pierre and Miquelon"
    },
    { _id: "Bahia Standard Time", name: "(UTC-03:00) Salvador" },
    { _id: "UTC-02", name: "(UTC-02:00) Coordinated Universal Time-02" },
    {
      _id: "Mid-Atlantic Standard Time",
      name: "(UTC-02:00) Mid-Atlantic - Old"
    },
    { _id: "Azores Standard Time", name: "(UTC-01:00) Azores" },
    { _id: "Cape Verde Standard Time", name: "(UTC-01:00) Cabo Verde Is." },
    { _id: "UTC", name: "(UTC) Coordinated Universal Time" },
    {
      _id: "GMT Standard Time",
      name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London"
    },
    { _id: "Greenwich Standard Time", name: "(UTC+00:00) Monrovia, Reykjavik" },
    { _id: "Sao Tome Standard Time", name: "(UTC+00:00) Sao Tome" },
    {
      _id: "W. Europe Standard Time",
      name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
      _id: "Central Europe Standard Time",
      name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
      _id: "Romance Standard Time",
      name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    },
    { _id: "Morocco Standard Time", name: "(UTC+01:00) Casablanca" },
    {
      _id: "Central European Standard Time",
      name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
      _id: "W. Central Africa Standard Time",
      name: "(UTC+01:00) West Central Africa"
    },
    { _id: "Jordan Standard Time", name: "(UTC+02:00) Amman" },
    { _id: "GTB Standard Time", name: "(UTC+02:00) Athens, Bucharest" },
    { _id: "Middle East Standard Time", name: "(UTC+02:00) Beirut" },
    { _id: "Egypt Standard Time", name: "(UTC+02:00) Cairo" },
    { _id: "E. Europe Standard Time", name: "(UTC+02:00) Chisinau" },
    { _id: "Syria Standard Time", name: "(UTC+02:00) Damascus" },
    { _id: "West Bank Standard Time", name: "(UTC+02:00) Gaza, Hebron" },
    { _id: "South Africa Standard Time", name: "(UTC+02:00) Harare, Pretoria" },
    {
      _id: "FLE Standard Time",
      name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    { _id: "Israel Standard Time", name: "(UTC+02:00) Jerusalem" },
    { _id: "Kaliningrad Standard Time", name: "(UTC+02:00) Kaliningrad" },
    { _id: "Sudan Standard Time", name: "(UTC+02:00) Khartoum" },
    { _id: "Libya Standard Time", name: "(UTC+02:00) Tripoli" },
    { _id: "Namibia Standard Time", name: "(UTC+02:00) Windhoek" },
    { _id: "Arabic Standard Time", name: "(UTC+03:00) Baghdad" },
    { _id: "Turkey Standard Time", name: "(UTC+03:00) Istanbul" },
    { _id: "Arab Standard Time", name: "(UTC+03:00) Kuwait, Riyadh" },
    { _id: "Belarus Standard Time", name: "(UTC+03:00) Minsk" },
    {
      _id: "Russian Standard Time",
      name: "(UTC+03:00) Moscow, St. Petersburg"
    },
    { _id: "E. Africa Standard Time", name: "(UTC+03:00) Nairobi" },
    { _id: "Iran Standard Time", name: "(UTC+03:30) Tehran" },
    { _id: "Arabian Standard Time", name: "(UTC+04:00) Abu Dhabi, Muscat" },
    {
      _id: "Astrakhan Standard Time",
      name: "(UTC+04:00) Astrakhan, Ulyanovsk"
    },
    { _id: "Azerbaijan Standard Time", name: "(UTC+04:00) Baku" },
    { _id: "Russia Time Zone 3", name: "(UTC+04:00) Izhevsk, Samara" },
    { _id: "Mauritius Standard Time", name: "(UTC+04:00) Port Louis" },
    { _id: "Saratov Standard Time", name: "(UTC+04:00) Saratov" },
    { _id: "Georgian Standard Time", name: "(UTC+04:00) Tbilisi" },
    { _id: "Volgograd Standard Time", name: "(UTC+04:00) Volgograd" },
    { _id: "Caucasus Standard Time", name: "(UTC+04:00) Yerevan" },
    { _id: "Afghanistan Standard Time", name: "(UTC+04:30) Kabul" },
    { _id: "West Asia Standard Time", name: "(UTC+05:00) Ashgabat, Tashkent" },
    { _id: "Ekaterinburg Standard Time", name: "(UTC+05:00) Ekaterinburg" },
    { _id: "Pakistan Standard Time", name: "(UTC+05:00) Islamabad, Karachi" },
    { _id: "Qyzylorda Standard Time", name: "(UTC+05:00) Qyzylorda" },
    {
      _id: "India Standard Time",
      name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    { _id: "Sri Lanka Standard Time", name: "(UTC+05:30) Sri Jayawardenepura" },
    { _id: "Nepal Standard Time", name: "(UTC+05:45) Kathmandu" },
    { _id: "Central Asia Standard Time", name: "(UTC+06:00) Astana" },
    { _id: "Bangladesh Standard Time", name: "(UTC+06:00) Dhaka" },
    { _id: "Omsk Standard Time", name: "(UTC+06:00) Omsk" },
    { _id: "Myanmar Standard Time", name: "(UTC+06:30) Yangon (Rangoon)" },
    {
      _id: "SE Asia Standard Time",
      name: "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    { _id: "Altai Standard Time", name: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
    { _id: "W. Mongolia Standard Time", name: "(UTC+07:00) Hovd" },
    { _id: "North Asia Standard Time", name: "(UTC+07:00) Krasnoyarsk" },
    { _id: "N. Central Asia Standard Time", name: "(UTC+07:00) Novosibirsk" },
    { _id: "Tomsk Standard Time", name: "(UTC+07:00) Tomsk" },
    {
      _id: "China Standard Time",
      name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi"
    },
    { _id: "North Asia East Standard Time", name: "(UTC+08:00) Irkutsk" },
    {
      _id: "Singapore Standard Time",
      name: "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    { _id: "W. Australia Standard Time", name: "(UTC+08:00) Perth" },
    { _id: "Taipei Standard Time", name: "(UTC+08:00) Taipei" },
    { _id: "Ulaanbaatar Standard Time", name: "(UTC+08:00) Ulaanbaatar" },
    { _id: "Aus Central W. Standard Time", name: "(UTC+08:45) Eucla" },
    { _id: "Transbaikal Standard Time", name: "(UTC+09:00) Chita" },
    { _id: "Tokyo Standard Time", name: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
    { _id: "North Korea Standard Time", name: "(UTC+09:00) Pyongyang" },
    { _id: "Korea Standard Time", name: "(UTC+09:00) Seoul" },
    { _id: "Yakutsk Standard Time", name: "(UTC+09:00) Yakutsk" },
    {
      _id: "Cen. Australia Standard Time",
      name: "(UTC+09:30) Adelaide. Australia"
    },
    { _id: "AUS Central Standard Time", name: "(UTC+09:30) Darwin, Australia" },
    {
      _id: "E. Australia Standard Time",
      name: "(UTC+10:00) Brisbane, Australia"
    },
    {
      _id: "AUS Eastern Standard Time",
      name: "(UTC+10:00) Canberra, Melbourne, Sydney"
    },
    {
      _id: "West Pacific Standard Time",
      name: "(UTC+10:00) Guam, Port Moresby"
    },
    { _id: "Tasmania Standard Time", name: "(UTC+10:00) Hobart" },
    { _id: "Vladivostok Standard Time", name: "(UTC+10:00) Vladivostok" },
    { _id: "Lord Howe Standard Time", name: "(UTC+10:30) Lord Howe Island" },
    {
      _id: "Bougainville Standard Time",
      name: "(UTC+11:00) Bougainville Island"
    },
    { _id: "Russia Time Zone 10", name: "(UTC+11:00) Chokurdakh" },
    { _id: "Magadan Standard Time", name: "(UTC+11:00) Magadan" },
    { _id: "Norfolk Standard Time", name: "(UTC+11:00) Norfolk Island" },
    { _id: "Sakhalin Standard Time", name: "(UTC+11:00) Sakhalin" },
    {
      _id: "Central Pacific Standard Time",
      name: "(UTC+11:00) Solomon Is., New Caledonia"
    },
    {
      _id: "Russia Time Zone 11",
      name: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky"
    },
    {
      _id: "New Zealand Standard Time",
      name: "(UTC+12:00) Auckland, Wellington"
    },
    { _id: "UTC+12", name: "(UTC+12:00) Coordinated Universal Time+12" },
    { _id: "Fiji Standard Time", name: "(UTC+12:00) Fiji" },
    {
      _id: "Kamchatka Standard Time",
      name: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old"
    },
    {
      _id: "Chatham Islands Standard Time",
      name: "(UTC+12:45) Chatham Islands"
    },
    { _id: "UTC+13", name: "(UTC+13:00) Coordinated Universal Time+13" },
    { _id: "Tonga Standard Time", name: "(UTC+13:00) Nuku'alofa" },
    { _id: "Samoa Standard Time", name: "(UTC+13:00) Samoa" },
    { _id: "Line Islands Standard Time", name: "(UTC+14:00) Kiritimati Island" }
  ];
  constructor(
    private Api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private notifier: NotifierService,
    private modalService: BsModalService
  ) {
    this.getCharities();
    this.getClubs();
    this.getCoupon();
    //   this.getReferral();
    //   this.getMembership();
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '331323564452768',
        cookie: true,
        xfbml: true,
        version: 'v3.2'
      });
      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/es_LA/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    this.registerForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        dob: ["", []],
        termsCondition: ["", [Validators.required]],
        gender: ["Male", [Validators.required]],
        password: ["", [Validators.required, Validators.minLength(4)]],
    
        membership: [1, [Validators.required]],
        address: ["", []],
        coupon: ["", []],
        charity: [null],
        club: [null, [Validators.required]],
        referralBy: ['None of the above', [Validators.required]],
        timeZone: ["(UTC+10:00) Brisbane, Australia"]
      }
    );
  }

  getClubs() {
    this.Api.get("/auth/clubs").subscribe(result => {
      if (result.data) {
        this.clubs = result.data;
      }
    });
  }

  getCharities() {
    this.Api.get("/auth/charities/private").subscribe(result => {
      if (result.data) {
        this.charities = result.data;
      }
    });
  }

  getMembership() {
    this.Api.get("/auth/memberships").subscribe(result => {
      if (result.data) {
        this.memberships = result.data;
      }
    });
  }

  getReferral() {
    this.Api.get("/public/referrals").subscribe(result => {
      if (result.data) {
        this.referrals = result.data;
      }
    });
  }

  getCoupon() {
    this.Api.get("/public/coupons").subscribe(result => {
      if (result.data) {
        this.coupons = result.data;
      }
    });
  }
  redeemCoupon() {
    this.couponExpire = false;
    this.coupon = '';
    this.promoMessage = '';
    this.isRedeem = false;
    if (this.registerForm.value.coupon === "") {
      this.notifier.notify("error", "Please enter the coupon ");
      return;
    }
    let offer = this.registerForm.value.coupon.toUpperCase();
    let couponTest = this.coupons.find(x => x.code == offer)
    if (!couponTest) {
      this.notifier.notify("error", "Please enter the valid coupon");
      return;
    }

    if (couponTest.status == 'inactive') {
      this.couponExpire = true;
      this.notifier.notify("error", "Coupon has expired");
      return;
    }

    this.payment = couponTest.payment;
    this.promoMessage = couponTest.promoMessage;
    this.coupon = couponTest;
    localStorage.setItem("coupon", this.coupon);
    this.isRedeem = true;
  }

  handleAddressChange(address) {
    this.registerForm.get("address").setValue(address.formatted_address);
  }

  getStates() {
    this.Api.get("/states").subscribe(res => {
      this.states = res.data;
    });
  }

  getCountries() {
    this.Api.get("/countries").subscribe(res => {
      this.countries = res.data;
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.coupon != "" && !this.coupon) {
      this.notifier.notify("error", "Please enter the valid coupon ");
      return;
    }

    if (this.registerForm.value.coupon != "" && this.coupon === "") {
      this.notifier.notify("error", "Please redeem coupon.");
      return;
    }

    if (this.registerForm.value.charity != null && this.registerForm.value.charity != 'None') {
      if (this.registerForm.value.membership == 0) {
        this.notifier.notify("error", "You need to select full yearly/annual membership for joining private charity");
        return;
      }
    }

    this.loading = true;
    if (
      this.registerForm.value.coupon === "" || this.payment
    ) {
      if (this.registerForm.value.membership != 0) {
        localStorage.setItem(
          "user-data",
          JSON.stringify(this.registerForm.value)
        );
        localStorage.setItem("reg-email", this.registerForm.value.email);
        this.router.navigate(["/payment"]);
        return;
      }
    }

    this.userService
      .register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
       //   this.notifier.notify("success", "Registration successful");
          this.register = true;
          var registrationMethod = this.registerForm.value.referralBy
          this.logCompleteRegistrationEvent(registrationMethod)
          if (this.registerForm.value.referralBy == "Ride Nation") {
            this.RideNationRegistrationEvent();
          }
        },
        error => {
          if (error === "Email already exists") {
            alert("Email already exists");
          }
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }

  logCompleteRegistrationEvent(registrationMethod) {
    var params = {};
    params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = registrationMethod;
    FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);
  }
  RideNationRegistrationEvent() {
    FB.AppEvents.logEvent('Ride Nation Registration')
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-lg" })
    );
  }
}
