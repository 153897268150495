import { Injectable } from "@angular/core";
import { ApiService } from "../../server/api.service";

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  constructor(private Api: ApiService) { }

  getCoupons() {
    return this.Api.get(`/shop/coupons`);
  }

  store(city: any) {
    return this.Api.post("/shop/coupon/create", city);
  }

  update(id: any, city: any) {
    return this.Api.put("/shop/coupon/" + id, city);
  }

  getById(id: string) {
    return this.Api.get(`/shop/coupon/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/shop/coupon/` + id);
  }

  getCouponByCode(id: any, userId: any) {
    return this.Api.get('/shop/coupon/code/'+id+'/user/'+ userId);
  }
}
