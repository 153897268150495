import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class ClubService {
  constructor(private Api: ApiService) { }

  getRecords() {
    return this.Api.get(`/clubs`);
  }

  store(club: any) {
    return this.Api.post("/club/create", club);
  }

  update(id: any, club: any) {
    return this.Api.put(`/club/update/` + id, club);
  }

  getRecordById(id: string) {
    return this.Api.get(`/club/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/club/delete/` + id);
  }

}
