import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: 'root'
})
export class CharityService {
  constructor(private Api: ApiService) { }

  getRecords() {
    return this.Api.get(`/charities`);
  }

  store(charity: any) {
    return this.Api.post("/charity/create", charity);
  }

  update(id: any, charity: any) {
    return this.Api.put(`/charity/` + id, charity);
  }

  getRecordById(id: string) {
    return this.Api.get(`/charity/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/charity/` + id);
  }

}
