import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MemberService } from "../../../../../../services";
import { NotifierService } from "angular-notifier";
@Component({
  selector: "app-add-member",
  templateUrl: "./add-member.component.html",
  styleUrls: ["./add-member.component.css"]
})
export class AddMemberComponent implements OnInit {
  memeberForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private notifier: NotifierService
  ) { }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/user/account";

    this.memeberForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(4)]],
      familyMemberType: ["child", [Validators.required]],
      restriction: [true, [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.memeberForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.memeberForm.invalid) {
      return;
    }

    this.loading = true;

    this.memberService
      .store(this.memeberForm.value)
      .pipe(first())
      .subscribe(
        (res: any) => {
          if (res.data) {
            this.notifier.notify(
              "success",
              "Family memeber has been added successful"
            );
            this.loading = false;
            this.submitted = false;
            this.memeberForm.reset();
            this.router.navigate([this.returnUrl]);
          }
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
