import { Injectable } from "@angular/core";
import { ApiService } from "../../server/api.service";

@Injectable({
  providedIn: "root"
})
export class CityService {
  constructor(private Api: ApiService) { }

  getCities() {
    return this.Api.get(`/cities`);
  }

  store(city: any) {
    return this.Api.post("/city/create", city);
  }

  update(id: any, city: any) {
    return this.Api.put("/city/" + id, city);
  }

  getById(id: string) {
    return this.Api.get(`/city/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/city/` + id);
  }
}