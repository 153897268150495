import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
@Component({
  selector: "app-goals",
  templateUrl: "./goals.component.html",
  styleUrls: ["./goals.component.css"]
})
export class GoalsComponent implements OnInit {
  loading = true;

  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 50;

  photoUrl: string = storage.S3 + "/goals/";
  userGoals: any = [];

  constructor(private Api: ApiService) { }

  ngOnInit() {
    this.getUserGoals();
  }

  getUserGoals() {
    this.loading = true;
    this.Api.get("/user/goals").subscribe((res: any) => {
      if (res.data) {
        this.userGoals = res.data.goals;
        if (res.data.users) {
          this.total = res.data.users.length;
        }
        this.totalPages = res.data.pages;
        this.pages = Array.from(Array(res.data.pages).keys());
        this.currentPage = res.data.page;
      }
      this.loading = false;
    });
  }

  getGoal(distanceCompleted, distance) {
    let totalDistance;
    totalDistance = ((distanceCompleted / distance) * 100).toFixed(0);
    if (totalDistance > 100) {
      return 100;
    }
    return totalDistance;
  }

  nextPage(page) {
    this.currentPage = page + 1;
    this.getUserGoals();
  }
}
