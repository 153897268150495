import { Component, OnInit } from '@angular/core';
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ApiService,
  UserService,
  AuthenticationService
} from "../../../../../services";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})
export class ContactComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  sendMail = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notifier: NotifierService
  ) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      message: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .sendMail(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          // this.notifier.notify("success", "Thank you, your message has been successfully sent.");
          this.loading = false;
          this.registerForm.reset();
          this.sendMail = true;
          this.submitted = false;
        },
        error => {
          this.notifier.notify("error", error);
          this.loading = false;
        }
      );
  }
}
