import { Component, OnInit, NgZone } from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { ApiService } from "../../../../../services";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-my-ride",
  templateUrl: "./my-ride.component.html",
  styleUrls: ["./my-ride.component.css"]
})
export class MyRideComponent implements OnInit {
  checkpointLoading = true;
  points: number = 0;
  tierDistance: number = 0.0;
  tierLevel: number = 0;
  currentUser: any;
  tierPhoto: any;
  checkpoints: any;
  oldPointName = "Gold Coast";
  userPostion = 1;

  lat: number = -28.0042749;
  lng: number = 153.3928554;
  zoom: number = 5;

  constructor(
    private Api: ApiService,
    private activatedRoute: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.getUserTier();
    this.upcomingPoints();
  }

  getUserTier() {
    this.Api.get(`/user/profile`).subscribe((result: any) => {
      if (result.data) {
        this.currentUser = result.data;
        this.points = result.data.points;
        this.tierLevel = result.data.level;
        this.tierDistance = result.data.distance;
      }

    });
  }

  upcomingPoints() {
    this.checkpointLoading = true;
    this.Api.get(`/tier/points/5dbac5d8013bd917e622a7d2`).subscribe(
      (result: any) => {
        if (result.data) {
          this.checkpoints = result.data;
          this.checkpointLoading = false;
        }
      }
    );
  }

  setPointName(name) {
    this.oldPointName = name;
  }

  getPointFromat(total) {
    if (total < 100) {
      return total.toFixed(0);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }
}
