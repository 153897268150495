import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../../services";
import { storage } from "../../../../../../../environments/environment";
import {
  UserService,
  AlertService,
  AuthenticationService
} from "../../../../../../services";
import { Subscription } from "rxjs";
import { User } from "../../../../../../models";
@Component({
  selector: "app-more-events",
  templateUrl: "./more-events.component.html",
  styleUrls: ["./more-events.component.css"]
})
export class MoreEventsComponent implements OnInit {
  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 10;

  state = null;
  states: any;
  country: any;
  countries = null;
  photoUrl: string = storage.S3 + "/events/covers/";
  userEvents: any;
  bsInlineRangeValue = null;
  showMore = false;
  loading = true;
  dateCustomClasses = [];
  /////
  currentUserSubscription: Subscription;
  currentUser: User;
  constructor(
    private Api: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit() {
    this.getUserEvents();
    this.getStates();
    this.getCountries();
  }

  getUserEvents() {
    this.Api.get("/web/events/user", {
      perPage: this.perPage,
      page: this.currentPage
    }).subscribe((res: any) => {
      if (res.data) {
        this.loading = false;
        this.userEvents = res.data.events;
        this.total = res.data.events.length;
        this.totalPages = res.data.pages;
        this.pages = Array.from(Array(res.data.pages).keys());
        this.currentPage = res.data.page;
      }
    });
  }

  nextPage(page) {
    if (this.currentPage != this.totalPages) {
      this.currentPage = page + 1;
    }
    this.getUserEvents();
  }
  prevPage(page) {
    if (this.currentPage != 1) {
      this.currentPage = page - 1;
    }
    this.getUserEvents();
  }
  samePage(page) {
    this.currentPage = page + 1;
    this.getUserEvents();
  }


  setUpData() {
    this.userEvents.forEach(ev => {
      this.dateCustomClasses.push({
        date: new Date(ev["date"]),
        classes: ["bg-success-light"]
      });
      if (new Date(ev["startDate"]) < new Date() || ev.spotLeft === 0) {
        ev.event_status = "Closed";
      } else {
        ev.event_status = "Open";
      }
    });
  }

  deleteEvent(id) {
    if (confirm("Are you sure to delete ")) {
      this.Api.delete("/web/user/event/delete/" + id).subscribe(
        (result: any) => {
          this.getUserEvents();
        }
      );
    }
  }
  filterEvents(ev = null) {
    this.Api.get("/web/events/user", {
      keyword: ev ? ev.target.value : "",
      state: this.state ? this.state : "",
      date: this.bsInlineRangeValue ? this.bsInlineRangeValue : ""
    }).subscribe((res: any) => {
      if (res.data) {
        this.userEvents = res.data.events;
        this.setUpData();
      }
    });
  }
  formatDate(date, org = null) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year =
        d.getFullYear() > 2019 ? d.getFullYear() : new Date().getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return org ? [day, month, year].join("/") : [year, month, day].join("-");
  }
  changeDate(e) {
    if (e) {
      this.bsInlineRangeValue =
        e.getFullYear() + "-" + (1 + e.getMonth()) + "-" + e.getDate();
    }
    this.filterEvents({ target: { value: "" } });
  }

  getStates() {
    this.Api.get("/states").subscribe(res => {
      this.states = res.data;
    });
  }

  getCountries() {
    this.Api.get("/countries").subscribe(res => {
      this.countries = res.data;
    });
  }
}
