import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class TourService {
  constructor(private Api: ApiService) {}

  getCountries() {
    return this.Api.get(`/countries`);
  }

  store(tour: any) {
    return this.Api.post("/tier/create", tour);
  }

  update(id: any, tour: any) {
    return this.Api.put("/tier/" + id, tour);
  }

  getById(id: string) {
    return this.Api.get(`/tier/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/tier/` + id);
  }
}