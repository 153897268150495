import { Injectable } from "@angular/core";
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class TipService {
  
  constructor(private Api: ApiService) {}

  getTips() {
    return this.Api.get(`/tips`);
  }

  store(tip: any) {
    return this.Api.post("/tip/create", tip);
  }

  update(id: any, tip: any) {
    return this.Api.put("/tip/" + id, tip);
  }

  getById(id: string) {
    return this.Api.get(`/tip/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/tip/` + id);
  }
}