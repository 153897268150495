import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: 'root'
})
export class RedeemPointService {

  constructor(
    private Api: ApiService
  ) { }

  getPoint() {
    return this.Api.get(`/shop/points-rates`);
  }

  createPoint(point:any) {
    return this.Api.post(`/shop/points-rate/create`, point);
  }

  updatePoint(id:any, point: any) {
    return this.Api.put(`/shop/points-rate/`+ id, point);
  }

  deletePoint(id: any) {
    return this.Api.delete(`/shop/points-rate/`+ id);
  }
}
