import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";
@Injectable({
  providedIn: "root"
})
export class TeamService {
  constructor(private Api: ApiService) {}

  getTeams() {
    return this.Api.get(`/teams`);
  }

  store(team: any) {
    return this.Api.post("/web/team/create", team);
  }

  update(id: any, team: any) {
    return this.Api.put(`/web/team/update/` + id, team);
  }

  getById(id: string) {
    return this.Api.get(`/team/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/team/delete/` + id);
  }

}
