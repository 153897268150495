import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../services";
import { storage } from "../../../../../../environments/environment";
@Component({
  selector: "app-user-activities",
  templateUrl: "./user-activities.component.html",
  styleUrls: ["./user-activities.component.css"]
})
export class UserActivitiesComponent implements OnInit {
  rideLoading = true;
  challengeLoading = true;
  eventLoading = true;
  showMore = false;

  public pages: any;
  public total: any;
  public totalPages: any;
  public currentPage: any = 1;
  public perPage: any = 25;

  public activities: any = [];
  public challengeActivities: any = [];
  public eventActivities: any = [];
  public isCollapsed = false;
  public distance = 0;
  public durations = 0;
  public photoUrl: string = storage.S3 + "/log/";

  constructor(private Api: ApiService) { }

  ngOnInit() {
    this.getRides();
    this.getChallengeRides();
    this.getEventRides();
  }

  timeConvert(num) {
    var h = Math.floor(num / 3600);
    var m = Math.floor((num % 3600) / 60);
    var s = Math.floor((num % 3600) % 60);

    var hh = "" + h;
    var mm = "" + m;
    var ss = "" + s;

    if (h < 10) {
      hh = "0" + h;
    }
    if (m < 10) {
      mm = "0" + m;
    }
    if (s < 10) {
      ss = "0" + s;
    }

    return hh + ":" + mm + ":" + ss;
  }


  getRides() {
    this.rideLoading = true;
    this.Api.get("/user/rides/all", {
      rideType: 'basic',
      perPage: this.perPage,
      page: this.currentPage
    }).subscribe(
      (res: any) => {
        console.log(res);
        if (res.data) {
          this.activities = res.data.rides;
          this.total = res.data.rides.length;
          this.totalPages = res.data.pages;
          this.pages = Array.from(Array(res.data.pages).keys());
          this.currentPage = res.data.page;
        }
        this.rideLoading = false;
      }
    );
  }

  getChallengeRides() {
    this.challengeLoading = true;
    this.Api.get(`/user/rides/all?rideType=challenge&perPage=100`).subscribe(
      (res: any) => {
        if (res.data) {
          this.challengeActivities = res.data.rides;
        }
        this.challengeLoading = false;
      }
    );
  }

  getEventRides() {
    this.eventLoading = true;
    this.Api.get(`/user/rides/all?rideType=event&perPage=100`).subscribe(
      (res: any) => {
        if (res.data) {
          this.eventActivities = res.data.rides;
        }
        this.eventLoading = false;
      }
    );
  }

  nextPage(page) {
    if (this.currentPage != this.totalPages) {
      this.currentPage = page + 1;
    }
    this.getRides();
  }
  prevPage(page) {
    if (this.currentPage != 1) {
      this.currentPage = page - 1;
    }
    this.getRides();
  }
  samePage(page) {
    this.currentPage = page + 1;
    this.getRides();
  }

  getDistance() {
    let total = 0;
    if (this.activities) {
      for (var i = 0; i < this.activities.length; i++) {
        total += this.activities[i].distanceCompleted;
      }
    }
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  getDistanceFromat(total) {
    if (total < 100) {
      return total.toFixed(2);
    } else if (total < 1000) {
      return total.toFixed(0);
    } else {
      return total.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
  }

  getDurations() {
    let total = 0;
    if (this.activities) {
      for (var i = 0; i < this.activities.length; i++) {
        total += this.activities[i].completedTime;
      }
    }
    return this.timeConvert(total);
  }
}
