import { Injectable } from '@angular/core';
import { ApiService } from "./../server/api.service";

@Injectable({
  providedIn: "root"
})
export class VendorService {
  constructor(private Api: ApiService) {}

  getVendors() {
    return this.Api.get(`/shop/vendors`);
  }

  store(vendor: any) {
    return this.Api.post("/shop/vendor/create", vendor);
  }

  update(id: any, vendor: any) {
    // return this.Api.put(`/shop/vendor/update/` + id, vendor);
    return this.Api.put("/shop/vendor/" + id, vendor);
  }

  getById(id: string) {
    return this.Api.get(`/shop/vendor/` + id);
  }

  getProfileByUserId(id: string) {
    return this.Api.get('/shop/user/'+id+'/vendor-profile');
  }

  delete(id: string) {
    return this.Api.delete(`/shop/vendor/` + id);
  }

  resetPassword(id: any, password: any) {
    return this.Api.put('/shop/vendor/'+id+'/reset-password', password)
  }

  vendorPaymentStatusUpdate(orderId:any, vendorId:any, status:any) {
    return this.Api.put('/shop/order/'+orderId+'/vendor/'+vendorId+'/payment-status', status);
  }
}
