import { Injectable } from "@angular/core";
import { ApiService } from "../../server/api.service";

@Injectable({
  providedIn: "root"
})
export class StateService {
  constructor(private Api: ApiService) { }
  getStates() {
    return this.Api.get('/states');
  }
  store(state: any) {
    return this.Api.post('/state/create', state);
  }

  update(id: any, state: any) {
    return this.Api.put('/state/' + id, state);
  }

  getById(id: string) {
    return this.Api.get(`/state/` + id);
  }

  delete(id: string) {
    return this.Api.delete(`/state/` + id);
  }
}