import { Injectable } from '@angular/core';
import { ApiService } from '../../services/server/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private Api: ApiService) {}

	getEvents() {
		return this.Api.get(`/events`);
	}

	createEvent(event: any) {
		event['timeZone'] = new Date().getTimezoneOffset() * -1;
		return this.Api.post('/web/event/create', event);
	}

	updateEvent(event: any, id) {
		event['timeZone'] = new Date().getTimezoneOffset() * -1;
		return this.Api.put('/web/event/update/'+ id, event);
	}

}
