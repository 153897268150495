import { Role } from './role/role.model';
export class User {
	id: string;
	data: any;
	email: string;
	password: string;
	firstName: string;
	lastName: string;
	gender: string;
	dob?: string;
	authdata?: string;
	role: Role;
	token?: string;
	height: number;
	weight: number;
	photo: string;
	photoThumbnail: string;
	banner: string;
}
